import React, { Component, useEffect } from "react";

import {
  EuiButton,
  EuiFlexItem,
  EuiPopover,
  EuiButtonEmpty,
  EuiFlexGroup,
  EuiPanel,
  EuiText,
  EuiForm,
  EuiRadioGroup,
  EuiFormRow,
  EuiTextArea,
  EuiOverlayMask,
  EuiModal,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalBody,
  EuiSpacer,
  EuiLoadingSpinner,
  EuiModalFooter,
  EuiSelect,
} from "@elastic/eui";
import swal from "sweetalert";
import moment from "moment";

import Config from "../Config";


import SessionService from "../services/Sessions";
import Staff from "../services/Staffs";
import SessionSlotService from "../services/SessionSlots";
import ErrorService from "../services/Error.js";
import OrganizationService from "../services/Organizations";
import ClinicService from "../services/Clinics";


const clinicService = new ClinicService();
const errorService = new ErrorService();
const sessionsService = new SessionService();
const sessionSlotService = new SessionSlotService();
const staffService = new Staff();
const organizationService = new OrganizationService();

const ChangeCloseModal = (props) => {
  const { sessionItem, onScheduleChange } = props;
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [availableClinicOrganizations, setAvailableClinicOrganizations] =
    React.useState([]);
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [radioIdSelected, setRadioIdSelected] = React.useState(4); // default 4 (ended)
  const [reason, setReason] = React.useState(``);
  console.log(onScheduleChange, availableClinicOrganizations);

  useEffect(() => {
    loadOrganizations();
  }, []);

  const handleSubmit = () => {
    let payload = {};
    payload.Status = radioIdSelected;
    payload.Description = reason;
    payload.Id = sessionItem.id;

    setLoading(true);
    sessionsService
      .updateSessionStatus(payload)
      .then(() => {
        setLoading(false);
        setSuccess(true);
        closeModal();
        onScheduleChange();
      })
      .catch((err) => {
        setLoading(false);
        errorService.handle(err);
      });
  };

  const closeModal = (resetSuccessState) => {
    if (resetSuccessState) {
      setSuccess(false);
    }
    setIsModalVisible(false);
    setRadioIdSelected(4);
    setReason('');
  };

  const showModal = () => setIsModalVisible(true);
  const loadOrganizations = () => {
    let payload = {
      limit: 1000,
    };
    organizationService
      .getOrganizations(payload)
      .then((result) => {
        let options = [{ value: "", text: "Pilih organisasi" }];
        for (let i in result.items) {
          options.push({
            value: result.items[i].id,
            text: result.items[i].name,
          });
        }
        setAvailableClinicOrganizations(options);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  let oldSlotPanel = (
    <EuiPanel>
      <EuiText>
        <p>
          <b>{sessionItem?.patientName}</b>
          <br />
          <small>{sessionItem?.patientExternalId}</small>
          <br />
          <br />
          Jadwal Saat Ini
          <br />
          <small>
            <b>{sessionItem?.staffName}</b>
          </small>
          <br />
          <small>{sessionItem?.clinicName}</small>
          <br />
          <small>
            {`${moment(sessionItem?.scheduleDate).format("dddd")}`},{" "}
            {`${moment(sessionItem?.scheduleDate).format("LL")}`} pukul{" "}
            {sessionItem?.scheduleTime} <br />
          </small>
          <br />
        </p>
      </EuiText>
    </EuiPanel>
  );

  const onChangeCloseReason = (optionId) => {
    setRadioIdSelected(optionId);
  };

  let form = (
    <EuiPanel>
      <EuiForm>
        <p>di-close karena</p>
        <br />
        <EuiRadioGroup
          options={[
            {
              id: 4,
              label: "Ended",
            },
            {
              id: 3,
              label: "Disabled",
            },
            {
              id: 7,
              label: "Refund",
            },
          ]}
          idSelected={radioIdSelected}
          onChange={onChangeCloseReason}
          name="close option"
        />
        <EuiFormRow label="Alasan*">
          <div>
            <EuiTextArea
              placeholder="Masukan alasan..."
              aria-label="description"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            />
          </div>
        </EuiFormRow>
      </EuiForm>
    </EuiPanel>
  );

  let modal;
  if (isModalVisible) {
    modal = (
      <EuiOverlayMask>
        <EuiModal onClose={closeModal} initialFocus="[name=popswitch]">
          <EuiModalHeader>
            <EuiModalHeaderTitle>
              Close Temu-janji Konsultasi
            </EuiModalHeaderTitle>
          </EuiModalHeader>

          <EuiModalBody>
            {oldSlotPanel}
            <EuiSpacer />
            <br />
            <br />
            {loading ? (
              <p>
                <EuiLoadingSpinner size="xl" />
              </p>
            ) : (
              form
            )}
          </EuiModalBody>

          {loading ? (
            ""
          ) : !success ? (
            <EuiModalFooter>
              <EuiButton onClick={handleSubmit} fill disabled={!reason}>
                Submit
              </EuiButton>
              <EuiButtonEmpty onClick={closeModal}>Batal</EuiButtonEmpty>
            </EuiModalFooter>
          ) : (
            <EuiModalFooter>
              <EuiButtonEmpty onClick={() => closeModal(true)}>
                Batal
              </EuiButtonEmpty>
            </EuiModalFooter>
          )}
        </EuiModal>
      </EuiOverlayMask>
    );
  }
  return (
    <>
      <EuiButton onClick={showModal}>Close</EuiButton>
      {modal}
    </>
  );
};

const ChangeScheduleModal = (props) => {
  const { sessionItem, onScheduleChange } = props;
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [availableClinicOrganizations, setAvailableClinicOrganizations] =
    React.useState([]);
  const [availableClinics, setAvailableClinics] = React.useState([]);
  const [availableDoctors, setAvailableDoctors] = React.useState([]);
  const [availableSessionSlots, setAvailableSessionSlots] = React.useState([]);
  const [clinicOrganizationId, setClinicOrganizationId] = React.useState("");
  const [pickedClinicId, setPickedClinicId] = React.useState("");
  const [pickedDoctorId, setPickedDoctorId] = React.useState("");
  const [pickedSessionSlotId, setPickedSessionSlotId] = React.useState("");
  const [isModalVisible, setIsModalVisible] = React.useState(false);

  useEffect(() => {
    loadOrganizations();
  }, []);

  const handleSubmit = () => {
    let payload = {};
    console.log(payload);

    payload.to_slot = pickedSessionSlotId;
    payload.from_session = sessionItem.id;
    setLoading(true);
    sessionsService
      .moveSessionToAnotherSlot(pickedSessionSlotId, payload)
      .then(() => {
        setLoading(false);
        setSuccess(true);
        closeModal();
        onScheduleChange(payload);
      })
      .catch((err) => {
        setLoading(false);
        errorService.handle(err);
      });
  };

  const closeModal = (resetSuccessState) => {
    if (resetSuccessState) {
      setSuccess(false);
    }
    setIsModalVisible(false);
  };

  const showModal = () => setIsModalVisible(true);
  const loadOrganizations = () => {
    let payload = {
      limit: 1000,
    };
    organizationService
      .getOrganizations(payload)
      .then((result) => {
        let options = [{ value: "", text: "Pilih organisasi" }];
        for (let i in result.items) {
          options.push({
            value: result.items[i].id,
            text: result.items[i].name,
          });
        }
        setAvailableClinicOrganizations(options);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadClinics = (organizationId) => {
    let payload = {
      limit: 1000,
      organizationId: organizationId,
    };
    clinicService
      .getClinics(payload)
      .then((result) => {
        let options = [];
        for (let i in result.items) {
          let exist = false;
          for (let j in availableClinics) {
            if (result.items[i].id === availableClinics[j].id) {
              exist = true;
              break;
            }
          }
          if (exist) {
            continue;
          }
          options.push({
            value: result.items[i].id,
            text: result.items[i].name,
          });
        }
        if (options && options.length < 1) {
          options.splice(0, 0, {
            value: "",
            text: "Tidak ada klinik tersedia",
          });
        } else {
          options.splice(0, 0, {
            value: "",
            text: "Pilih klinik..",
          });
        }
        setAvailableClinics(options);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadDoctors = (clinicId) => {
    let payload = {
      limit: 1000,
      clinicId: clinicId,
    };
    staffService
      .getStaffs(payload)
      .then((result) => {
        let options = [];
        for (let i in result.items) {
          let exist = false;
          for (let j in availableDoctors) {
            if (result.items[i].id === availableDoctors[j].id) {
              exist = true;
              break;
            }
          }
          if (exist) {
            continue;
          }
          options.push({
            value: result.items[i].id,
            text: result.items[i].name,
          });
        }
        if (options && options.length < 1) {
          options.splice(0, 0, {
            value: "",
            text: "Tidak ada dokter tersedia",
          });
        } else {
          options.splice(0, 0, {
            value: "",
            text: "Pilih dokter..",
          });
        }
        setAvailableDoctors(options);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadSessionSlot = (staffId) => {
    let payload = {
      limit: 1000,
      staffId: staffId,
      clinicId: pickedClinicId,
      searchBy: "",
    };
    sessionSlotService
      .getSessionSlots(payload)
      .then((result) => {
        console.log(result);
        let options = [];
        for (let i in result.items) {
          let exist = false;
          for (let j in availableSessionSlots) {
            if (result.items[i].id === availableSessionSlots[j].id) {
              exist = true;
              break;
            }
          }
          if (exist) {
            continue;
          }
          options.push({
            value: result.items[i].id,
            text: `${result.items[i].specificDate}, ${result.items[i].startTime}-${result.items[i].endTime} `,
          });
        }
        if (options && options.length < 1) {
          options.splice(0, 0, {
            value: "",
            text: "Tidak ada jadwal tersedia",
          });
        } else {
          options.splice(0, 0, {
            value: "",
            text: "Pilih jadwal..",
          });
        }
        setAvailableSessionSlots(options);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onClinicOrganizationChange = (e) => {
    let value = e.target.value || null;
    setClinicOrganizationId(value);
    loadClinics(value);
  };

  const onPickedClinic = (e) => {
    let value = e.target.value || null;
    if (!value || (value && value.length < 1)) {
      return;
    }

    setPickedClinicId(value);
    loadDoctors(value);
  };

  const onPickedDoctor = (e) => {
    let value = e.target.value || null;
    if (!value || (value && value.length < 1)) {
      return;
    }

    setPickedDoctorId(value);
    loadSessionSlot(value);
  };

  const onPickedSessionSlot = (e) => {
    let value = e.target.value || null;
    if (!value || (value && value.length < 1)) {
      return;
    }

    setPickedSessionSlotId(value);
  };

  let oldSlotPanel = (
    <EuiPanel>
      <EuiText>
        <p>
          <b>{sessionItem?.patientName}</b>
          <br />
          <small>{sessionItem?.patientExternalId}</small>
          <br />
          <br />
          Jadwal Saat Ini
          <br />
          <small>
            <b>{sessionItem?.staffName}</b>
          </small>
          <br />
          <small>{sessionItem?.clinicName}</small>
          <br />
          <small>
            {`${moment(sessionItem?.scheduleDate).format("dddd")}`},{" "}
            {`${moment(sessionItem?.scheduleDate).format("LL")}`} pukul{" "}
            {sessionItem?.scheduleTime} <br />
          </small>
          <br />
        </p>
      </EuiText>
    </EuiPanel>
  );

  let form = (
    <EuiPanel>
      <EuiForm>
        <p>Jadwal Baru</p>
        <br />
        <EuiFormRow label="Nama Unit">
          <EuiSelect
            placeholder="Unit"
            options={availableClinicOrganizations}
            value={clinicOrganizationId}
            name="clinicOrganizationId"
            onChange={(e) => {
              onClinicOrganizationChange(e);
            }}
            aria-label="Organisasi"
          />
        </EuiFormRow>
        {clinicOrganizationId && clinicOrganizationId.length > 0 && (
          <EuiFormRow label="Nama Klinik">
            <div>
              <EuiSelect
                placeholder="Klinik"
                options={availableClinics}
                value={pickedClinicId}
                name="pickedClinicName"
                onChange={(e) => {
                  onPickedClinic(e);
                }}
                aria-label="Klinik"
              />
            </div>
          </EuiFormRow>
        )}
        {pickedClinicId && pickedClinicId.length > 0 && (
          <EuiFormRow label="Nama Dokter">
            <div>
              <EuiSelect
                placeholder="Dokter"
                options={availableDoctors}
                value={pickedDoctorId}
                name="pickedDoctorName"
                onChange={(e) => {
                  onPickedDoctor(e);
                }}
                aria-label="Dokter"
              />
            </div>
          </EuiFormRow>
        )}
        {pickedDoctorId && pickedDoctorId.length > 0 && (
          <EuiFormRow label="Pilih Jadwal">
            <div>
              <EuiSelect
                placeholder="Jadwal"
                options={availableSessionSlots}
                value={pickedSessionSlotId}
                name="pickedSessionSlotId"
                onChange={(e) => {
                  onPickedSessionSlot(e);
                }}
                aria-label="Jadwal"
              />
            </div>
          </EuiFormRow>
        )}
      </EuiForm>
    </EuiPanel>
  );

  let modal;
  if (isModalVisible) {
    modal = (
      <EuiOverlayMask>
        <EuiModal onClose={closeModal} initialFocus="[name=popswitch]">
          <EuiModalHeader>
            <EuiModalHeaderTitle>
              Lihat / Ubah Jadwal Pasien
            </EuiModalHeaderTitle>
          </EuiModalHeader>

          <EuiModalBody>
            {oldSlotPanel}
            <EuiSpacer />
            <br />
            <center> Menjadi </center>
            <br />
            {loading ? (
              <p>
                <EuiLoadingSpinner size="xl" />
              </p>
            ) : (
              form
            )}
          </EuiModalBody>

          {loading ? (
            ""
          ) : !success ? (
            <EuiModalFooter>
              <EuiButton onClick={handleSubmit} fill>
                Ubah
              </EuiButton>
              <EuiButtonEmpty onClick={closeModal}>Batal</EuiButtonEmpty>
            </EuiModalFooter>
          ) : (
            <EuiModalFooter>
              <EuiButtonEmpty onClick={() => closeModal(true)}>
                Kembali
              </EuiButtonEmpty>
            </EuiModalFooter>
          )}
        </EuiModal>
      </EuiOverlayMask>
    );
  }
  return (
    <>
      <EuiButton onClick={showModal}>Reschedule</EuiButton>
      {modal}
    </>
  );
};

class ActionPopover extends Component {
  state = {
    isPopoverOpen: false,
  };
  render() {
    return (
      <EuiFlexGroup>
        <EuiFlexItem grow={false}>
          <EuiPopover
            button={
              <EuiButton
                iconType="arrowDown"
                iconSide="right"
                onClick={() => {
                  this.setState({ isPopoverOpen: !this.state.isPopoverOpen });
                }}
              >
                {console.log(this.state.openedPopOver, this.props.id)}
                Aksi
              </EuiButton>
            }
            isOpen={this.state.isPopoverOpen}
            closePopover={() => this.setState({ isPopoverOpen: false })}
            anchorPosition="downCenter"
          >
            <EuiFlexGroup direction="column">
              <EuiFlexItem>
                <ChangeScheduleModal
                  sessionItem={this.props.sessionItem}
                  onScheduleChange={this.props.onScheduleChange}
                />
              </EuiFlexItem>
              <EuiFlexItem>
                {this.props.sessionItem.status !== 1 ? (
                  <EuiButton
                    onClick={() => {
                      return swal({
                        title: "",
                        type: "error",
                        text: "Mohon maaf, fitur ini tidak dapat digunakan pada temu-janji ini",
                      });
                    }}
                  >
                    Close
                  </EuiButton>
                ) : (
                  <ChangeCloseModal
                    sessionItem={this.props.sessionItem}
                    onScheduleChange={this.props.onScheduleChange}
                  />
                )}
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiButton onClick={this.props.openReceiptBlob}>
                  Lihat Kwitansi
                </EuiButton>
              </EuiFlexItem>
              <EuiFlexItem>
                <EuiButton onClick={this.props.checkPayment}>
                  Cek Pembayaran
                </EuiButton>
              </EuiFlexItem>
              {this.props.sessionItem.paymentURL !== "" ? (
                <EuiFlexItem>
                  <EuiButton
                    onClick={() =>
                      window.open(this.props.sessionItem.paymentURL)
                    }
                  >
                    URL Pembayaran
                  </EuiButton>
                </EuiFlexItem>
              ) : null}
              {!moment(this.props.sessionItem.scheduleDate).isBefore(
                moment().format("YYYY-MM-DD")
              ) &&
              Config.weblink !== "" &&
              this.props.sessionItem.status === 1 ? (
                <EuiFlexItem>
                  <EuiButton
                    onClick={() => {
                      window.open("/weblink-list/" + this.props.id);
                    }}
                  >
                    Siapdok Link
                  </EuiButton>
                </EuiFlexItem>
              ) : null}
            </EuiFlexGroup>
          </EuiPopover>
        </EuiFlexItem>
      </EuiFlexGroup>
    );
  }
}

export default ActionPopover;
