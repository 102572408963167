import React, { Component } from 'react';
import { debounce } from 'lodash';
import {
  EuiPage,
  EuiPageBody,
  EuiButton,
  EuiForm,
  EuiFormRow,
  EuiOverlayMask,
  EuiButtonEmpty,
  EuiConfirmModal,
  EuiModal,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalBody,
  EuiModalFooter,
  EuiSelect,
  EuiFilePicker,
  EuiDatePicker,
  EuiFieldText,
  EuiComboBox,
  EuiFlexItem,
  EuiTitle,
  EuiTab,
  EuiTabs,
  EuiSwitch,
  EuiBadge,
  EuiBasicTable,
  EuiCheckbox,
} from '@elastic/eui';
import TablePage from '../components/TablePage';
import SessionSlotService from '../services/SessionSlots';
import OrganizationService from '../services/Organizations';
import ClinicService from '../services/Clinics';
import Staff from '../services/Staffs';
import ProductService from '../services/Products.js';
import ErrorService from '../services/Error.js';
import Utils from '../Utils';
import swal from 'sweetalert';
import moment from 'moment';
import 'moment/locale/id';

moment.locale('id');
const staffService = new Staff();
const sessionSlotService = new SessionSlotService();
const organizationService = new OrganizationService();
const clinicService = new ClinicService();
const productService = new ProductService();
const errorService = new ErrorService();

/*

This function will generate something like this
[
  '00:00', '00:30', '01:00', '01:30',
  '02:00', '02:30', '03:00', '03:30',
  '04:00', '04:30', '05:00', '05:30',
  '06:00', '06:30', '07:00', '07:30',
  '08:00', '08:30', '09:00', '09:30',
  '10:00', '10:30', '11:00', '11:30',
  '12:00', '12:30', '13:00', '13:30',
  '14:00', '14:30', '15:00', '15:30',
  '16:00', '16:30', '17:00', '17:30',
  '18:00', '18:30', '19:00', '19:30',
  '20:00', '20:30', '21:00', '21:30',
  '22:00', '22:30', '23:00', '23:30'
]
*/

var self;

class SessionSlot extends Component {
  state = {
    maxCount: 0,
    selectedTabId: 'weekly',
    tabs: [
      {
        id: 'weekly',
        name: 'Mingguan',
        disabled: false,
      },
      {
        id: 'specificDate',
        name: 'Tanggal spesifik',
        disabled: false,
      },
    ],
    dateRangeOptions: [
      {
        text: 'Semua tanggal',
        value: 'all',
      },
      {
        text: 'Tanggal',
        value: 'specificDate',
      },
    ],
    durationOptions: [
      {
        text: '10',
        value: '600000',
      },
      {
        text: '15',
        value: '900000',
      },
      {
        text: '30',
        value: '1800000',
      },
      {
        text: '45',
        value: '2700000',
      },
      {
        text: '60',
        value: '3600000',
      },
      {
        text: '240',
        value: '14400000',
      },
    ],
    dayOptions: [
      {
        text: 'Silakan Pilih Hari',
        value: '',
      },
      {
        text: 'Minggu',
        value: '7',
      },
      {
        text: 'Senin',
        value: '1',
      },
      {
        text: 'Selasa',
        value: '2',
      },
      {
        text: 'Rabu',
        value: '3',
      },
      {
        text: 'Kamis',
        value: '4',
      },
      {
        text: 'Jumat',
        value: '5',
      },
      {
        text: 'Sabtu',
        value: '6',
      },
    ],
    page: 1,
    limit: 10,
    order: 'ASC',
    search: '',
    dateRange: 'all',
    searchDate: moment(),
    loading: false,
    orderBy: 'name',
    data: [],
    day: '',
    tags: [],
    column: [
      {
        field: 'organizationName',
        name: 'Organisasi',
      },
      {
        field: 'clinicName',
        name: 'Klinik',
      },
      {
        field: 'staffName',
        name: 'Dokter',
      },
      {
        field: 'assistantName',
        name: 'Asisten',
      },
      {
        field: 'startTime',
        name: 'Awal',
      },
      {
        field: 'endTime',
        name: 'Akhir',
      },
      {
        field: 'dayString',
        name: 'Hari',
      },
      {
        field: 'specificDate',
        name: 'Tanggal',
        render: (item) => {
          if (
            item &&
            item._isAMomentObject &&
            item.isValid &&
            item.format('D MMMM YYYY').indexOf('Invalid') < 0
          ) {
            return <span>{item.format('D MMMM YYYY')}</span>;
          } else {
            return <span>-</span>;
          }
        },
      },
      {
        field: 'endDate',
        name: 'Tanggal Berakhir',
        render: (item) => {
          if (
            item &&
            item._isAMomentObject &&
            item.isValid &&
            item.format('D MMMM YYYY').indexOf('Invalid') < 0
          ) {
            if (moment().isAfter(item)) {
              return <span><EuiBadge color="danger">Kadaluarsa</EuiBadge></span>;
            }
            return <span>{item.format('D MMMM YYYY')}</span>;
          } else {
            return <span>-</span>;
          }
        },
      },
      {
        field: 'humanReadableDuration',
        name: 'Durasi (menit)',
      },
      {
        field: 'productName',
        name: 'Produk/Tarif',
      },
    ],

    // Form
    activeSessions: 0,
    name: '',
    externalId: '',
    currentPage: 0,
    sessionsImport: [],
    validationFields: {
      maxCount: {
        type: 'integer',
        isInvalid: false,
        errors: ['Tidak boleh kosong'],
        isValidFunc: function (value) {
          return value && value > 0;
        },
      },
      organizationId: {
        type: 'string',
        isInvalid: false,
        errors: ['Tidak boleh kosong'],
        isValidFunc: function (value) {
          return value && value.length > 0;
        },
      },
      clinicId: {
        type: 'string',
        isInvalid: false,
        errors: ['Tidak boleh kosong'],
        isValidFunc: function (value) {
          return value && value.length > 0;
        },
      },
      staffId: {
        type: 'string',
        isInvalid: false,
        errors: ['Tidak boleh kosong'],
        isValidFunc: function (value) {
          return value && value.length > 0;
        },
      },
      assistantId: {
        type: 'string',
        isInvalid: false,
        errors: ['Tidak boleh kosong'],
        isValidFunc: function (value) {
          return true;
        },
      },
      startTime: {
        type: 'string',
        isInvalid: false,
        errors: ['Tidak boleh kosong'],
        isValidFunc: function (value) {
          return value && value.length > 0;
        },
      },
      endTime: {
        type: 'string',
        isInvalid: false,
        errors: ['Tidak boleh kosong dan waktu tidak boleh sama'],
        isValidFunc: function (value) {
          return value && value.length > 0 && self.state.startTime !== value;
        },
      },
      duration: {
        type: 'number',
        isInvalid: false,
        errors: ['Tidak boleh kosong'],
        isValidFunc: function (value) {
          return true;
        },
      },
      productId: {
        type: 'string',
        isInvalid: false,
        errors: ['Tidak boleh kosong'],
        isValidFunc: function (value) {
          return value && value.length > 0;
        },
      },
      day: {
        type: 'string',
        isInvalid: false,
        errors: ['Tidak boleh kosong'],
        isValidFunc: function (value) {
          if (self.state.selectedTabId !== 'weekly') return true;
          return (
            self.state.selectedTabId === 'weekly' && value && value.length > 0
          );
        },
      },
      specificDate: {
        type: 'string',
        isInvalid: false,
        errors: ['Tidak boleh kosong'],
        isValidFunc: function (value) {
          if (self.state.selectedTabId === 'weekly') return true;
          console.log(value);
          return value && value._isAMomentObject;
        },
      },
      startDate: {
        type: 'string',
        isInvalid: false,
        errors: ['Tidak boleh kosong'],
        isValidFunc: function (value) {
          console.log(self.state.selectedTabId, value);
          if (self.state.selectedTabId === 'specificDate') return true;
          return value && value._isAMomentObject;
        },
      },
      endDate: {
        type: 'string',
        isInvalid: false,
        errors: ['Tidak boleh kosong'],
        isValidFunc: function (value) {
          console.log(self.state.selectedTabId, value);
          if (self.state.selectedTabId === 'specificDate') return true;
          return value && value._isAMomentObject;
        },
      },
      tags: {
        type: 'string',
        isInvalid: false,
        errors: [''],
        isValidFunc: function (value) {
          return true;
        },
      },
    },
  };

  componentDidMount = () => {
    self = this;
    this.page(1);
    this.loadOrganizations();
    this.loadProducts();
    this.loadClinics();
  };

  loadMaxCount = (id) => {
    sessionSlotService
      .getMaxCount(id)
      .then((result) => {
        console.log(result);
        this.setState({
          maxCount: result['max_count'],
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  loadOrganizations = () => {
    return new Promise((resolve, reject) => {
      let payload = {
        limit: 1000,
      };
      organizationService
        .getOrganizations(payload)
        .then((result) => {
          let options = [];
          if (this.state.isNewItem) {
            options.push({ value: '', text: 'Pilih organisasi...' });
          }
          for (let i in result.items) {
            options.push({
              value: result.items[i].id,
              text: result.items[i].name,
            });
          }
          this.setState({ organizations: options }, () => {
            resolve();
          });
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            organizations: [],
          });
          reject(err);
        });
    });
  };

  loadProducts = () => {
    return new Promise((resolve, reject) => {
      productService
        .getProducts()
        .then((result) => {
          let options = [];
          if (this.state.isNewItem) {
            options.push({ value: '', text: 'Pilih produk...' });
          }
          for (let i in result.items) {
            let item = result.items[i];
            console.log(item);
            options.push({
              value: result.items[i].id,
              text: `${result.items[i].title} - Rp. ${result.items[i].price.units}`,
            });
          }
          this.setState({ products: options }, () => {
            resolve();
          });
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            products: [],
          });
          reject(err);
        });
    });
  };

  loadClinics = (organizationId) => {
    return new Promise((resolve, reject) => {
      let payload = {
        limit: 1000,
        organizationId: organizationId,
      };
      clinicService
        .getClinics(payload)
        .then((result) => {
          let options = [];
          for (let i in result.items) {
            options.push({
              value: result.items[i].id,
              label: result.items[i].name,
              text: result.items[i].name,
            });
          }
          this.setState({ availableClinics: options }, () => {
            resolve();
          });
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            organizations: [],
          });
          reject(err);
        });
    });
  };

  handleChange = (e) => {
    let value = e.target.value || null;
    let obj = {};
    obj[e.target.name] = value;
    obj['failedAttempt'] = false;
    let validationFields = { ...this.state.validationFields };
    if (validationFields[e.target.name]) {
      validationFields[e.target.name].isInvalid = false;
      obj.validationFields = validationFields;
    }
    this.setState(obj);
  };

  page = (page) => {
    page = page || 1;
    this.setState({ loading: true });
    let payload = {
      page: page,
      limit: this.state.limit,
      search: this.state.search,
      order: this.state.order,
      orderBy: this.state.orderBy,
      specificDate: this.state.searchDate,
      scheduleType: this.state.selectedTabId === 'weekly' ? 0 : 1,
    };
    sessionSlotService
      .getSessionSlots(payload)
      .then((result) => {
        for (let i in result.items) {
          result.items[i].humanReadableDuration =
            parseInt(result.items[i].duration, 10) / 60000;

          // the default is Sunday, because in the system Sunday is 0
          // change to 7, because 0 is undefined in the system (emitempty)
          result.items[i].dayString = moment().weekday(7).format('dddd');
          if (result.items[i].day !== undefined) {
            result.items[i].dayString = moment()
              .weekday(result.items[i].day)
              .format('dddd');
          }
          //check on specificDate and scheduleType, if it has specificDate and scheduleType, print them
          if (result.items[i].specificDate != '' && result.items[i].specificDate !== undefined && this.state.selectedTabId === 'specificDate') {
            result.items[i].specificDate = moment(result.items[i].specificDate);
          } else {
            //else, we get date from days
            const day = Number(result.items[i].day);
            const today = moment().isoWeekday();
            if (today <= day) {
              result.items[i].specificDate = moment().isoWeekday(day);
            } else {
              result.items[i].specificDate = moment().add(1, 'weeks').isoWeekday(day);
            }
          }
          if (result.items[i].startDate !== '' && result.items[i].startDate !== undefined)
            result.items[i].startDate = moment(result.items[i].startDate);
          if (result.items[i].endDate !== '' && result.items[i].endDate !== undefined)
            result.items[i].endDate = moment(result.items[i].endDate);
          result.items[i].day = String(result.items[i].day);
        }
        this.setState(
          {
            page: result.page,
            limit: result.limit,
            total: result.total,
            order: result.order,
            orderBy: result.orderBy,
            data: result.items,
            loading: false,
          },
          () => {
            this.forceUpdate();
          }
        );
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loading: false,
          data: [],
        });
      });
  };

  nextPage = () => {
    this.page(this.state.page + 1);
  };

  prevPage = () => {
    this.page(this.state.page - 1);
  };

  add = () => {
    this.prepareNewItem();
  };

  show = (item) => {
    this.setState({ id: item.id, name: item.name, showModal: true });
  };

  save = () => {
    let state = { ...this.state };
    this.setState({ saveLoading: true }, () => {
      Utils.validateFields(state).then((result) => {
        this.setState({ validationFields: result.validationFields });
        this.forceUpdate();
        if (!result.isValid) {
          console.log(result);
          return;
        }

        let tags = [];
        for (let i in this.state.tags) {
          tags.push(this.state.tags[i].label);
        }

        let payload = {
          clinicId: this.state.clinicId,
          staffId: this.state.staffId,
          assistantId: this.state.assistantId,
          organizationId: this.state.organizationId,
          startTime: this.state.startTime,
          endTime: this.state.endTime,
          duration: parseInt(this.state.duration, 10),
          productId: this.state.productId,
          day: parseInt(this.state.day, 10),
          scheduleType: this.state.selectedTabId === 'weekly' ? 0 : 1,
          excludeSync: this.state.excludeSync,
          isActive: this.state.isActive,
          externalId: this.state.externalId,
          tags,
        };

        if (payload.scheduleType === 1 || 
          (this.state.specificDate &&
          this.state.specificDate._isAMomentObject &&
          this.state.specificDate.toISOString() &&
          !payload.scheduleType === 0)
        ) {
          payload.specificDate = this.state.specificDate.format('YYYY-MM-DD');
        }

        if (
          this.state.startDate &&
          this.state.startDate._isAMomentObject &&
          this.state.startDate.toISOString()
        ) {
          payload.startDate = this.state.startDate.format('YYYY-MM-DD');
        }

        if (
          this.state.endDate &&
          this.state.endDate._isAMomentObject &&
          this.state.endDate.toISOString()
        ) {
          payload.endDate = this.state.endDate.format('YYYY-MM-DD');
        }

        if (this.state.id && this.state.id.length > 0) {
          payload.id = this.state.id;
          // Update existing
          sessionSlotService
            .update(payload)
            .then((result) => {
              if (this.state.maxCount > 0) {
                sessionSlotService.updateMaxCount(
                  payload.id,
                  this.state.maxCount
                );
              }
              this.setState({ saveLoading: false, loading: true });
              this.closeModal();
              this.page(this.state.page);
            })
            .catch((err) => {
              this.setState({ saveLoading: false });
              if (
                err.response &&
                err.response.data &&
                err.response.data.message &&
                err.response.data.message === 'already-have-sessions'
              ) {
                swal({
                  icon: 'error',
                  title: 'Terdapat sesi terdaftar.',
                  text: 'Mohon maaf, slot jadwal tidak dapat diubah karena sudah ada sesi pasien yang terdaftar.',
                  confirmButtonText: 'OK',
                });
              } else {
                errorService.handle(err);
              }
            });
        } else {
          // Create new one
          sessionSlotService
            .create(payload)
            .then((result) => {
              this.setState({ saveLoading: false, loading: true });
              this.closeModal();
              this.page(1);
            })
            .catch((err) => {
              this.setState({ saveLoading: false });
              errorService.handle(err);
            });
        }
      });
    });
  };

  saveImport = () => {
    const payload = this.state.sessionsImport;
    sessionSlotService
            .create(payload, true)
            .then((result) => {
              swal({
                title: '',
                type: 'success',
                text: 'Success: ' + result.successCount + '      Failed: ' + result.failCount,
              }).then(() => {
                this.closeModal();
                this.page(1);
            });
            })
            .catch((err) => {
              this.setState({ saveLoading: false });
              errorService.handle(err);
            }).finally(() => {
              this.setState({ saveLoading: false, loading: true });
            });
  }

  delete = () => {
    // TODO see general#735
    //if(this.state.activeSessions == 0){
    if (!(this.state.id && this.state.id.length > 0)) {
      return;
    }
    if (!window.confirm('Apakah Anda yakin ingin menghapus item ini?')) {
      return;
    }
    sessionSlotService
      .delete(this.state.id)
      .then((result) => {
        this.setState({ saveLoading: false, loading: true });
        this.closeModal();
        this.page(1);
      })
      .catch((err) => {
        this.setState({ saveLoading: false });
        errorService.handle(err);
      });
    //}else{
    //  window.alert("Jadwal tidak bisa dihapus karena ada "+ this.state.activeSessions +" pasien terdaftar.")
    //}
  };

  closeDeleteModal = () => {
    this.setState({
      showDeleteModal: false,
      id: '',
      name: '',
      selectedOptionsAssistant: [],
      selectedOptionsClinic: [],
      selectedOptionsStaff: [],
    });
  };

  closeModal = () => {
    this.setState({
      showModal: false,
      modalPreviewSessions: false,
      showModalImport: false,
      isNewItem: false,
      id: '',
      name: '',
      selectedOptionsAssistant: [],
      selectedOptionsClinic: [],
      selectedOptionsStaff: [],
      startDate: null,
      endDate: null,
      specificDate: null,
      sessionsImport: [],
      file: null,
      productId: '',
      productName: '',
    });
  };

  onItemClick = (item) => {
    if (
      item.organizationId &&
      item.organizationId.length > 0 &&
      item.clinicId &&
      item.clinicId.length > 0
    ) {
      console.log('SESSION DETAIL =>', item);
      this.loadMaxCount(item.id);
      this.loadClinics(item.organizationId)
        .then(() => {
          return this.loadStaffsByClinicAndOrganizationId(
            item.clinicId,
            item.organizationId
          );
        })
        .then(() => {
          let selectedOptionsAssistant = [];
          if (item.assistantId) {
            selectedOptionsAssistant = [
              {
                text: item.assistantName,
                label: item.assistantName,
                value: item.assistantId,
              },
            ];
          }

          let tags = [];
          for (let i in item.tags) {
            tags.push({ label: item.tags[i] });
          }

          this.setState({
            activeSessions: item.activeSessions,
            id: item.id,
            organizationId: item.organizationId,
            organizationName: item.organizationName,
            clinicId: item.clinicId,
            clinicName: item.clinicName,
            staffId: item.staffId,
            staffName: item.staffName,
            assistantId: item.assistantId,
            assistantName: item.assistantName,
            startTime: item.startTime,
            endTime: item.endTime,
            duration: item.duration,
            productId: item.productId,
            showModal: true,
            day: item.day,
            specificDate: item.specificDate,
            excludeSync: item.excludeSync,
            isActive: item.isActive,
            externalId: item.externalId,
            selectedOptionsClinic: [
              {
                text: item.clinicName,
                label: item.clinicName,
                value: item.clinicId,
              },
            ],
            selectedOptionsStaff: [
              {
                text: item.staffName,
                label: item.staffName,
                value: item.staffId,
              },
            ],
            selectedOptionsAssistant,
            startTimeRangeOptions: Utils.generateTimeRangeOptions(
              item.duration / 60000,
              '00:00:00'
            ),
            endTimeRangeOptions: Utils.generateTimeRangeOptions(
              item.duration / 60000,
              '00:00:00'
            ),
            startDate: item.startDate,
            endDate: item.endDate,
            tags,
          });
        });
    }
  };

  onOrganizationChange = (e) => {
    let value = e.target.value || null;
    console.log(value);
    let name = '';
    for (let i in this.state.organizations) {
      if (this.state.organizations[i].value === value) {
        name =
          this.state.organizations[i].name || this.state.organizations[i].text;
      }
    }
    this.setState(
      {
        organizationId: value,
        organizationName: name,
        clinics: [],
        availableClinics: [],
        clinicName: '',
        selectedOptionsAssistant: [],
        selectedOptionsClinic: [],
        selectedOptionsStaff: [],
        clinicId: '',
        staffId: '',
        staffName: '',
        assistantId: '',
        assistantName: '',
      },
      () => {
        this.loadClinics(value);
      }
    );
  };

  onProductChange = (e) => {
    let value = e.target.value || null;
    console.log(value);
    let name = '';
    for (let i in this.state.products) {
      if (this.state.products[i].value === value) {
        name = this.state.products[i].title || this.state.products[i].text;
      }
    }
    this.setState({
      productId: value,
      productName: name,
    });
  };

  onmaxCountChange = (e) => {
    let value = e.target.value || null;
    console.log(value);
    this.setState({
      maxCount: value,
    });
  };

  onPickedClinic = (e) => {
    if (e.length > 0) {
      // Check if the previous staff is available on current clinics as the clinic's staff list got refreshed
      var prevStaffId = this.state.staffId;
      var prevStaffName = this.state.staffName;

      this.setState(
        {
          clinicId: e[0].value,
          staffId: null,
          staffName: null,
          assistantId: '',
          assistantName: '',
          selectedOptionsClinic: e,
          staffOptions: [],
          selectedOptionsStaff: [],
          selectedOptionsAssistant: [],
        },

        () => {
          this.loadStaffsByClinicAndOrganizationId(
            e[0].value,
            this.state.organizationId
          ).then(() => {
            let found = false;
            for (let i in this.state.staffOptions) {
              if (this.state.staffOptions[i].value === prevStaffId) {
                found = true;
                break;
              }
            }
            if (found) {
              this.setState({
                staffId: prevStaffId,
                staffName: prevStaffName,
              });
            } else {
              // Eui does not provide programmatical way to remove the selected option,
              // Let's put little hack here
              let elements = document.querySelectorAll(
                '[value="' + prevStaffId + '"]'
              );
              for (let i in elements) {
                if (
                  elements[i] &&
                  elements[i].tagName &&
                  elements[i].tagName === 'SPAN'
                ) {
                  elements[i].setAttribute('value', '');
                  elements[i].setAttribute('text', '');
                  elements[i].innerHTML = '';
                  break;
                }
              }
            }
          });
        }
      );
    }
  };

  loadStaffsByClinicAndOrganizationId = (clinicId, organizationId) => {
    return new Promise((resolve, reject) => {
      staffService
        .getStaffs({ clinicId, organizationId, limit: 1000 })
        .then((result) => {
          if ((result.items && result.items.length < 1) || !result.items) {
            this.setState({ clinicId: null });
            alert('Belum ada dokter dari klinik ini');
            return;
          }
          let hasDoctor = false;
          let options = [];
          let assistantOptions = [];
          for (let i in result.items) {
            if (result.items[i].type === 'DOCTOR') {
              hasDoctor = true;
              console.log(
                '-----------------------------------------------DOCTOR ',
                result.items[i].name
              );
              options.push({
                text: result.items[i].name,
                value: result.items[i].id,
                label: result.items[i].name,
              });
            } else {
              console.log(
                '-----------------------------------------------ELSE ',
                result.items[i].name
              );
              assistantOptions.push({
                text: result.items[i].name,
                value: result.items[i].id,
                label: result.items[i].name,
              });
            }
          }

          if (!hasDoctor) {
            this.setState({ clinicId: null });
            alert('Belum ada dokter dari klinik ini');
            return;
          }

          this.setState(
            { staffOptions: options, assistantOptions: assistantOptions },
            () => {
              resolve();
            }
          );
        })
        .catch((err) => {
          console.log(err);
          this.setState({ staffOptions: [] });
          reject(err);
        });
    });
  };

  onStaffChange = (e) => {
    let value = '';
    if (e.length > 0) {
      value = e[0].value;
    }

    this.setState({
      staffId: value,
      selectedOptionsStaff: e,
    });
  };

  onAssistantChange = (e) => {
    let value = '';
    if (e.length > 0) {
      value = e[0].value;
    }

    this.setState({
      assistantId: value,
      selectedOptionsAssistant: e,
    });
  };

  onDurationChange = (e) => {
    let value = e.target.value || null;
    if (!value || (value && value.length < 1)) {
      return;
    }
    console.log(value);
    this.setState({
      duration: value,
      startTime: null,
      endTime: null,
      startTimeRangeOptions: Utils.generateTimeRangeOptions(
        value / 60000,
        '00:00:00'
      ),
      endTimeRangeOptions: Utils.generateTimeRangeOptions(
        value / 60000,
        '00:00:00'
      ),
    });
  };

  onDayChange = (e) => {
    let value = e.target.value || null;
    if (!value || (value && value.length < 1)) {
      return;
    }
    console.log(value);
    this.setState({
      day: value,
    });
  };

  onStartTimeChange = (e) => {
    let value = e.target.value || null;
    if (!value || (value && value.length < 1)) {
      return;
    }
    console.log(value);
    this.setState({
      startTime: value,
      endTime: null,
      endTimeRangeOptions: Utils.generateTimeRangeOptions(
        this.state.duration / 60000,
        value
      ),
    });
  };

  onEndTimeChange = (e) => {
    let value = e.target.value || null;
    if (!value || (value && value.length < 1)) {
      return;
    }
    console.log(value);
    this.setState({
      endTime: value,
    });
  };

  onExternalIdChange = (e) => {
    this.setState({
      externalId: e.target.value,
    })
  }

  prepareNewItem = () => {
    this.setState(
      {
        showModal: true,
        isNewItem: true,
        id: '',
        clinicId: '',
        clinicName: '',
        organizationId: '',
        organizationName: '',
        staffId: '',
        staffName: '',
        assistantId: '',
        assistantName: '',
        startTime: '00:00:00',
        endTime: '00:00:00',
        duration: 900000,
        productId: this.state.products[0].value,
        productName: this.state.products[0].text,
        startTimeRangeOptions: Utils.generateTimeRangeOptions(15, '00:00:00'),
        endTimeRangeOptions: Utils.generateTimeRangeOptions(15, '00:00:00'),
        selectedOptionsAssistant: [],
        selectedOptionsClinic: [],
        selectedOptionsStaff: [],
        day: '',
        externalId: '',
        excludeSync: false,
      },
      () => {
        this.loadOrganizations();
        this.loadProducts();
      }
    );
  };

  closeModalImport = () => {
    this.setState({ 
      showModalImport: false,
      file: {},
      productId: '',
      productName: '',
     });
  };

  submitFile = () => {
    const { productId, file, startDate, endDate } = this.state;
    sessionSlotService
      .importV2({ file, productId: productId, startDate: startDate.format('YYYY-MM-DD'), endDate: endDate.format('YYYY-MM-DD')})
      .then((response) => {
        let i = 0;
        response.forEach((item) => {
          item.index = i;
          i++;
        })
        this.setState({sessionsImport: response, modalPreviewSessions: true})
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          swal({
            title: '',
            type: 'success',
            text: 'Berkas CSV gagal diimpor. Silakan periksa kembali berkas CSV Anda.',
          });
        }
        this.closeModalImport();
      });
  };

  onChangeFile = (file) => {
    this.setState({ file });
  };

  addFile = () => {
    this.setState(
      {
        id: '',
        showModalImport: true,
        isNewItem: true,
        modalPreviewSessions: false,
      }
    );
  };

  onDateRangeChange = (e) => {
    let value = e.target.value || null;
    this.setState({ dateRange: value, searchDate: '' }, () => {
      this.page(1);
    });
  };

  handleOnSearchDate = (value) => {
    const newDate = moment(value).format('YYYY-MM-DD');
    this.setState({ searchDate: newDate, loading: true });
    this.handleDateChange(value);
    this.handleSearchDebounce();
  };

  handleOnSearch = (value) => {
    this.setState({ search: value, loading: true });
    this.handleSearchDebounce();
  };

  handleSearchDebounce = debounce(() => {
    this.page(1);
  }, 2000);

  onSelectedTabChanged = (id) => {
    this.setState({ selectedTabId: id, searchDate: '' }, () => {
      this.page(1);
    });
  };

  handleDateChange = (date) => {
    // in moment object format
    console.log(date);
    this.setState({ specificDate: date });
  };

  handleDateChangeStart = (date) => {
    // in moment object format
    console.log(date);
    this.setState({ startDate: date });
  };

  handleDateChangeEnd = (date) => {
    // in moment object format
    console.log(date);
    this.setState({ endDate: date });
  };

  renderTabs = () => {
    return this.state.tabs.map((tab, index) => (
      <EuiTab
        {...(tab.href && { href: tab.href, target: '_blank' })}
        onClick={() => this.onSelectedTabChanged(tab.id)}
        isSelected={tab.id === this.state.selectedTabId}
        disabled={tab.disabled}
        key={index}
      >
        {tab.name}
      </EuiTab>
    ));
  };

  columns = [
    {
      field: 'checkbox',
      name: '',
      width: '3%',
      render: (_, record) => {
        return <><EuiCheckbox
        checked={record.isChecked}
        onChange={(e) => {
          record.isChecked = e.target.checked;
          this.setState({})
        }}
      /></>
      }
    },
    {
      field: 'staff_name',
      name: 'Staff',
      width: '25%',
      render: (_, record) => {
        return record.staff_name;
      }
    },
    {
      field: 'day',
      name: 'Hari',
      // width: '15%',
      render: (_, record) => {
        const date = new Date(record.specific_date);
        const options = { weekday: 'long' };
        const dayOfWeek = date.toLocaleDateString('id-ID', options);
        return dayOfWeek;
      }
    },
    {
      field: 'time',
      name: 'Jam',
      // width: '15%',
      render: (_, record) => {
        return `${record.start_time.slice(0, 5)} - ${record.end_time.slice(0,5)}`
      }
    },
    {
      field: 'specific_date',
      name: 'Tanggal',
      // width: '15%',
    },
    {
      field: 'clinic_name',
      name: 'Klinik',
      // width: '15%',
      render: (_, record) => {
        return record.clinic_name;
      }
    },
    {
      field: 'product_name',
      name: 'Produk',
      // width: '15%',
      render: (_, record) => 
        <>
        {console.log('tes')}
        <EuiSelect
                      placeholder='Produk'
                      options={this.state.products}
                      value={record.product_id}
                      name='productName'
                      onChange={(e) => {
                        let value = e.target.value || null;
                        for (let i in this.state.products) {
                          if (this.state.products[i].value === value) {
                            record.product_name = this.state.products[i].title || this.state.products[i].text;
                            record.product_id = this.state.products[i].value
                          }
                        }
                        this.setState({})
                      }}
                      aria-label='Produk'
                    /></>
      
    },
  ];

  onPageChange = (pageIndex) => {
    this.setState({currentPage: pageIndex});
  };

  render() {
    return (
      <>
        <EuiPage className="class">
          <EuiPageBody
            style={{ flexDirection: "row" }}
            className={"content-container"}
          >
            <div style={{ width: "100%" }}>
              <div style={{ textAlign: "left" }}>
                <EuiTitle>
                  <h2>Pengaturan Jadwal</h2>
                </EuiTitle>
                <EuiTabs>{this.renderTabs()}</EuiTabs>
              </div>
              <div>
                <TablePage
                  title={""}
                  placeholder={"Cari"}
                  searchDate={
                    <EuiFlexItem>
                      {this.state.selectedTabId !== "weekly" &&
                        this.state.dateRange === "specificDate" && (
                          <EuiDatePicker
                            value={this.state.searchDate}
                            onChange={(e) => this.handleOnSearchDate(e._d)}
                          />
                        )}
                    </EuiFlexItem>
                  }
                  searchComp={
                    <EuiFlexItem>
                      <EuiFieldText
                        onKeyDown={(e) => {
                          if (e.keyCode === 13) {
                            this.handleOnSearch(this.state.search);
                          }
                        }}
                        value={this.state.search}
                        onChange={(e) => this.handleOnSearch(e.target.value)}
                        placeholder={this.props.placeholder}
                        append={
                          <EuiButtonEmpty
                            onClick={() => this.handleOnSearch(this.state.search)}
                          >
                            Cari
                          </EuiButtonEmpty>
                        }
                      />
                    </EuiFlexItem>
                  }
                  searchOpt={
                    <EuiFlexItem grow={false}>
                      {this.state.selectedTabId !== "weekly" && (
                        <EuiSelect
                          style={{ display: "inline-block !important" }}
                          placeholder="Cari berdasarkan"
                          options={this.state.dateRangeOptions}
                          value={this.state.dateRange}
                          name="dateRange"
                          onChange={this.onDateRangeChange}
                          aria-label="Cari berdasarkan"
                        />
                      )}
                    </EuiFlexItem>
                  }
                  buttonLabel={"Tambah"}
                  buttonFunc={() => this.add()}
                  buttonImportLabel={"Import"}
                  buttonImportFunc={() => this.addFile()}
                  data={this.state.data}
                  column={this.state.column}
                  disablePagination={this.state.loading}
                  loading={this.state.loading}
                  page={this.state.page}
                  limit={this.state.limit}
                  total={this.state.total}
                  prev={this.prevPage}
                  next={this.nextPage}
                  toPage={this.page}
                  onItemClick={this.onItemClick}
                />
              </div>
            </div>
          </EuiPageBody>
        </EuiPage>
        {this.state.showDeleteModal ? (
          <EuiOverlayMask>
            <EuiConfirmModal
              title="Hapus"
              onCancel={this.closeDeleteModal}
              onConfirm={this.closeDeleteModal}
              cancelButtonText="Batal"
              confirmButtonText="Hapus"
              buttonColor="danger"
              defaultFocusedButton="confirm"
            >
              <p>Apakah anda ingin menghapus item ini ?</p>
            </EuiConfirmModal>
          </EuiOverlayMask>
        ) : null}
        {this.state.showModal && (
          <EuiOverlayMask>
            <EuiModal
              onClose={() => this.closeModal()}
              initialFocus="[name=popswitch]"
            >
              <EuiModalHeader>
                <EuiModalHeaderTitle>Slot Jadwal</EuiModalHeaderTitle>
              </EuiModalHeader>
    
              <EuiModalBody>
                <EuiForm>
                  <EuiFormRow
                    label="Organisasi"
                    isInvalid={
                      this.state.validationFields["organizationId"].isInvalid
                    }
                    error={
                      this.state.validationFields["organizationId"].isInvalid &&
                      this.state.validationFields["organizationId"].errors
                    }
                  >
                    <EuiSelect
                      placeholder="Organisasi"
                      options={this.state.organizations}
                      value={this.state.organizationId}
                      name="organizationName"
                      onChange={this.onOrganizationChange}
                      aria-label="Organisasi"
                    />
                  </EuiFormRow>
                  {this.state.organizationId &&
                    this.state.organizationId.length > 0 &&
                    this.state.organizationName &&
                    this.state.organizationName.length > 0 && (
                      <EuiFormRow>
                        <div>
                          {(!this.state.availableClinics ||
                            (this.state.availableClinics &&
                              this.state.availableClinics.length < 1)) && (
                            <span>Belum ada klinik untuk organisasi ini.</span>
                          )}
                        </div>
                      </EuiFormRow>
                    )}
                  {this.state.availableClinics &&
                    this.state.availableClinics.length > 0 &&
                    this.state.organizationId &&
                    this.state.organizationId.length > 0 &&
                    this.state.organizationName &&
                    this.state.organizationName.length > 0 && (
                      <EuiFormRow label="External ID">
                        <div>
                          <EuiFieldText
                            placeholder="External ID"
                            value={this.state.externalId}
                            onChange={(e) => {
                              this.onExternalIdChange(e);
                            }}
                            aria-label={this.state.externalId}
                          />
                        </div>
                      </EuiFormRow>
                    )}
                  {this.state.availableClinics &&
                    this.state.availableClinics.length > 0 &&
                    this.state.organizationId &&
                    this.state.organizationId.length > 0 &&
                    this.state.organizationName &&
                    this.state.organizationName.length > 0 && (
                      <EuiFormRow label="Klinik">
                        <div>
                          <EuiComboBox
                            placeholder="Klinik"
                            options={this.state.availableClinics}
                            value={this.state.clinicName}
                            name="clinicName"
                            onChange={(e) => {
                              this.onPickedClinic(e);
                            }}
                            isClearable={false}
                            aria-label="Klinik"
                            singleSelection={{ asPlainText: true }}
                            selectedOptions={this.state.selectedOptionsClinic}
                          />
                        </div>
                      </EuiFormRow>
                    )}
                  {this.state.availableClinics &&
                    this.state.availableClinics.length > 0 &&
                    this.state.clinicId &&
                    this.state.clinicId.length > 0 &&
                    this.state.staffOptions &&
                    this.state.staffOptions.length > 0 &&
                    this.state.organizationId &&
                    this.state.organizationId.length > 0 &&
                    this.state.organizationName &&
                    this.state.organizationName.length > 0 && (
                      <div style={{ marginTop: 15 }}>
                        <EuiFormRow
                          label="Dokter"
                          isInvalid={
                            this.state.validationFields["staffId"].isInvalid
                          }
                          error={
                            this.state.validationFields["staffId"].isInvalid &&
                            this.state.validationFields["staffId"].errors
                          }
                        >
                          <div>
                            <EuiComboBox
                              placeholder="Dokter"
                              options={this.state.staffOptions}
                              name="staffId"
                              onChange={(e) => {
                                this.onStaffChange(e);
                              }}
                              value={this.state.staffId}
                              isClearable={true}
                              aria-label="Klinik"
                              singleSelection={{ asPlainText: true }}
                              selectedOptions={this.state.selectedOptionsStaff}
                            />
                          </div>
                        </EuiFormRow>
    
                        <EuiFormRow
                          label="Asisten"
                          isInvalid={
                            this.state.validationFields["assistantId"].isInvalid
                          }
                          error={
                            this.state.validationFields["assistantId"].isInvalid &&
                            this.state.validationFields["assistantId"].errors
                          }
                        >
                          <div>
                            <EuiComboBox
                              placeholder="Asisten"
                              label="Asisten"
                              options={this.state.assistantOptions}
                              name="assistantId"
                              onChange={(e) => {
                                this.onAssistantChange(e);
                              }}
                              value={this.state.assistantId}
                              isClearable={true}
                              aria-label="Asisten"
                              singleSelection={{ asPlainText: true }}
                              selectedOptions={this.state.selectedOptionsAssistant}
                            />
                          </div>
                        </EuiFormRow>
                      </div>
                    )}
                  {this.state.clinicId &&
                    this.state.clinicId.length > 0 &&
                    this.state.organizationId &&
                    this.state.organizationId.length > 0 &&
                    this.state.organizationName &&
                    this.state.organizationName.length > 0 && (
                      <div style={{ marginTop: 15 }}>
                        {this.state.selectedTabId === "weekly" ? (
                          <div>
                            <EuiFormRow
                              label="Hari"
                              isInvalid={
                                this.state.validationFields["day"].isInvalid
                              }
                              error={
                                this.state.validationFields["day"].isInvalid &&
                                this.state.validationFields["day"].errors
                              }
                            >
                              <EuiSelect
                                placeholder="Hari"
                                options={this.state.dayOptions}
                                value={this.state.day}
                                name="day"
                                onChange={this.onDayChange}
                                aria-label="Hari"
                              />
                            </EuiFormRow>
                            <EuiFormRow
                              label="Tanggal Mulai"
                              isInvalid={
                                this.state.validationFields["startDate"].isInvalid
                              }
                              error={
                                this.state.validationFields["startDate"]
                                  .isInvalid &&
                                this.state.validationFields["startDate"].errors
                              }
                            >
                              {this.state.startDate ? (
                                <EuiDatePicker
                                  selected={this.state.startDate}
                                  onChange={this.handleDateChangeStart}
                                />
                              ) : (
                                <EuiDatePicker
                                  onChange={this.handleDateChangeStart}
                                />
                              )}
                            </EuiFormRow>
                            <EuiFormRow
                              label="Tanggal Akhir"
                              isInvalid={
                                this.state.validationFields["endDate"].isInvalid
                              }
                              error={
                                this.state.validationFields["endDate"].isInvalid &&
                                this.state.validationFields["endDate"].errors
                              }
                            >
                              {this.state.endDate ? (
                                <EuiDatePicker
                                  selected={this.state.endDate}
                                  onChange={this.handleDateChangeEnd}
                                />
                              ) : (
                                <EuiDatePicker
                                  onChange={this.handleDateChangeEnd}
                                />
                              )}
                            </EuiFormRow>
                          </div>
                        ) : (
                          <div>
                            <EuiFormRow
                              label="Tanggal"
                              isInvalid={
                                this.state.validationFields["specificDate"]
                                  .isInvalid
                              }
                              error={
                                this.state.validationFields["specificDate"]
                                  .isInvalid &&
                                this.state.validationFields["specificDate"].errors
                              }
                            >
                              <EuiDatePicker
                                selected={this.state.specificDate}
                                onChange={this.handleDateChange}
                              />
                            </EuiFormRow>
                          </div>
                        )}
                        <EuiFormRow
                          label="Tag"
                          isInvalid={this.state.validationFields["tags"].isInvalid}
                          error={
                            this.state.validationFields["tags"].isInvalid &&
                            this.state.validationFields["tags"].errors
                          }
                        >
                          <EuiComboBox
                            placeholder="Tag"
                            options={[]}
                            label={"Tag"}
                            isClearable={true}
                            selectedOptions={this.state.tags || []}
                            name="Tag"
                            onChange={(tags) => this.setState({ tags })}
                            onCreateOption={(val) => {
                              let tags = this.state.tags || [];
                              tags.push({ label: val });
                              this.setState({ tags: tags });
                            }}
                            aria-label="Tag"
                          />
                        </EuiFormRow>
                        <EuiFormRow
                          label="Durasi (menit)"
                          isInvalid={
                            this.state.validationFields["duration"].isInvalid
                          }
                          error={
                            this.state.validationFields["duration"].isInvalid &&
                            this.state.validationFields["duration"].errors
                          }
                        >
                          <EuiSelect
                            placeholder="Durasi"
                            options={this.state.durationOptions}
                            value={this.state.duration}
                            name="duration"
                            onChange={this.onDurationChange}
                            aria-label="Durasi"
                          />
                        </EuiFormRow>
                        <EuiFormRow
                          label="Awal"
                          isInvalid={
                            this.state.validationFields["startTime"].isInvalid
                          }
                          error={
                            this.state.validationFields["startTime"].isInvalid &&
                            this.state.validationFields["startTime"].errors
                          }
                        >
                          <EuiSelect
                            placeholder="Awal"
                            options={this.state.startTimeRangeOptions}
                            value={this.state.startTime}
                            name="startTime"
                            onChange={this.onStartTimeChange}
                            aria-label="Awal"
                          />
                        </EuiFormRow>
                        <EuiFormRow
                          label="Akhir"
                          isInvalid={
                            this.state.validationFields["endTime"].isInvalid
                          }
                          error={
                            this.state.validationFields["endTime"].isInvalid &&
                            this.state.validationFields["endTime"].errors
                          }
                        >
                          <EuiSelect
                            placeholder="Akhir"
                            options={this.state.endTimeRangeOptions}
                            value={this.state.endTime}
                            name="endTime"
                            onChange={this.onEndTimeChange}
                            aria-label="Akhir"
                          />
                        </EuiFormRow>
                        <EuiFormRow
                          label="Produk/Tarif"
                          isInvalid={
                            this.state.validationFields["productId"].isInvalid
                          }
                          error={
                            this.state.validationFields["productId"].isInvalid &&
                            this.state.validationFields["productId"].errors
                          }
                        >
                          <EuiSelect
                            placeholder="Produk/Tarif"
                            options={this.state.products}
                            value={this.state.productId}
                            name="productName"
                            onChange={this.onProductChange}
                            aria-label="Produk/Tarif"
                          />
                        </EuiFormRow>
                        <EuiFormRow label="Kecualikan dalam proses sync">
                          <EuiSwitch
                            label=""
                            onChange={() => {
                              this.setState({
                                excludeSync: !this.state.excludeSync,
                              });
                            }}
                            checked={this.state.excludeSync}
                          />
                        </EuiFormRow>
                        {this.state.id ? (
                          <EuiFormRow label="Jadwal Aktif">
                            <EuiSwitch
                              label=""
                              onChange={(e) => {
                                this.setState({
                                  isActive: e.target.checked,
                                });
                              }}
                              checked={this.state.isActive ?? false}
                            />
                          </EuiFormRow>
                        ) : (
                          <></>
                        )}
                        {this.state.id &&
                        this.state.id.length > 0 &&
                        this.state.selectedTabId === "specificDate" ? (
                          <EuiFormRow
                            label="Jumlah Slot"
                            isInvalid={
                              this.state.validationFields["maxCount"].isInvalid
                            }
                            error={
                              this.state.validationFields["maxCount"].isInvalid &&
                              this.state.validationFields["maxCount"].errors
                            }
                          >
                            <EuiFieldText
                              placeholder="Jumlah Slot"
                              options={this.state.maxCount}
                              value={this.state.maxCount}
                              name="maxCount"
                              onChange={this.onmaxCountChange}
                              aria-label="Jumlah Slot"
                            />
                          </EuiFormRow>
                        ) : (
                          <></>
                        )}
                        <br />
                        <br />
                      </div>
                    )}
                </EuiForm>
              </EuiModalBody>
    
              <EuiModalFooter>
                <EuiButtonEmpty onClick={() => this.closeModal()}>
                  Batal
                </EuiButtonEmpty>
                {this.state.id && (
                  <EuiButton color={"danger"} onClick={() => this.delete()} fill>
                    Hapus
                  </EuiButton>
                )}
                <EuiButton onClick={() => this.save()} fill>
                  Simpan
                </EuiButton>
              </EuiModalFooter>
            </EuiModal>
          </EuiOverlayMask>
        )}
        {this.state.showModalImport && (
          <EuiOverlayMask>
            <EuiModal
              onClose={() => this.closeModalImport()}
              initialFocus="[name=popswitch]"
            >
              <EuiModalHeader>
                <EuiModalHeaderTitle>Import Jadwal</EuiModalHeaderTitle>
              </EuiModalHeader>
    
              <EuiModalBody>
                <EuiForm>
                  <EuiFormRow
                    label="Produk"
                    isInvalid={this.state.validationFields["productId"].isInvalid}
                    error={
                      this.state.validationFields["productId"].isInvalid &&
                      this.state.validationFields["productId"].errors
                    }
                  >
                    <EuiSelect
                      placeholder="Produk"
                      options={this.state.products}
                      value={this.state.productId}
                      name="productName"
                      onChange={this.onProductChange}
                      aria-label="Produk"
                    />
                  </EuiFormRow>
                  <EuiFormRow
                    label="Tanggal Mulai"
                    isInvalid={this.state.validationFields["startDate"].isInvalid}
                    error={
                      this.state.validationFields["startDate"].isInvalid &&
                      this.state.validationFields["startDate"].errors
                    }
                  >
                    {this.state.startDate ? (
                      <EuiDatePicker
                        selected={this.state.startDate}
                        onChange={this.handleDateChangeStart}
                      />
                    ) : (
                      <EuiDatePicker onChange={this.handleDateChangeStart} />
                    )}
                  </EuiFormRow>
                  <EuiFormRow
                    label="Tanggal Akhir"
                    isInvalid={this.state.validationFields["endDate"].isInvalid}
                    error={
                      this.state.validationFields["endDate"].isInvalid &&
                      this.state.validationFields["endDate"].errors
                    }
                  >
                    {this.state.endDate ? (
                      <EuiDatePicker
                        selected={this.state.endDate}
                        onChange={this.handleDateChangeEnd}
                      />
                    ) : (
                      <EuiDatePicker onChange={this.handleDateChangeEnd} />
                    )}
                  </EuiFormRow>
                  <h5 style={{ margin: 12 }}>Pilih File XLSX/CSV</h5>
                  <EuiFilePicker
                    id="asdf2"
                    multiple
                    initialPromptText="Pilih atau seret dan lepas berkas"
                    onChange={(file) => {
                      this.onChangeFile(file);
                    }}
                    display="large"
                  />
                </EuiForm>
              </EuiModalBody>
    
              <EuiModalFooter>
                <EuiButtonEmpty onClick={() => this.closeModalImport()}>
                  Batal
                </EuiButtonEmpty>
                <EuiButton fill onClick={() => this.submitFile()}>
                  Proses
                </EuiButton>
              </EuiModalFooter>
            </EuiModal>
          </EuiOverlayMask>
        )}
    
        {this.state.modalPreviewSessions && (
          <EuiOverlayMask>
            <EuiModal
              onClose={() => this.setState({ modalPreviewSessions: false })}
              initialFocus="[name=popswitch]"
              maxWidth={false}
              style={{ maxWidth: 1200 }}
            >
              <EuiModalHeader>
                <EuiModalHeaderTitle>Preview Jadwal</EuiModalHeaderTitle>
              </EuiModalHeader>
              <EuiModalBody>
                <EuiForm>
                  <EuiBasicTable
                    noItemsMessage={"Belum ada data"}
                    items={this.state.sessionsImport}
                    columns={this.columns}
                    pagination={false}
                  />
                </EuiForm>
              </EuiModalBody>
    
              <EuiModalFooter>
                <EuiButton
                  onClick={() => {
                    const updatedSessionsImport = this.state.sessionsImport.filter(
                      (v) => !v.isChecked,
                    );
                    this.setState({ sessionsImport: updatedSessionsImport });
                  }}
                  color="danger"
                  fill
                >
                  Hapus
                </EuiButton>
                <EuiButtonEmpty
                  onClick={() => this.setState({ modalPreviewSessions: false })}
                >
                  Batal
                </EuiButtonEmpty>
                <EuiButton onClick={this.saveImport} fill>
                  Simpan
                </EuiButton>
              </EuiModalFooter>
            </EuiModal>
          </EuiOverlayMask>
        )}
      </>
    );
  }
}

export default SessionSlot;
