import React, { useEffect, useState } from 'react';
import {
  EuiButton,
  EuiButtonEmpty,
  EuiForm,
  EuiFormRow,
  EuiLoadingSpinner,
  EuiModal,
  EuiModalBody,
  EuiModalFooter,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiOverlayMask,
  EuiPanel,
  EuiSelect,
  EuiSpacer,
  EuiText,
} from '@elastic/eui';

import ClinicService from '../../services/Clinics';
import ErrorService from '../../services/Error.js';
import OrganizationService from '../../services/Organizations';
import SessionService from '../../services/Sessions';
import SessionSlotService from '../../services/SessionSlots';
import Staff from '../../services/Staffs';
import moment from 'moment';
import swal from 'sweetalert';

const clinicService = new ClinicService();
const errorService = new ErrorService();
const organizationService = new OrganizationService();
const sessionsService = new SessionService();
const sessionSlotService = new SessionSlotService();
const staffService = new Staff();

const ChangeScheduleModal = (props) => {
  const { sessionItem, onScheduleChange } = props;
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [availableClinicOrganizations, setAvailableClinicOrganizations] =
    useState([]);
  const [availableClinics, setAvailableClinics] = useState([]);
  const [availableDoctors, setAvailableDoctors] = useState([]);
  const [availableSessionSlots, setAvailableSessionSlots] = useState([]);
  const [clinicOrganizationId, setClinicOrganizationId] = useState('');
  const [pickedClinicId, setPickedClinicId] = useState('');
  const [pickedDoctorId, setPickedDoctorId] = useState('');
  const [pickedSessionSlotId, setPickedSessionSlotId] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [pickedSpecificDate, setSpecificDate] = useState('');
  const [pickedSchedule, setPickedSchedule] = useState('');
  const [pickedScheduleFee, setPickedScheduleFee] = useState(0);

  useEffect(() => {
    loadOrganizations();
  }, []);

  const handleSubmit = () => {
    // compare old and new slot (only on RSCM)
    // 1. Same doctor, pass through
    // 2. Different doctor with different price return error &
    //    suggest manual refund and patient re-register.
    // 3. Different doctor with same price
    
    // 2 & 3 
    if (sessionItem.staffId !== pickedDoctorId) {
      return swal({
        title: '',
        text: `Mohon maaf, reschedule tidak dapat dilakukan 
        karena dokter atau biaya yang berbeda. 
        Silakan ajukan refund ke pasien dan daftar ulang di sesi yang dituju.`,
        confirmButtonText: 'OK',
        html: true,
      });
    }

    let payload = {};

    payload.to_slot = pickedSessionSlotId;
    payload.from_session = sessionItem.id;
    payload.specific_date = pickedSpecificDate;
    setLoading(true);
    sessionsService
      .moveSessionToAnotherSlot(pickedSessionSlotId, payload)
      .then(() => {
        setLoading(false);
        setSuccess(true);
        closeModal();
        onScheduleChange(payload);
      })
      .catch((err) => {
        setLoading(false);
        errorService.handle(err);
      });
  };

  const closeModal = (resetSuccessState) => {
    if (resetSuccessState) {
      setSuccess(false);
    }
    setIsModalVisible(false);
  };

  const showModal = () => setIsModalVisible(true);
  const loadOrganizations = () => {
    let payload = {
      limit: 1000,
    };
    organizationService
      .getOrganizations(payload)
      .then((result) => {
        let options = [{ value: '', text: 'Pilih organisasi' }];
        for (let i in result.items) {
          options.push({
            value: result.items[i].id,
            text: result.items[i].name,
          });
        }
        setAvailableClinicOrganizations(options);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadClinics = (organizationId) => {
    let payload = {
      limit: 1000,
      organizationId: organizationId,
    };
    clinicService
      .getClinics(payload)
      .then((result) => {
        let options = [];
        for (let i in result.items) {
          let exist = false;
          for (let j in availableClinics) {
            if (result.items[i].id === availableClinics[j].id) {
              exist = true;
              break;
            }
          }
          if (exist) {
            continue;
          }
          options.push({
            value: result.items[i].id,
            text: result.items[i].name,
          });
        }
        if (options && options.length < 1) {
          options.splice(0, 0, {
            value: '',
            text: 'Tidak ada klinik tersedia',
          });
        } else {
          options.splice(0, 0, {
            value: '',
            text: 'Pilih klinik..',
          });
        }
        setAvailableClinics(options);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadDoctors = (clinicId) => {
    let payload = {
      limit: 1000,
      clinicId: clinicId,
    };
    staffService
      .getStaffs(payload)
      .then((result) => {
        let options = [];
        for (let i in result.items) {
          let exist = false;
          for (let j in availableDoctors) {
            if (result.items[i].id === availableDoctors[j].id) {
              exist = true;
              break;
            }
          }
          if (exist) {
            continue;
          }
          options.push({
            value: result.items[i].id,
            text: result.items[i].name,
          });
        }
        if (options && options.length < 1) {
          options.splice(0, 0, {
            value: '',
            text: 'Tidak ada dokter tersedia',
          });
        } else {
          options.splice(0, 0, {
            value: '',
            text: 'Pilih dokter..',
          });
        }
        setAvailableDoctors(options);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadSessionSlot = (staffId) => {
    let payload = {
      limit: 1000,
      staffId: staffId,
      clinicId: pickedClinicId,
      searchBy: '',
      sinceToday: 'TRUE',
    };
    sessionSlotService
      .getSessionSlots(payload)
      .then((result) => {
        let options = [];
        let allSchedules = [];

        for (let i in result.items) {
          if (result.items[i].specificDate != null) {
            allSchedules.push({ ...result.items[i] });
          } else {
            for (let x = 0; x <= 14; x++) {
              let date = moment();
              let endDate = moment(result.items[i].endDate);
              date.add(x, 'days');
              if (
                result.items[i].day === date.day() &&
                date.isBefore(endDate)
              ) {
                const schedule = result.items[i];
                schedule.specificDate = date.format('YYYY-MM-DD');
                allSchedules.push({ ...schedule });
              }
            }
          }
        }

        allSchedules.sort(function (a, b) {
          return moment(a.specificDate).diff(moment(b.specificDate));
        });

        for (let i in allSchedules) {
          let exist = false;
          for (let j in availableSessionSlots) {
            if (allSchedules[i].id === availableSessionSlots[j].id) {
              exist = true;
              break;
            }
          }
          if (
            allSchedules[i].id === sessionItem.slotId &&
            allSchedules[i].specificDate === sessionItem.scheduleDate
          ) {
            exist = true;
          }
          if (exist) {
            continue;
          }

          options.push({
            value: `${i}`,
            date: allSchedules[i].specificDate,
            id: allSchedules[i].id,
            text: `${allSchedules[i].specificDate}, ${allSchedules[i].startTime}-${allSchedules[i].endTime} `,
          });
        }
        if (options && options.length < 1) {
          options.splice(0, 0, {
            value: '',
            text: 'Tidak ada jadwal tersedia',
          });
        } else {
          options.splice(0, 0, {
            value: '',
            text: 'Pilih jadwal..',
          });
        }
        setAvailableSessionSlots(options);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onClinicOrganizationChange = (e) => {
    let value = e.target.value || null;
    setClinicOrganizationId(value);
    loadClinics(value);
  };

  const onPickedClinic = (e) => {
    let value = e.target.value || null;
    if (!value || (value && value.length < 1)) {
      return;
    }

    setPickedClinicId(value);
    loadDoctors(value);
  };

  const onPickedDoctor = (e) => {
    let value = e.target.value || null;
    if (!value || (value && value.length < 1)) {
      return;
    }

    setPickedDoctorId(value);
    loadSessionSlot(value);
  };

  const onPickedSessionSlot = (e) => {
    let value = e.target.value || null;
    if (!value || (value && value.length < 1)) {
      return;
    }
    
    let selected = availableSessionSlots.find(function (e) {
      return e.value === value;
    });
    if (selected) {
      setPickedSchedule(selected.value);
      setPickedSessionSlotId(selected.id);
      setSpecificDate(selected.date);
      setPickedScheduleFee(selected.fee);
    }
  };

  let oldSlotPanel = (
    <EuiPanel>
      <EuiText>
        <p>
          <b>{sessionItem?.patientName}</b>
          <br />
          <small>{sessionItem?.patientExternalId}</small>
          <br />
          <br />
          Jadwal Saat Ini
          <br />
          <small>
            <b>{sessionItem?.staffName}</b>
          </small>
          <br />
          <small>{sessionItem?.clinicName}</small>
          <br />
          <small>
            {`${moment(sessionItem?.scheduleDate).format('dddd')}`},{' '}
            {`${moment(sessionItem?.scheduleDate).format('LL')}`} pukul{' '}
            {sessionItem?.scheduleTime} <br />
          </small>
          <br />
        </p>
      </EuiText>
    </EuiPanel>
  );

  let form = (
    <EuiPanel>
      <EuiForm>
        <p>Jadwal Baru</p>
        <br />
        <EuiFormRow label='Nama Unit'>
          <EuiSelect
            placeholder='Unit'
            options={availableClinicOrganizations}
            value={clinicOrganizationId}
            name='clinicOrganizationId'
            onChange={(e) => {
              onClinicOrganizationChange(e);
            }}
            aria-label='Organisasi'
          />
        </EuiFormRow>
        {clinicOrganizationId && clinicOrganizationId.length > 0 && (
          <EuiFormRow label='Nama Klinik'>
            <div>
              <EuiSelect
                placeholder='Klinik'
                options={availableClinics}
                value={pickedClinicId}
                name='pickedClinicName'
                onChange={(e) => {
                  onPickedClinic(e);
                }}
                aria-label='Klinik'
              />
            </div>
          </EuiFormRow>
        )}
        {pickedClinicId && pickedClinicId.length > 0 && (
          <EuiFormRow label='Nama Dokter'>
            <div>
              <EuiSelect
                placeholder='Dokter'
                options={availableDoctors}
                value={pickedDoctorId}
                name='pickedDoctorName'
                onChange={(e) => {
                  onPickedDoctor(e);
                }}
                aria-label='Dokter'
              />
            </div>
          </EuiFormRow>
        )}
        {pickedDoctorId && pickedDoctorId.length > 0 && (
          <EuiFormRow label='Pilih Jadwal'>
            <div>
              <EuiSelect
                placeholder='Jadwal'
                options={availableSessionSlots}
                value={pickedSchedule}
                name='pickedSessionSlotId'
                onChange={(e) => {
                  onPickedSessionSlot(e);
                }}
                aria-label='Jadwal'
              />
            </div>
          </EuiFormRow>
        )}
      </EuiForm>
    </EuiPanel>
  );

  let modal;
  if (isModalVisible) {
    modal = (
      <EuiOverlayMask>
        <EuiModal onClose={closeModal} initialFocus='[name=popswitch]'>
          <EuiModalHeader>
            <EuiModalHeaderTitle>
              Lihat / Ubah Jadwal Pasien
            </EuiModalHeaderTitle>
          </EuiModalHeader>

          <EuiModalBody>
            {oldSlotPanel}
            <EuiSpacer />
            <br />
            <center> Menjadi </center>
            <br />
            {loading ? (
              <p>
                <EuiLoadingSpinner size='xl' />
              </p>
            ) : (
              form
            )}
          </EuiModalBody>

          {loading ? (
            ''
          ) : !success ? (
            <EuiModalFooter>
              <EuiButton onClick={handleSubmit} fill>
                Ubah
              </EuiButton>
              <EuiButtonEmpty onClick={closeModal}>Batal</EuiButtonEmpty>
            </EuiModalFooter>
          ) : (
            <EuiModalFooter>
              <EuiButtonEmpty onClick={() => closeModal(true)}>
                Kembali
              </EuiButtonEmpty>
            </EuiModalFooter>
          )}
        </EuiModal>
      </EuiOverlayMask>
    );
  }
  return (
    <>
      <EuiButtonEmpty className='btnList' size='xs' onClick={showModal}>
        Reschedule
      </EuiButtonEmpty>
      {modal}
    </>
  );
};

export default ChangeScheduleModal;