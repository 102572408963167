import React, { useEffect, useState } from 'react';
import { EuiBadge, EuiLoadingSpinner, EuiToolTip } from '@elastic/eui';
import SessionService from '../../services/Sessions';
import EventsService from '../../services/Events';
import CustomBadge from '../../components/Badge';

const sessionsService = new SessionService();
const eService = new EventsService();

const EventState = {
    Unknown: 0,
    Error: 1,
    Success: 2,
    DataCleared: 3,
    ChannelCancelUp: 4,
};

const diagnosticStatus = (sessionId, ref) => {
    let diagnosticMsg = 'TIDAK ADA';
    let status = '';
    console.log("DIAG", ref)
    if (ref !== null && ref !== undefined) {
        if (ref.session_id === sessionId) {
            switch (ref.status) {
                case 1:
                    status = 'Uji Lab belum dibayar';
                    break;
                case 2:
                    status = 'Uji Lab sudah dibayar';
                    break;
                case 3:
                    status = 'Uji Lab sedang diproses';
                    break;
                case 10:
                    status = 'Uji Lab sudah dibayar';
                    break;
                default:
                    status = 'Tagihan Uji Lab belum dibuat';
                    break;
            }
            if (ref && ref.lab_vendor) {
                diagnosticMsg =
                    ref.lab_vendor.toString().toUpperCase() + ' - ' + status;
            }
        }
    }
    return diagnosticMsg;
};

const statusColor = (status) => {
    switch (status) {
        case 0:
        case false:
            return '#D9D9D9';
        case 1:
        case true:
            return '#C8EEBE';
        case 2:
            return '#C8EEBE';
        default:
            return '#C8EEBE';
    }
};

const indicatorColor = (status) => {
    switch (status) {
        case EventState.Unknown:
            return '#000000';
        case EventState.Error:
            return '#FF2424';
        case EventState.DataCleared:
        case EventState.ChannelCancelUp:
            return '#FFB524';
        case EventState.Success:
            return '#21B214';
        default:
            return '#000000';
    }
};

const eventStatus = (status) => {
    switch (status) {
        case EventState.Unknown:
            return 'Unknown';
        case EventState.Error:
            return 'Error';
        case EventState.Success:
            return 'Success';
        case EventState.DataCleared:
            return 'Data Cleared';
        case EventState.ChannelCancelUp:
            return 'Channel Cancel Up';
        default:
            return 'Unknown';
    }
};

const diagnosticBySession = async (sessionId) => {
    try {
        let _diag = await sessionsService.getDiagnosticBySessionId(sessionId);
        if (_diag != null && _diag.items != null && _diag.items.length > 0) {
            return _diag.items[0];
        }
    } catch (e) {
        // skip not found error
        if (e.response.status !== 404) {
            console.log(e);
        }
    }
    return null;
};

const eventsBySession = async (sessionId) => {
    let payload = {
        secondary_key: sessionId,
        code: 'THIRDPARTY_SEND_DIAGNOSTIC_REQ',
        limit: 1,
        order: 'DESC',
        orderBy: 'timestamp',
    };
    try {
        let _events = await eService.list(payload);
        if (_events.list[0].result_state >= 0) {
            return _events.list[0].result_state;
        }
    } catch (e) {
        console.log(e);
    }
    return EventState.Unknown;
};

const DiagnosticBadge = (props) => {
    const { sessionId } = props;
    const [Color, setColor] = useState(null);
    const [Status, setStatus] = useState(null);
    const [EventStatus, setEventStatus] = useState(null);
    const [IconColor, setStatusColor] = useState(null);

    useEffect(() => {
        const getData = async () => {
            const item = await diagnosticBySession(sessionId);
            const status = diagnosticStatus(sessionId, item);
            const color = statusColor(item !== null);
            if (status !== 'TIDAK ADA') {
                const eventState = await eventsBySession(sessionId);
                const iconColor = indicatorColor(eventState);
                setEventStatus(eventStatus(eventState));
                setStatusColor(iconColor);
            }

            setStatus(status);
            setColor(color);
        };
        getData();
    }, [sessionId]);

    if (Color !== null) {
        const tooltip = EventStatus
            ? 'THIRDPARTY_SEND_DIAGNOSTIC_REQ: ' + EventStatus
            : Status;
        return (
            <EuiToolTip position='top' content={tooltip}>
                <CustomBadge color={Color} text={Status} iconColor={IconColor} />
            </EuiToolTip>
        );
    } else {
        return (
            <EuiBadge
                color={'#fefefe'}
                style={{ color: '#000000', borderRadius: '10px' }}
            >
                <EuiLoadingSpinner size='l' />
            </EuiBadge>
        );
    }
};

export default DiagnosticBadge;
