import React, { useEffect, useState } from 'react';
import { debounce } from 'lodash';
import {
    EuiButton,
    EuiButtonEmpty,
    EuiFieldText,
    EuiForm,
    EuiFormRow,
    EuiModal,
    EuiModalBody,
    EuiModalFooter,
    EuiModalHeader,
    EuiModalHeaderTitle,
    EuiOverlayMask,
    EuiBasicTable,
    EuiFlexGroup,
    EuiFlexItem,
    EuiComboBox,
    EuiTextArea,
    EuiButtonIcon,
    EuiListGroupItem,
    EuiIcon,
} from '@elastic/eui';

import swal from 'sweetalert';
import LaboratoryService from '../services/Laboratory';

const laboratoryService = new LaboratoryService();

const AddDiagnosticsLabModal = (props) => {
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [options, setOptions] = useState([]);
    const [items, setItems] = useState([]);
    const [addForm, setAddForm] = useState(false);
    const [selectedOptions, setSelected] = useState([]);
    const [listDiagnosticLab, setListDiagnosticLab] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [note, setNote] = useState('');
    const [searchName, setSearchName] = useState('');

    const hideFormAdd = () => {
        setAddForm(false);
    };
    const closeModal = () => {
        setIsModalVisible(false);
        setItems([]);
        setSelected([]);
        setListDiagnosticLab([]);
    };
    const showModal = () => setIsModalVisible(true);
    const showModalAddForm = () => setAddForm(true);
    const closeModalAddForm = () => setAddForm(false);

    useEffect(() => {
        getDiagnosticLabBySession();
        getSuggestion();
    }, []);

    const onChangeDiagnosticLab = (v) => {
        setSelected(v);
    };

    const getDiagnosticLabBySession = async () => {
        try {
            let result = await laboratoryService.getDiagnosticRequestBySession(props.item.id);
            let item = [];

            if (result.items) {
                for (let i in result.items) {
                    if (result.items[i].services) {
                        for (let j in result.items[0].services) {
                            item.push({
                                id: result.items[i].services[j].id,
                                name: result.items[i].services[j].name,
                                label: result.items[i].services[j].name,
                                notes: result.items[i].services[j].notes,
                                preparations: result.items[i].services[j].preparations,
                            });
                        }
                        setItems(item);
                    }
                }
            }
        } catch (e) {
            // parse error message
            let message = e?.message || '';
            if (e?.response?.data?.code) {
                message = e.response.data.code;
            }
            swal({
                title: 'List Uji Lab gagal dimuat',
                text: message,
                icon: 'error',
                button: 'OK',
            });
        }
    };

    const handOnSearch = (name) => {
        setIsLoading(true);
        setSearchName(name);
        handleSearchDebounce();
    };

    const handleSearchDebounce = debounce(() => {
        getSuggestion();
    }, 2000);

    const getSuggestion = async () => {
        try {
            let payload = {};
            payload.Page = 1;
            payload.Limit = 10;
            payload.Order = 'ASC';
            payload.Search = searchName;
            let result = await laboratoryService.getDiagnosticService(payload);
            let item = [];
            if (result.data) {
                for (let i in result.data) {
                    item.push({
                        id: result.data[i].id,
                        name: result.data[i].name,
                        label: result.data[i].name,
                        external_id: result.data[i].external_id,
                        type: result.data[i].type,
                        preparations: result.data[i].preparations,
                    });
                }
                setOptions(item);
            }
        } catch (e) {
            // parse error message
            let message = e?.message || '';
            if (e?.response?.data?.code) {
                message = e.response.data.code;
            }
            swal({
                title: 'List Data Uji Lab gagal dimuat',
                text: message,
                icon: 'error',
                button: 'OK',
            });
        } finally {
            setIsLoading(false);
        }
    };

    const sendDiagnosticLab = async () => {
        try {
            let payload = {
                author_id: props.item.staffId,
                patient_id: props.item.patientId,
                session_id: props.item.id,
                services: items,
            };
            let result = await laboratoryService.createDiagnosticRequest(payload);
            if (result !== '') {
                swal({
                    title: 'Berhasil mengirim data Uji Lab',
                    icon: 'success',
                    button: 'OK',
                }).then(() => {
                    // refresh the page
                    window.location.reload();
                });
            }
        } catch (e) {
            // parse error message
            let message = e?.message || '';
            if (e?.response?.data?.code) {
                message = e.response.data.code;
            }
            swal({
                title: 'Gagal mengirim data Uji Lab',
                text: message,
                icon: 'error',
                button: 'OK',
            });
        }
    };

    const columns = [
        {
            field: 'name',
            name: 'Nama Uji Lab',
        },
        {
            field: 'notes',
            name: 'Catatan Tambahan',
        },
    ];

    const formSample = (
        <>
            <EuiForm>
                <EuiFormRow label="Uji Lab">
                    <EuiFieldText name="vendor" value={props.item.preferredLab !== 'Belum ada lab yang dipilih' ? 'Prodia' : '-'} readOnly={true} />
                </EuiFormRow>
                <EuiFormRow label="Outlet">
                    <EuiFieldText name="outlet" value={props.item.preferredLab ? props.item.preferredLab : 'Loading...'} readOnly="true" />
                </EuiFormRow>
                <br />

                <EuiBasicTable noItemsMessage={'Belum ada data'} items={items} columns={columns} />
            </EuiForm>
        </>
    );

    let modal;

    if (isModalVisible) {
        modal = (
            <EuiOverlayMask>
                <EuiModal onClose={closeModal} maxWidth={false} style={{ maxWidth: 1200 }}>
                    <EuiModalHeader>
                        <EuiModalHeaderTitle>
                            <span style={{ marginRight: 20 }}>Tambah Uji Lab</span>
                            <EuiButton disabled={props.item.preferredLab && props.item.preferredLab === 'Belum ada lab yang dipilih'} color={'secondary'} size="s" iconType={'plusInCircleFilled'} fill onClick={showModalAddForm}>
                                Tambah
                            </EuiButton>
                        </EuiModalHeaderTitle>
                    </EuiModalHeader>

                    <EuiModalBody>{formSample}</EuiModalBody>

                    <EuiModalFooter style={{ padding: '0 24px 24px !important' }}>
                        <EuiButton disabled={items.length === 0} fill onClick={sendDiagnosticLab}>
                            Kirim
                        </EuiButton>
                        <EuiButtonEmpty onClick={closeModal}>Batal</EuiButtonEmpty>
                    </EuiModalFooter>
                </EuiModal>
            </EuiOverlayMask>
        );
    }

    let modalAddForm;
    if (addForm) {
        modalAddForm = (
            <>
                <EuiOverlayMask>
                    <EuiModal onClose={closeModalAddForm}>
                        <EuiModalHeader>
                            <EuiModalHeaderTitle>
                                <span style={{ marginRight: 20 }}>Tambah Uji Lab</span>
                            </EuiModalHeaderTitle>
                        </EuiModalHeader>
                        <EuiModalBody style={{ width: '500px' }}>
                            <EuiFlexGroup columns={2} style={{ margin: 0 }}>
                                <EuiFlexItem style={{ justifyContent: 'center', whiteSpace: 'pre-line' }}>Nama Uji Lab</EuiFlexItem>
                                <EuiFlexItem grow={4}>
                                    <EuiComboBox
                                        placeholder="Masukkan nama uji lab"
                                        options={options}
                                        selectedOptions={selectedOptions}
                                        onSearchChange={handOnSearch}
                                        onChange={onChangeDiagnosticLab}
                                        singleSelection={{ asPlainText: true }}
                                        isLoading={isLoading}
                                    />
                                </EuiFlexItem>
                            </EuiFlexGroup>
                            <EuiFlexGroup columns={2} style={{ margin: 0 }}>
                                <EuiFlexItem style={{ justifyContent: 'center', whiteSpace: 'pre-line' }}>Catatan Tambahan</EuiFlexItem>
                                <EuiFlexItem grow={4}>
                                    <EuiTextArea placeholder="Masukkan catatan tambahan" value={note} onChange={(e) => setNote(e.target.value)} aria-label="Note" />
                                </EuiFlexItem>
                            </EuiFlexGroup>
                            <EuiFlexItem>
                                <EuiButtonIcon
                                    disabled={selectedOptions.length === 0}
                                    iconSize="l"
                                    style={{ height: '100%' }}
                                    color="success"
                                    iconType="plusInCircleFilled"
                                    onClick={() => {
                                        setListDiagnosticLab((prev) => {
                                            selectedOptions[0].notes = note;
                                            return [...prev, ...selectedOptions];
                                        });
                                        setNote('');
                                    }}
                                />
                            </EuiFlexItem>
                            {listDiagnosticLab.map((e, i) => (
                                <>
                                    <EuiFlexGroup columns={2} style={{ margin: 0 }}>
                                        <EuiFlexItem style={{ justifyContent: 'center', flexGrow: '0', marginBottom: '0', marginTop: '0' }}>
                                            <EuiIcon type="checkInCircleFilled" />
                                        </EuiFlexItem>
                                        <EuiFlexItem grow={4} style={{ marginBottom: '0', marginTop: '0' }}>
                                            <EuiListGroupItem label={e.label} />
                                        </EuiFlexItem>
                                        <EuiFlexItem grow={4} style={{ marginBottom: '0', marginTop: '0' }}>
                                            <EuiListGroupItem label={e.notes} />
                                        </EuiFlexItem>
                                    </EuiFlexGroup>
                                </>
                            ))}
                        </EuiModalBody>
                        <EuiModalFooter style={{ padding: '0 24px 24px !important' }}>
                            <EuiButton
                                size="s"
                                iconType="plusInCircleFilled"
                                color={'secondary'}
                                fill
                                onClick={() => {
                                    setItems((prev) => [...prev, ...listDiagnosticLab]);
                                    hideFormAdd();
                                    setListDiagnosticLab([]);
                                    setSelected([]);
                                }}
                            >
                                Tambah
                            </EuiButton>
                            <EuiButton color={'danger'} size="s" iconType="crossInACircleFilled" fill onClick={hideFormAdd}>
                                Batal
                            </EuiButton>
                        </EuiModalFooter>
                    </EuiModal>
                </EuiOverlayMask>
            </>
        );
    }

    return (
        <>
            <EuiButtonEmpty size="xs" className="btnList" onClick={showModal}>
                Tambah Uji Lab
            </EuiButtonEmpty>
            {modal}
            {modalAddForm}
        </>
    );
};

export default AddDiagnosticsLabModal;
