import React, { useEffect, useState } from 'react';
import { EuiBadge, EuiLoadingSpinner, EuiToolTip } from '@elastic/eui';
import SessionService from '../../services/Sessions';
import EventsService from '../../services/Events';
import CustomBadge from '../../components/Badge';

const sessionsService = new SessionService();
const eService = new EventsService();

const EventState = {
  Unknown: 0,
  Error: 1,
  Success: 2,
  DataCleared: 3,
  ChannelCancelUp: 4,
};

const prescriptionStatus = (sessionId, ref) => {
  let prescriptionMsg = 'TIDAK ADA';
  let status = '';
  if (ref !== null && ref !== undefined) {
    if (ref.session_id === sessionId) {
      switch (ref.status) {
        case 1:
          status = 'Resep belum dibayar';
          break;
        case 2:
          status = 'Resep sudah dibayar';
          break;
        case 3:
          status = 'Resep sedang diproses';
          break;
        case 4:
          status = 'Resep siap diambil';
          break;
        case 10:
          status = 'Resep sudah dibayar';
          break;
        default:
          status = 'Tagihan resep belum dibuat';
          break;
      }
      if (ref && ref.pharmacy_vendor) {
        prescriptionMsg =
          ref.pharmacy_vendor.toString().toUpperCase() + ' - ' + status;
      }
    }
  }
  return prescriptionMsg;
};

const statusColor = (status) => {
  switch (status) {
    case 0:
    case false:
      return '#D9D9D9';
    case 1:
    case true:
      return '#C8EEBE';
    case 2:
      return '#C8EEBE';
    default:
      return '#C8EEBE';
  }
};

const indicatorColor = (status) => {
  switch (status) {
    case EventState.Unknown:
      return '#000000';
    case EventState.Error:
      return '#FF2424';
    case EventState.DataCleared:
    case EventState.ChannelCancelUp:
      return '#FFB524';
    case EventState.Success:
      return '#21B214';
    default:
      return '#000000';
  }
};

const eventStatus = (status) => {
  switch (status) {
    case EventState.Unknown:
      return 'Unknown';
    case EventState.Error:
      return 'Error';
    case EventState.Success:
      return 'Success';
    case EventState.DataCleared:
      return 'Data Cleared';
    case EventState.ChannelCancelUp:
      return 'Channel Cancel Up';
    default:
      return 'Unknown';
  }
};

const prescriptionBySession = async (sessionId) => {
  try {
    let _presc = await sessionsService.getPrescriptionBySessionId(sessionId);
    if (_presc != null && _presc.items != null) {
      return _presc.ref;
    }
  } catch (e) {
    // skip not found error
    if (e.response.status !== 404) {
      console.log(e);
    }
  }
  return null;
};

const eventsBySession = async (sessionId) => {
  let payload = {
    secondary_key: sessionId,
    code: 'THIRDPARTY_SEND_PRESCRIPTION',
    limit: 1,
    order: 'DESC',
    orderBy: 'timestamp',
  };
  try {
    let _events = await eService.list(payload);
    if (_events.list[0].result_state >= 0) {
      return _events.list[0].result_state;
    }
  } catch (e) {
    console.log(e);
  }
  return EventState.Unknown;
};

const PrescriptionBadge = (props) => {
  const { sessionId } = props;
  const [Color, setColor] = useState(null);
  const [Status, setStatus] = useState(null);
  const [EventStatus, setEventStatus] = useState(null);
  const [IconColor, setStatusColor] = useState(null);

  useEffect(() => {
    const getData = async () => {
      const item = await prescriptionBySession(sessionId);
      const status = prescriptionStatus(sessionId, item);
      const color = statusColor(item !== null);
      if (status !== 'TIDAK ADA') {
        const eventState = await eventsBySession(sessionId);
        const iconColor = indicatorColor(eventState);
        setEventStatus(eventStatus(eventState));
        setStatusColor(iconColor);
      }

      setStatus(status);
      setColor(color);
    };
    getData();
  }, [sessionId]);

  if (Color !== null) {
    const tooltip = EventStatus
      ? 'THIRDPARTY_SEND_PRESCRIPTION: ' + EventStatus
      : Status;
    return (
      <EuiToolTip position='top' content={tooltip}>
        <CustomBadge color={Color} text={Status} iconColor={IconColor} />
      </EuiToolTip>
    );
  } else {
    return (
      <EuiBadge
        color={'#fefefe'}
        style={{ color: '#000000', borderRadius: '10px' }}
      >
        <EuiLoadingSpinner size='l' />
      </EuiBadge>
    );
  }
};

export default PrescriptionBadge;
