/* eslint-disable array-callback-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { Component, useEffect } from 'react';

import {
  EuiButtonEmpty,
  EuiForm,
  EuiFormRow,
  EuiSpacer,
  EuiText,
  EuiPanel,
  EuiDatePicker,
  EuiFlexItem,
  EuiFlexGroup,
  EuiPinnableListGroup,
  EuiCollapsibleNavGroup,
  EuiFieldText,
  EuiSelect,
  EuiRadioGroup,
  EuiTextArea,
  EuiPage,
  EuiPageBody,
  EuiPageContent,
  EuiPageContentHeader,
  EuiPageContentHeaderSection,
  EuiPageContentBody,
  EuiTitle,
  EuiLoadingSpinner,
  EuiOverlayMask,
  EuiModal,
  EuiModalHeader,
  EuiModalHeaderTitle,
  EuiModalBody,
  EuiModalFooter,
  EuiButton,
  EuiIcon,
  EuiBadge,
  EuiTab,
  EuiTabs,
  EuiPopover,
  EuiButtonIcon,
  EuiHorizontalRule,
  EuiFilePicker,
} from '@elastic/eui';
import AddPrescriptionsModal from '../components/AddPrescriptionModal';
import ChangeScheduleModal from './consultation/RescheduleModal';
import DiagnosticBadge from './consultation/Laboratory';
import TablePage from '../components/TablePage';
import SessionService from '../services/Sessions';
import ErrorService from '../services/Error.js';
import OrganizationService from '../services/Organizations';
import Payments from '../services/Payments';
import WeblinkService from '../services/Weblink';
import Config from '../Config';
import PrescriptionBadge from './consultation/Prescription';
import SoapBadge from './consultation/Soap';
import SessionBadge from './consultation/Session';
import SettingServices from '../services/Settings';

import moment from 'moment';
import debounce from 'debounce';
import swal from 'sweetalert';
import Blobs from '../services/Blob';
import downloadjs from 'downloadjs';

import '../App.css';
import AddDiagnosticsLabModal from '../components/AddDiagnosticsLabModal';

const blobService = new Blobs();
const errorService = new ErrorService();
const sessionsService = new SessionService();
const settingService = new SettingServices()
const organizationService = new OrganizationService();
const paymentService = new Payments();
const weblinkService = new WeblinkService();

const ChangeCloseModal = (props) => {
  const { sessionItem, onScheduleChange } = props;
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [availableClinicOrganizations, setAvailableClinicOrganizations] =
    React.useState([]);
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [radioIdSelected, setRadioIdSelected] = React.useState(4); // default 4 (ended)
  const [reason, setReason] = React.useState(sessionItem?.description);

  useEffect(() => {
    loadOrganizations();
  }, []);

  const handleSubmit = () => {
    let payload = {};
    payload.Status = radioIdSelected;
    payload.Description = reason;
    payload.Id = sessionItem.id;

    setLoading(true);
    sessionsService
      .updateSessionStatus(payload)
      .then(() => {
        setLoading(false);
        setSuccess(true);
        closeModal();
        onScheduleChange();
      })
      .catch((err) => {
        setLoading(false);
        errorService.handle(err);
      });
  };

  const closeModal = (resetSuccessState) => {
    if (resetSuccessState) {
      setSuccess(false);
    }
    setIsModalVisible(false);
    setRadioIdSelected(4);
    setReason(sessionItem?.description);
  };

  const showModal = () => setIsModalVisible(true);
  const loadOrganizations = () => {
    let payload = {
      limit: 1000,
    };
    organizationService
      .getOrganizations(payload)
      .then((result) => {
        let options = [{ value: '', text: 'Pilih organisasi' }];
        for (let i in result.items) {
          options.push({
            value: result.items[i].id,
            text: result.items[i].name,
          });
        }
        setAvailableClinicOrganizations(options);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  let oldSlotPanel = (
    <EuiPanel>
      <EuiText>
        <p>
          <b>{sessionItem?.patientName}</b>
          <br />
          <small>{sessionItem?.patientExternalId}</small>
          <br />
          <br />
          Jadwal Saat Ini
          <br />
          <small>
            <b>{sessionItem?.staffName}</b>
          </small>
          <br />
          <small>{sessionItem?.clinicName}</small>
          <br />
          <small>
            {`${moment(sessionItem?.scheduleDate).format('dddd')}`},{' '}
            {`${moment(sessionItem?.scheduleDate).format('LL')}`} pukul{' '}
            {sessionItem?.scheduleTime} <br />
          </small>
          <br />
        </p>
      </EuiText>
    </EuiPanel>
  );

  const onChangeCloseReason = (optionId) => {
    setRadioIdSelected(optionId);
  };

  let form = (
    <EuiPanel>
      <EuiForm>
        <p>di-close karena</p>
        <br />
        <EuiRadioGroup
          options={[
            {
              id: 4,
              label: 'Ended',
            },
            {
              id: 3,
              label: 'Disabled',
            },
            {
              id: 7,
              label: 'Refund',
            },
          ]}
          idSelected={radioIdSelected}
          onChange={onChangeCloseReason}
          name="close option"
        />
        <EuiFormRow label="Alasan*">
          <div>
            <EuiTextArea
              placeholder="Masukan alasan..."
              aria-label="description"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            />
          </div>
        </EuiFormRow>
      </EuiForm>
    </EuiPanel>
  );

  let modal;
  if (isModalVisible) {
    modal = (
      <EuiOverlayMask>
        <EuiModal onClose={closeModal} initialFocus="[name=popswitch]">
          <EuiModalHeader>
            <EuiModalHeaderTitle>
              Close Temu-Janji Konsultasi
            </EuiModalHeaderTitle>
          </EuiModalHeader>

          <EuiModalBody>
            {oldSlotPanel}
            <EuiSpacer />
            <br />
            <br />
            {loading ? (
              <p>
                <EuiLoadingSpinner size="xl" />
              </p>
            ) : (
              form
            )}
          </EuiModalBody>

          {loading ? (
            ''
          ) : !success ? (
            <EuiModalFooter>
              <EuiButton onClick={handleSubmit} fill disabled={!reason}>
                Submit
              </EuiButton>
              <EuiButtonEmpty onClick={closeModal}>Batal</EuiButtonEmpty>
            </EuiModalFooter>
          ) : (
            <EuiModalFooter>
              <EuiButtonEmpty onClick={() => closeModal(true)}>
                Batal
              </EuiButtonEmpty>
            </EuiModalFooter>
          )}
        </EuiModal>
      </EuiOverlayMask>
    );
  }
  return (
    <>
      <EuiButtonEmpty
        disabled={sessionItem.status !== 1}
        className="btnList"
        size="xs"
        onClick={showModal}
      >
        Tutup Sesi
      </EuiButtonEmpty>
      {modal}
    </>
  );
};

const ChangeActivateEndedSession = (props) => {
  const { sessionItem, onActivate, closePopover } = props;
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);
  const [availableClinicOrganizations, setAvailableClinicOrganizations] =
    React.useState([]);
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [reason, setReason] = React.useState(``);

  const handleSubmit = () => {
    let payload = {};
    payload.Status = 1;
    payload.Description = reason;
    payload.Id = sessionItem.id;

    setLoading(true);
    sessionsService
      .updateSessionStatus(payload)
      .then(() => {
        setLoading(false);
        setSuccess(true);
        closeModal();
        onActivate();
        closePopover();
      })
      .catch((err) => {
        setLoading(false);
        errorService.handle(err);
      });
  };

  const closeModal = (resetSuccessState) => {
    if (resetSuccessState) {
      setSuccess(false);
    }
    setIsModalVisible(false);
    setReason('');
  };

  const showModal = () => setIsModalVisible(true);

  let oldSlotPanel = (
    <EuiPanel>
      <EuiText>
        <p>
          <b>{sessionItem?.patientName}</b>
          <br />
          <small>{sessionItem?.patientExternalId}</small>
          <br />
          <br />
          Jadwal Saat Ini
          <br />
          <small>
            <b>{sessionItem?.staffName}</b>
          </small>
          <br />
          <small>{sessionItem?.clinicName}</small>
          <br />
          <small>
            {`${moment(sessionItem?.scheduleDate).format('dddd')}`},{' '}
            {`${moment(sessionItem?.scheduleDate).format('LL')}`} pukul{' '}
            {sessionItem?.scheduleTime} <br />
          </small>
          <br />
        </p>
      </EuiText>
    </EuiPanel>
  );

  let form = (
    <EuiPanel>
      <EuiForm>
        <EuiFormRow label="Alasan sesi diaktifkan kembali*">
          <div>
            <EuiTextArea
              placeholder="Masukan alasan..."
              aria-label="description"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            />
          </div>
        </EuiFormRow>
      </EuiForm>
    </EuiPanel>
  );

  let modal;
  if (isModalVisible) {
    modal = (
      <EuiOverlayMask>
        <EuiModal onClose={closeModal} initialFocus="[name=popswitch]">
          <EuiModalHeader>
            <EuiModalHeaderTitle>
              Aktifkan Kembali Temu-janji Konsultasi
            </EuiModalHeaderTitle>
          </EuiModalHeader>

          <EuiModalBody>
            {oldSlotPanel}
            <EuiSpacer />
            <br />
            {loading ? (
              <p>
                <EuiLoadingSpinner size="xl" />
              </p>
            ) : (
              form
            )}
          </EuiModalBody>

          {loading ? (
            ''
          ) : !success ? (
            <EuiModalFooter>
              <EuiButton onClick={handleSubmit} fill disabled={!reason}>
                Submit
              </EuiButton>
              <EuiButtonEmpty onClick={closeModal}>Batal</EuiButtonEmpty>
            </EuiModalFooter>
          ) : (
            <EuiModalFooter>
              <EuiButtonEmpty onClick={() => closeModal(true)}>
                Batal
              </EuiButtonEmpty>
            </EuiModalFooter>
          )}
        </EuiModal>
      </EuiOverlayMask>
    );
  }
  return (
    <>
      <EuiButtonEmpty size="xs" className="btnList" onClick={showModal}>
        Aktifkan Kembali Sesi
      </EuiButtonEmpty>
      {modal}
    </>
  );
};

class Consultations extends Component {
  parsePlatform(e) {
    console.log(e);
    var platformText = "SIAPDOK"
    switch (e) {
      case '1':
        platformText = "ZOOM"
        break;
      case '2':
        platformText = "WHATSAPP"
        break;
      default:
        platformText = ''
    }
    return platformText;
  }
  state = {
    page: 1,
    limit: 10,
    order: 'asc',
    search: '',
    searchString: '',
    patientSearchString: '',
    searchBy: 'staffName',
    orderBy: 'date',
    data: [],
    specificDate: moment(),
    dateRange: 'all',
    selectedOrganizationId: '',
    organizationId: '',
    clinicOrganizationId: '',
    sessionId: '',
    showModal: false,
    loading: false,
    availableClinicOrganizations: [],
    selectedTabId: 'today',
    popOver: {},
    popOverSecond: {},
    popOverClaimDoc: {},
    showModalUploadClaim: false,
    fileUploadClaim: null,
    loadingUploadClaim: false,
    loadingUploadClaimOnDB: false,
    showDescription: false,
    prevDescription: '',
    description: '',
    editDescription: false,
    loadingDescription: false,
    sessionItem: {},
    secondary_key: '',
    resultState: 0,
    sessionInsurances: [
      {
        value: '',
        text: 'Semua Sesi',
      },
      {
        value: 'true',
        text: 'Sesi Berasuransi',
      },
    ],
    selectedSessionInsurance: '',
    tabs: [
      {
        id: 'today',
        name: 'Hari ini',
        disabled: false,
      },
      {
        id: 'new',
        name: 'New',
        disabled: false,
      },
      {
        id: 'active',
        name: 'Aktif',
        disabled: false,
      },
      {
        id: 'ended',
        name: 'Ended',
        disabled: false,
      },
      {
        id: 'refunded',
        name: 'Refund',
        disabled: false,
      },
      {
        id: 'disabled',
        name: 'Disabled',
        disabled: false,
      },
      {
        id: 'all',
        name: 'All',
        disabled: false,
      },
    ],
    dateRangeOptions: [
      {
        text: 'Semua',
        value: 'all',
      },
      {
        text: 'Tanggal',
        value: 'specificDate',
      },
    ],
    searchOptions: [
      {
        text: 'Dokter',
        value: 'staffName',
      },
      {
        text: 'Pasien',
        value: 'patientName',
      },
    ],
    column: [
      {
        field: 'date',
        name: 'Waktu',
        sortable: true,
        width: '10%',
        render: (_, item) => {
          let time = moment(item.scheduleTime, 'HH:mm:ss').format('HH:mm');
          return `${item.scheduleDate}, ${time} (${item.slotNumber})`;
        },
      },
      {
        field: 'staffName',
        name: 'Staff',
        sortable: true,
        width: '13%',
        render: (staffName) => (
          <div className="eui-textBreakWord eui-textLeft">{staffName}</div>
        ),
      },
      {
        field: 'clinicName',
        name: 'Klinik',
        sortable: true,
        width: '13%',
        render: (clinicName) => (
          <div className="eui-textBreakWord eui-textLeft">{clinicName}</div>
        ),
      },
      {
        field: 'patientName',
        name: 'Pasien',
        sortable: true,
        width: '13%',
        render: (patientName, data) => {
          return (
              <div className="eui-textBreakWord eui-textLeft">
                  {patientName} {data.isVirtualClinic ? <img src={data.virtualClinicConfig.logo} alt={data.virtualClinicConfig.name} style={{ width: '60px', marginLeft: '5px' }} /> : null}
              </div>
          );
        },
      },
      {
        field: 'id',
        name: 'Sesi',
        width: '8%',
        render: (sessionId) => (
          <SessionBadge sessionId={sessionId} sessionList={this.state.data} />
        ),
      },
      {
        field: 'id',
        name: 'Rekam Medis',
        width: '8%',
        render: (id) => <SoapBadge sessionId={id} />,
      },
      {
        field: 'id',
        name: 'Resep',
        width: '8%',
        render: (id) => <PrescriptionBadge sessionId={id} />,
      },
      {
        field: 'id',
        name: 'Uji Lab',
        width: '8%',
        render: (id) => <DiagnosticBadge sessionId={id} />,
      },
      {
        field: 'oldSchedule',
        name: 'Rescheduled',
        sortable: false,
        width: '8%',
        render: (oldSchedule) => (
          <EuiBadge
            color={this.statusColor(this.isRescheduled(oldSchedule))}
            style={{ color: '#fefefe', borderRadius: '10px' }}
          >
            {this.isRescheduled(oldSchedule) ? 'YA' : 'TIDAK'}
          </EuiBadge>
        ),
      },
      {
        field: 'description',
        name: 'Deskripsi',
        width: '10%',
        render: (description, sessionItem) => {
          return description ? (
            <EuiBadge
              style={{ textDecoration: 'none' }}
              iconType="documentEdit"
              iconSide="right"
              onClick={() => this.showDescription(description, sessionItem)}
            >
              {description}
            </EuiBadge>
          ) : (
            <EuiBadge
              style={{ textDecoration: 'none' }}
              iconType="plusInCircleFilled"
              iconSide="right"
              onClick={() => this.showDescription(description, sessionItem)}
            >
              Tambah
            </EuiBadge>
          );
        },
      },
      {
        field: 'finishedPlatform',
        name: 'Tuntas Via',
        sortable: true,
        width: '8%',
        render: (platform) => (
          <div className="eui-textBreakWord eui-textLeft">{this.parsePlatform(platform)}</div>)
      },
      {
        field: 'id',
        name: 'Link',
        width: '5%',
        render: (id, sessionItem) => (
          <>
            <EuiPopover
              ownFocus={false}
              button={
                <EuiButtonIcon
                  onClick={() => this.onPopOverClick(id)}
                  iconType="boxesHorizontal"
                  aria-label="option"
                />
              }
              isOpen={this.state.popOver[id]?.isOpen}
              closePopover={() => this.onPopOverClick(id)}
              panelPaddingSize="none"
              anchorPosition="upCenter"
            >
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <EuiButtonEmpty
                  size="xs"
                  className="btnList"
                  disabled={!sessionItem.paymentURL}
                  onClick={() => window.open(sessionItem.paymentURL, '_blank')}
                >
                  Link Pembayaran Sesi Telekonsul
                </EuiButtonEmpty>
                <EuiButtonEmpty
                  size="xs"
                  className="btnList"
                  disabled={!sessionItem.prescriptionPaymentURL}
                  onClick={() => window.open(sessionItem.prescriptionPaymentURL, '_blank')}
                >
                  Link Pembayaran Resep Obat
                </EuiButtonEmpty>
                <EuiButtonEmpty
                  size="xs"
                  className="btnList"
                  disabled={!sessionItem.weblinks.doctor}
                  onClick={() =>
                    this.copyLink('DOCTOR', sessionItem.weblinks.doctor)
                  }
                >
                  Link Dokter
                </EuiButtonEmpty>
                <EuiButtonEmpty
                  size="xs"
                  className="btnList"
                  disabled={!sessionItem.weblinks.patient}
                  onClick={() =>
                    this.copyLink('PATIENT', sessionItem.weblinks.patient)
                  }
                >
                  Link Pasien
                </EuiButtonEmpty>
                <EuiButtonEmpty
                  size="xs"
                  className="btnList"
                  disabled={!sessionItem.weblinks.assistant}
                  onClick={() =>
                    this.copyLink('ASSISTANT', sessionItem.weblinks.assistant)
                  }
                >
                  Link Assistant
                </EuiButtonEmpty>
                <EuiButtonEmpty
                  size="xs"
                  className="btnList"
                  onClick={() =>
                    window.open(
                      'https://wa.me/' + sessionItem.patientPhoneNumber,
                      '_blank'
                    )
                  }
                >
                  Kontak WhatsApp
                </EuiButtonEmpty>
                <EuiButtonEmpty
                  size="xs"
                  className="btnList"
                  disabled={sessionItem.status < 1}
                  onClick={() =>
                    this.openReceiptBlob(id, sessionItem.current_language)
                  }
                >
                  Unduh Kwitansi
                </EuiButtonEmpty>
                <EuiButtonEmpty
                  size="xs"
                  className="btnList"
                  isLoading={this.state.downloadingPrescriptionCopy[id]}
                  onClick={() => this.downloadPrescriptionCopy(id, 'id')}
                >
                  Unduh Resep
                </EuiButtonEmpty>
                <EuiButtonEmpty
                  size="xs"
                  className="btnList"
                  isLoading={this.state.downloadingPrescriptionCopyEn[id]}
                  onClick={() => this.downloadPrescriptionCopy(id, 'en')}
                >
                  Unduh Resep (EN)
                </EuiButtonEmpty>
              </div>
            </EuiPopover>
          </>
        ),
        sortable: false,
      },
      {
        field: 'id',
        name: 'Menu',
        width: '5%',
        render: (id, sessionItem) => (
          <>
            <EuiPopover
              ownFocus={false}
              button={
                <EuiButtonIcon
                  onClick={() => this.onPopOverClickSecond(id)}
                  iconType="boxesHorizontal"
                  aria-label="option"
                />
              }
              isOpen={this.state.popOverSecond[id]?.isOpen}
              closePopover={() => this.onPopOverClickSecond(id)}
              anchorPosition="upCenter"
              panelPaddingSize="none"
            >
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <EuiCollapsibleNavGroup
                  title="Tuntas Via"
                  style={{ padding: '0', margin: '0', fontSize: '5px' }}
                  isCollapsible={true}
                  className="btnList"
                  titleSize="5"
                  initialIsOpen={false}
                >
                  <EuiPinnableListGroup
                    listItems={[
                      {
                        label: 'SiapDok',
                        iconType:
                          sessionItem.finishedPlatform === '0' ? 'check' : '',
                        onClick: () =>
                          this.updateSessionPlatformFinished(
                            id,
                            0,
                            sessionItem
                          ),
                      },
                      {
                        label: 'ZOOM',
                        iconType:
                          sessionItem.finishedPlatform === '1' ? 'check' : '',
                        onClick: () =>
                          this.updateSessionPlatformFinished(
                            id,
                            1,
                            sessionItem
                          ),
                      },
                      {
                        label: 'WhatsApp',
                        iconType:
                          sessionItem.finishedPlatform === '2' ? 'check' : '',
                        onClick: () =>
                          this.updateSessionPlatformFinished(
                            id,
                            2,
                            sessionItem
                          ),
                      },
                    ]}
                    maxWidth="none"
                    color="subdued"
                    gutterSize="none"
                    size="s"
                  />
                </EuiCollapsibleNavGroup>
                <ChangeScheduleModal
                  sessionItem={sessionItem}
                  onScheduleChange={() => this.page(this.state.page)}
                />
                {sessionItem.status > 1 && (
                  <ChangeActivateEndedSession
                    sessionItem={sessionItem}
                    onActivate={() => this.page(1)}
                    closePopover={() => this.onPopOverClickSecond(id)}
                  />
                )}
                {sessionItem.status === 1 && (
                  <ChangeCloseModal
                    sessionItem={sessionItem}
                    onScheduleChange={() => this.page(1)}
                  />
                )}
                {sessionItem.status == 0 && (
                  <EuiButtonEmpty
                    size="xs"
                    className="btnList"
                    onClick={() => this.checkPayment(id)}
                  >
                    Cek Pembayaran
                  </EuiButtonEmpty>
                )}
                {sessionItem.status == 1 && (
                  <EuiButtonEmpty
                    size="xs"
                    className="btnList"
                    onClick={() => this.rerunEventPaymentReceived(id)}
                  >
                    Tambahkan ulang SESSION_PAYMENT_RECEIVED
                  </EuiButtonEmpty>
                )}
                <EuiButtonEmpty
                  size="xs"
                  className="btnList"
                  onClick={() =>
                    this.props.history.push({
                      pathname: '/pharmacies/kimia-farma',
                      state: { searchBy: 'session_id', searchString: id },
                    })
                  }
                >
                  Lihat Resep
                </EuiButtonEmpty>
                <AddPrescriptionsModal item={sessionItem} />
                <AddDiagnosticsLabModal item={sessionItem} />
                {sessionItem.status > 1 && (
                  <EuiButtonEmpty
                    size="xs"
                    className="btnList"
                    onClick={() => this.resetStatus(sessionItem)}
                  >
                    Reset Status
                  </EuiButtonEmpty>
                )}
                {!moment(sessionItem.scheduleDate).isBefore(
                  moment().format('YYYY-MM-DD')
                ) &&
                  Config.weblink !== '' &&
                  sessionItem.status === 1 ? (
                  <EuiButtonEmpty
                    size="xs"
                    className="btnList"
                    onClick={() => window.open('/weblink-list/' + id, '_blank')}
                  >
                    SiapDok link
                  </EuiButtonEmpty>
                ) : null}
                <EuiButtonEmpty
                  size="xs"
                  className="btnList"
                  onClick={() =>
                    this.props.history.push({
                      pathname: '/events',
                      state: { secondary_key: id },
                    })
                  }
                >
                  Log Events
                </EuiButtonEmpty>
                <EuiButtonEmpty
                  size="xs"
                  className="btnList"
                  onClick={() => {
                    var endDate;
                    var startDate = sessionItem.createdTime;
                    if (sessionItem.oldSchedule) {
                      let oldSchedule = moment(sessionItem.oldSchedule.split('T')[0]);
                      let scheduleDate = moment(sessionItem.scheduleDate)
                      if (oldSchedule.isBefore(scheduleDate)) {
                        endDate = scheduleDate.format('YYYY-MM-DD');
                      } else {
                        endDate = oldSchedule.format('YYYY-MM-DD');
                      }
                    } else {
                      endDate = sessionItem.scheduleDate;
                    }

                    this.props.history.push({
                      pathname: '/thirdparty-logs',
                      state: {
                        key: 'payload',
                        keyword: id,
                        startDate: startDate,
                        endDate: endDate,
                      },
                    })}
                  }
                >
                  Log Bridging
                </EuiButtonEmpty>
                {sessionItem.orderId && sessionItem.orderId.length > 0 &&
                  <EuiButtonEmpty
                    size="xs"
                    className="btnList"
                    disabled={sessionItem.status === 1}
                    onClick={() => this.completePayment(sessionItem.orderId)}
                  >
                    Tandai Sesi Telah Dibayar
                  </EuiButtonEmpty>
                }
              </div>
            </EuiPopover>
          </>
        ),
        sortable: false,
      },
    ],
    loadingData: true,
    reports: [],
    reportSummaries: {
      finishedViaSiapdok: 0,
      finishedViaAlternatives: 0,
      finishedPercentage: 0,
    },
    showReports: false,
    loadingReport: false,
    downloadingPrescriptionCopy: {},
    downloadingPrescriptionCopyEn: {},
  };

  insuranceText = (session) => {
    let s = session.insuranceVendor || '-';
    if (s != '-' && session.insuranceStatus) {
      switch (session.insuranceStatus) {
        case 1:
          s += ' (NOT ELIGIBLE)';
          break;
        case 2:
          s += ' (EXCESS)';
          break;
        case 3:
          s += ' (ELIGIBLE)';
          break;
        case 4:
          s += ' (ADMISSIONED)';
          break;
        case 5:
          s += ' (DISCHARGED)';
          break;
        case 6:
          s += ' (CLAIM SENT)';
          break;
        default:
          s += ' (UNKNOWN)';
          break;
      }
    }
    return s;
  };

  resetStatus = (data) => {
    let payload = {};
    if (data.paymentURL === '' || data.paymentURL === undefined || data.paymentURL === 'free') {
      payload.Status = 0;
    } else {
      payload.Status = 1;
    }
    payload.Description = `reset manual`;
    payload.Id = data.id;
    if (!window.confirm('Apakah Anda yakin ingin mereset status item ini?')) {
      return;
    }

    sessionsService
      .updateSessionStatus(payload)
      .then((result) => {
        this.page(this.state.page);
        this.onPopOverClick(data.id);
      })
      .catch((err) => {
        console.log(err);
        swal({
          title: '',
          icon: 'error',
          type: 'error',
          text: `Mohon maaf, status tidak bisa direset`,
        });
      });
  };

  copyLink = (role, id) => {
    if (id != '') {
      let link = `${Config.weblink}/${weblinkService.makeUserCode(role)}-${id}`;
      navigator.clipboard.writeText(link).then(() =>
        swal({
          title: 'Link berhasil disalin',
          icon: 'success',
          type: 'success',
        })
      );
    } else
      swal({
        title: '',
        icon: 'error',
        type: 'error',
        text: `Mohon maaf, tidak ada link ${role}`,
      });
  };

  showDescription = (description, sessionItem) => {
    this.setState({
      showDescription: true,
      prevDescription: description,
      description,
      sessionItem,
    });
  };

  onPopOverClick = (id) => {
    let state = this.state;
    if (!state.popOver[id]) {
      state.popOver[id] = { isOpen: true };
    } else {
      state.popOver[id] = { isOpen: !state.popOver[id].isOpen };
    }
    this.setState(state);
  };

  onPopOverClickSecond = (id) => {
    let state = this.state;
    if (!state.popOverSecond[id]) {
      state.popOverSecond[id] = { isOpen: true };
    } else {
      state.popOverSecond[id] = { isOpen: !state.popOverSecond[id].isOpen };
    }
    this.setState(state);
  };

  onPopOverClaimDocClick = (id) => {
    let state = this.state;
    if (!state.popOverClaimDoc[id]) {
      state.popOverClaimDoc[id] = { isOpen: true };
    } else {
      state.popOverClaimDoc[id] = { isOpen: !state.popOverClaimDoc[id].isOpen };
    }
    this.setState(state);
  };

  closePopOver = (id) => {
    let state = this.state;
    state.popOver[id] = { isOpen: false };
    this.setState(state);
  };

  componentDidMount = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const status = queryParams.get('status');
    if (status) {
      this.state.selectedTabId = status;
    }
    this.page(1);
    this.loadOrganizations();
  };

  statusRender = (status) => {
    switch (status) {
      case 0:
        return 'NEW';
      case 1:
        return 'ACTIVE';
      case 2:
        return 'REJECTED';
      case 3:
        return 'DISABLED';
      case 4:
        return 'ENDED';
      case 5:
        return 'CANCELED';
      case 7:
        return 'REFUND';
      default:
        return 'UNKNOWN';
    }
  };

  statusColor = (status) => {
    switch (status) {
      case 0:
      case false:
        return '#ffc03f';
      case 1:
      case true:
        return '#3f9f30';
      case 2:
        return '#d3dbe6';
      default:
        return '#f6413a';
    }
  };

  loadOrganizations = () => {
    let payload = {
      limit: 1000,
    };
    organizationService
      .getOrganizations(payload)
      .then((result) => {
        let options = [{ value: '', text: 'Pilih organisasi' }];
        for (let i in result.items) {
          options.push({
            value: result.items[i].id,
            text: result.items[i].name,
          });
        }
        this.setState({
          organizations: options,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          organizations: [],
        });
      });
  };

  closeModal = () => {
    this.setState({
      showModal: false,
      logsData: [],
    });
  };

  openReceiptBlob = (id, lang) => {
    this.setState({ loading: true });
    var langCode = '';
    if (lang === 1) {
      langCode = 'en';
    } else {
      langCode = 'id';
    }
    sessionsService
      .getSessionReceipt(id, langCode)
      .then((value) => {
        var binaryData = [];
        binaryData.push(value);
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(
          new Blob(binaryData, { type: 'application/pdf' })
        );
        link.download = `payment-receipt-${id}`;
        link.click();

        this.setState({
          loading: false,
        });
      })
      .catch((err) => {
        console.log(err);
        this.setState({
          loading: false,
        });
      });
  };

  downloadClaimDoc = (id) => {
    this.setState({ downloadingClaimDoc: true }, () => {
      blobService
        .getBlob(id)
        .then((result) => {
          downloadjs(
            `data:${result.contentType};base64,${result.base64}`,
            `document_claim_${id}.pdf`,
            `${result.contentType}`
          );
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => this.setState({ downloadingClaimDoc: false }));
    });
  };

  downloadPrescriptionCopy = (id, lang) => {
    // set loading to specific id
    let key = 'downloadingPrescriptionCopy';
    if (lang === 'en') {
      key = 'downloadingPrescriptionCopyEn';
    }

    this.setState(
      (prevState) => ({
        [key]: {
          ...prevState[key],
          [id]: true,
        },
      }),
      () => {
        sessionsService
          .getPrescriptionCopy(id, lang)
          .then((result) => {
            let fileName = `prescription_${id}.pdf`;
            if (lang === 'en') {
              fileName = `prescription_${id}_en.pdf`;
            }
            downloadjs(result, fileName, `application/pdf`);
          })
          .catch((err) => {
            console.log(err);
            if (err.response?.status === 404) {
              swal({
                title: '',
                icon: 'error',
                type: 'error',
                text: `Mohon maaf, sesi ini belum atau tidak memiliki resep.`,
              });
            } else {
              errorService.handle(err);
            }
          })
          .finally(() => {
            this.onPopOverClick(id);
            this.setState((prevState) => ({
              [key]: {
                ...prevState[key],
                [id]: false,
              },
            }));
          });
      }
    );
  };

  isSoapComplete = (sessionId) => {
    if (this.state.soaps) {
      return this.state.soaps.includes(sessionId);
    } else {
      return false;
    }
  };

  isPrescription = (sessionId) => {
    if (this.state.prescriptions.includes(`${sessionId}`)) {
      return true;
    }
    return false;
  };

  isRescheduled = (oldSchedule) => {
    let time = moment(oldSchedule);
    return time.isValid() && time.year() !== 1;
  };

  loadReport = () => {
    let payload = {
      page: 1,
      limit: 10000000,
      order: this.state.order,
      orderBy: this.state.orderBy,
    };

    if (this.state.selectedTabId === 'today') {
      payload.date = moment().format('YYYY-MM-DD');
    } else if (this.state.selectedTabId !== 'all') {
      payload.filterBy = this.state.selectedTabId;
    }

    if (
      this.state.dateRange === 'specificDate' &&
      this.state.specificDate &&
      typeof this.state.specificDate === 'object'
    ) {
      payload.date = this.state.specificDate.format('YYYY-MM-DD');
    }
    if (
      this.state.searchBy === 'patientName' &&
      this.state.patientSearchString &&
      this.state.patientSearchString.length > 0
    ) {
      payload.search = this.state.patientSearchString;
      payload.searchBy = this.state.searchBy;
    }
    if (
      this.state.searchBy === 'staffName' &&
      this.state.searchString &&
      this.state.searchString.length > 0
    ) {
      payload.search = this.state.searchString;
      payload.searchBy = this.state.searchBy;
    }
    if (this.state.organizationId && this.state.organizationId.length > 0) {
      payload.organizationId = this.state.organizationId;
    }

    this.setState(
      { showReports: true, loadingReport: true, reports: [] },
      () => {
        sessionsService
          .getSessions(payload)
          .then((result) => {
            let summaries = {
              finishedViaSiapdok: 0,
              finishedViaAlternatives: 0,
              finishedPercentage: 0,
              refund: 0,
            };
            for (let i in result.Items) {
              let v = result.Items[i];
              if (v.finishedPlatform > 0) {
                summaries.finishedViaAlternatives += 1;
              } else {
                summaries.finishedViaSiapdok += 1;
              }
              if (v.status == 7) {
                summaries.refund += 1;
              }
            }
            summaries.finishedPercentage =
              (summaries.finishedViaSiapdok / result.Items.length) * 100;
            let obj = {
              reports: result.Items,
              reportSummaries: summaries,
              loadingReport: false,
            };
            this.setState(obj);
          })
          .catch((err) => {
            console.log(err);
            let obj = {
              loadingReport: false,
            };
            this.setState(obj);
          });
      }
    );
  };

  page = (page) => {
    page = page || 1;
    let payload = {
      page: page,
      limit: this.state.limit,
      search: this.state.search,
      order: this.state.order,
      orderBy: this.state.orderBy,
      hasInsuranceVendor: this.state.selectedSessionInsurance,
    };

    if (this.state.selectedTabId === 'today') {
      payload.date = moment().format('YYYY-MM-DD');
    } else if (this.state.selectedTabId !== 'all') {
      payload.filterBy = this.state.selectedTabId;
    }

    if (
      this.state.dateRange === 'specificDate' &&
      this.state.specificDate &&
      typeof this.state.specificDate === 'object'
    ) {
      payload.date = this.state.specificDate.format('YYYY-MM-DD');
    }
    if (
      this.state.searchBy === 'patientName' &&
      this.state.patientSearchString &&
      this.state.patientSearchString.length > 0
    ) {
      payload.search = this.state.patientSearchString;
      payload.searchBy = this.state.searchBy;
    }
    if (
      this.state.searchBy === 'staffName' &&
      this.state.searchString &&
      this.state.searchString.length > 0
    ) {
      payload.search = this.state.searchString;
      payload.searchBy = this.state.searchBy;
    }
    if (this.state.organizationId && this.state.organizationId.length > 0) {
      payload.organizationId = this.state.organizationId;
    }

    let virtualClinicJson;
    settingService.getAppSettings('virtual-clinic-config').then((res) => {
      virtualClinicJson = JSON.parse(res.data);
    })

    this.setState({ loadingData: true }, () => {
      sessionsService
        .getSessions(payload)
        .then((result) => {
          var startFrom = (parseInt(result.Page) - 1) * parseInt(result.Limit);
         for (var a in result.Items) {
            var item = result.Items[a];
            item.sequence = parseInt(a) + startFrom + 1;
            item.weblinks = {
                doctor: '',
                patient: '',
                assistant: '',
            };
             item.resultState = 0;
             if (item.tags !== null) {
               for (var b = 0; b < item.tags.length; b++) {
                   if (item.tags[b].startsWith('products:virtual-clinic')) {
                       let tagParts = item.tags[b].split('-');
                       item.isVirtualClinic = true;
                       item.virtualClinicConfig = virtualClinicJson[tagParts[tagParts.length - 1]];
                       break;
                   }
               }
             }
         }
          let obj = {
            page: result.Page,
            limit: result.Limit,
            total: result.Total,
            data: result.Items,
            loadingData: false,
          };

          obj[this.state.currentSlotId] = false;
          console.log(result.Items);
          this.setState(obj);
          this.forceUpdate();
          for (var i in result.Items) {
            this.webLinkBySessionID(result.Items[i].id, i);
          }
        })
        .catch((err) => {
          console.log(err);
          let obj = {
            loadingData: false,
            data: [],
            loading: false,
          };
          obj[this.state.currentSlotId] = false;
          this.setState(obj);
        });
    });
  };

  statusToTab = (status) => {
    switch (status) {
      case 0:
        return 'new';
      case 1:
        return 'active';
      case 3:
        return 'disabled';
      case 4:
        return 'ended';
      case 7:
        return 'refund';
      default:
        return 'all';
    }
  };

  updateSessionPlatformFinished = async (id, platform, session) => {
    let payload = {
      finished_platform: platform,
    };

    try {
      let resp = await sessionsService.updateSessionFinishedPlatform(
        id,
        payload
      );
      switch (platform) {
        case 0:
          platform = 'SiapDok';
          break;
        case 1:
          platform = 'ZOOM';
          break;
        case 2:
          platform = 'WhatsApp';
          break;
        default:
          break;
      }
      if (resp != null) {
        swal({
          title: `Berhasil Tuntas Via ${platform}`,
          icon: 'success',
          type: 'success',
        }).then(() => {
          this.onSelectedTabChanged(
            this.statusToTab(session.status),
            session.staffName
          );
        });
      }
    } catch (err) {
      errorService.handle(err);
    }
  };

  webLinkBySessionID = async (sessionId, index) => {
    const payload = { Id: sessionId };
    try {
      let _weblink = await weblinkService.listWeblink(payload);
      const weblinkLen = Object.keys(_weblink).length;
      if (weblinkLen == 0) {
        Object.assign(payload, { session_id: sessionId, role: 'ALL' });
        let _createWeblink = await weblinkService.createWeblink(payload);
        if (_createWeblink.message.includes(',')) {
          _weblink = await weblinkService.listWeblink(payload);
        }
      }

      _weblink.items.forEach((element) => {
        if (element.role == 'DOCTOR') {
          this.state.data[index].weblinks.doctor = element.id;
        }
        if (element.role == 'PATIENT') {
          this.state.data[index].weblinks.patient = element.id;
        }
        if (element.role == 'ASSISTANT') {
          this.state.data[index].weblinks.assistant = element.id;
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  nextPage = () => {
    this.page(this.state.page + 1);
  };

  prevPage = () => {
    this.page(this.state.page - 1);
  };

  handleDateChange = (date) => {
    // in moment object format
    this.setState({ specificDate: date }, () => {
      this.page(1);
    });
  };

  handleChange = (e) => {
    let value = e.target.value || null;
    let obj = {};
    obj[e.target.name] = value;
    obj['failedAttempt'] = false;
    let validationFields = { ...this.state.validationFields };
    if (validationFields[e.target.name]) {
      validationFields[e.target.name].isInvalid = false;
      obj.validationFields = validationFields;
    }
    this.setState(obj);
  };

  onOrganizationChange = (e) => {
    let value = e.target.value || null;
    this.setState({ loadingData: true, organizationId: value }, () => {
      this.page(1);
    });
  };

  onSessionInsuranceChange = (e) => {
    let value = e.target.value || null;
    this.setState(
      { loadingData: true, selectedSessionInsurance: value },
      () => {
        this.page(1);
      }
    );
  };

  onDateRangeChange = (e) => {
    let value = e.target.value || null;
    this.setState({ dateRange: value, specificDate: moment() }, () => {
      this.page(1);
    });
  };

  onSearchByChange = (e) => {
    let value = e.target.value || null;
    this.setState({ searchBy: value });
  };

  searchStringChange = debounce((value) => {
    this.setState({ searchString: value }, () => {
      this.page(1);
    });
  }, 1000);

  onSearchStringChange = (e) => {
    let value = e.target.value || null;
    if (!value || (value && value.length < 1)) {
      this.setState({ searchString: '' });
    }
    this.setState({ searchString: value });
    this.searchStringChange(value);
  };

  patientSearchStringChange = debounce((value) => {
    this.setState({ patientSearchString: value }, () => {
      this.page(1);
    });
  }, 1000);

  onPatientSearchStringChange = (e) => {
    let value = e.target.value || null;
    if (!value || (value && value.length < 1)) {
      this.setState({ patientSearchString: '' });
    }
    this.setState({ patientSearchString: value });
    this.patientSearchStringChange(value);
  };

  checkPayment = (id) => {
    if (id && id.length !== 36) {
      swal({
        title: '',
        icon: 'error',
        type: 'error',
        text: 'Mohon maaf, id invalid',
      });
    }
    paymentService
      .checkPayment(id)
      .then((result) => {
        let text = '';
        if (result.status === 'paid') {
          text = 'Pembayaran telah lunas';
        } else {
          text = 'Pembayaran belum lunas';
        }
        swal({
          title: 'Cek pembayaran telah berhasil',
          icon: 'success',
          type: 'success',
          text: text,
        }).then((value) => {
          this.page(this.state.page);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  rerunEventPaymentReceived = async (id) => {
    if (id && id.length !== 36) {
      swal({
        title: '',
        icon: 'error',
        type: 'error',
        text: 'Mohon maaf, id invalid',
      });
    }
    try {
      let resultEvent = await sessionsService.rerunPaymentReceivedEvents({
        id: id,
      });

      if (resultEvent.message == 'success') {
        swal({
          title: '',
          icon: 'success',
          type: 'success',
          text: 'Event berhasil dieksekusi',
        });
      } else {
        swal({
          title: '',
          icon: 'warning',
          type: 'warning',
          text: 'Event sudah tersedia sebelumnya',
        });
      }
    } catch (e) {
      console.log(e);
      swal({
        title: '',
        icon: 'error',
        type: 'error',
        text: `Mohon maaf, terjadi kesalahan dengan galat \n\n ${e}`,
      });
    }
  };

  completePayment = (id) => {
    if (id && id.length !== 36) {
      swal({
        title: '',
        icon: 'error',
        type: 'error',
        text: 'Mohon maaf, id order invalid',
      });
    }
    paymentService
      .completePaymentByOrderId(id)
      .then((result) => {
        swal({
          title: 'Sukses',
          icon: 'success',
          type: 'success',
          text: 'Sesi telah dibayar',
        }).then((value) => {
          this.page(this.state.page);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  renderFilterComponent = () => {
    return (
      <div>
        <div
          style={{ display: 'inline-block', marginLeft: 15, marginRight: 15 }}
        >
          <EuiFlexGroup>
            <EuiFlexItem grow={false}>
              <EuiSelect
                placeholder="Organisasi"
                options={this.state.organizations}
                value={this.state.organizationId}
                name="organizationName"
                onChange={this.onOrganizationChange}
                aria-label="Organisasi"
              />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiSelect
                options={this.state.sessionInsurances}
                value={this.state.selectedSessionInsurance}
                name="sessionInsurance"
                onChange={this.onSessionInsuranceChange}
                aria-label="Asuransi"
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        </div>
        {this.state.selectedTabId !== 'today' && (
          <div style={{ display: 'inline-block' }}>
            <EuiFlexGroup>
              <EuiFlexItem grow={false} style={{ marginRight: '-10px' }}>
                <EuiSelect
                  style={{ display: 'inline-block !important' }}
                  placeholder="Cari berdasarkan"
                  options={this.state.dateRangeOptions}
                  value={this.state.dateRange}
                  name="dateRange"
                  onChange={this.onDateRangeChange}
                  aria-label="Cari berdasarkan"
                />
              </EuiFlexItem>
              {this.state.dateRange === 'specificDate' && (
                <EuiFlexItem grow={false}>
                  <EuiDatePicker
                    selected={this.state.specificDate}
                    onChange={this.handleDateChange}
                  />
                </EuiFlexItem>
              )}
            </EuiFlexGroup>
          </div>
        )}
        <div style={{ display: 'inline-block', marginLeft: 30 }}>
          <EuiFlexGroup>
            <EuiFlexItem grow={false} style={{ marginRight: '-10px' }}>
              <EuiSelect
                style={{ display: 'inline-block !important' }}
                placeholder="Cari berdasarkan"
                options={this.state.searchOptions}
                value={this.state.searchBy}
                name="searchBy"
                onChange={this.onSearchByChange}
                aria-label="Cari berdasarkan"
              />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              {this.state.searchBy === 'staffName' && (
                <EuiFieldText
                  style={{ display: 'inline-block !important' }}
                  placeholder="Cari dokter..."
                  name="searchString"
                  onChange={this.onSearchStringChange}
                  value={this.state.searchString}
                />
              )}
              {this.state.searchBy === 'patientName' && (
                <EuiFieldText
                  style={{ display: 'inline-block !important' }}
                  placeholder="Cari..."
                  name="searchString"
                  onChange={this.onPatientSearchStringChange}
                  value={this.state.patientSearchString}
                />
              )}
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiButton
                color="primary"
                onClick={() => {
                  this.loadReport();
                }}
                iconType="lensApp"
              >
                Laporan Teks
              </EuiButton>
            </EuiFlexItem>
          </EuiFlexGroup>
        </div>
      </div>
    );
  };

  onSelectedTabChanged = (id, searchString = '') => {
    this.setState(
      {
        loadingData: true,
        selectedTabId: id,
        searchDate: '',
        searchString: searchString,
        specificDate: moment(),
      },
      () => {
        this.page(1);
        this.loadOrganizations();
      }
    );
  };

  onTableChange = ({ page = {}, sort = {} }) => {
    const { field: sortField, direction: sortDirection } = sort;
    this.setState(
      { order: sortDirection, orderBy: sortField, loadingData: true },
      () => {
        this.page(this.state.page);
      }
    );
  };

  renderTabs = () => {
    return this.state.tabs.map((tab, index) => (
      <EuiTab
        {...(tab.href && { href: tab.href, target: '_blank' })}
        onClick={() => this.onSelectedTabChanged(tab.id)}
        isSelected={tab.id === this.state.selectedTabId}
        disabled={tab.disabled}
        key={index}
      >
        {tab.name}
      </EuiTab>
    ));
  };

  render() {
    const sorting = {
      sort: {
        field: this.state.orderBy,
        direction: this.state.order,
      },
    };

    let modalDescription;
    let modalReports;
    let modalUploadClaim;

    if (this.state.showDescription) {
      modalDescription = (
        <EuiOverlayMask>
          <EuiModal
            onClose={() => this.setState({ showDescription: false })}
            initialFocus="[name=description]"
          >
            <EuiModalHeader>
              <EuiModalHeaderTitle>
                Deskripsi Temu-janji Konsultasi
              </EuiModalHeaderTitle>
            </EuiModalHeader>

            <EuiModalBody>
              <EuiPanel>
                <EuiText>
                  <p>
                    <b>{this.state.sessionItem?.patientName}</b>
                    <br />
                    <small>
                      {this.state.sessionItem?.patientExternalId || '-'}
                    </small>
                    <br />
                    <br />
                    <small>
                      <b>{this.state.sessionItem?.staffName}</b>
                    </small>
                    <br />
                    <small>{this.state.sessionItem?.clinicName}</small>
                    <br />
                    <small>
                      {`${moment(this.state.sessionItem?.scheduleDate).format(
                        'dddd'
                      )}`}
                      ,{' '}
                      {`${moment(this.state.sessionItem?.scheduleDate).format(
                        'LL'
                      )}`}{' '}
                      pukul {this.state.sessionItem?.scheduleTime} <br />
                    </small>
                    <br />
                    <small>
                      Rescheduled:{' '}
                      <b>
                        {this.isRescheduled(this.state.sessionItem?.oldSchedule)
                          ? 'Ya'
                          : 'Tidak'}
                      </b>
                    </small>
                    <br />
                    {this.isRescheduled(
                      this.state.sessionItem?.oldSchedule
                    ) && (
                        <small>
                          Jadwal lama:{' '}
                          {moment(this.state.sessionItem?.oldSchedule).format(
                            'dddd, LL [pukul] HH:mm:ss'
                          )}
                        </small>
                      )}
                  </p>
                </EuiText>
              </EuiPanel>
              <EuiFormRow label="Deskripsi">
                <div style={{ position: 'relative' }}>
                  {!this.state.editDescription && (
                    <EuiButtonIcon
                      style={{ position: 'absolute', top: 0, right: 0 }}
                      onClick={() => this.setState({ editDescription: true })}
                      iconType="indexEdit"
                      aria-label="option"
                      color="secondary"
                      size="m"
                    />
                  )}
                  <EuiTextArea
                    placeholder={
                      this.state.editDescription ? 'Masukan deskripsi...' : ''
                    }
                    aria-label="description"
                    value={this.state.description}
                    resize={this.state.editDescription ? 'both' : 'none'}
                    onChange={(e) =>
                      this.setState({ description: e.target.value })
                    }
                    readOnly={!this.state.editDescription}
                  />
                </div>
              </EuiFormRow>
            </EuiModalBody>
            {this.state.editDescription && (
              <EuiModalFooter>
                <EuiButton
                  isLoading={this.state.loadingDescription}
                  onClick={() => {
                    let payload = {};
                    payload.Status = this.state.sessionItem.status;
                    payload.Description = this.state.description;
                    payload.Id = this.state.sessionItem.id;

                    this.setState({ loadingDescription: true }, () => {
                      sessionsService
                        .updateSessionDescription(payload)
                        .then(() => {
                          swal({
                            title:
                              'Berhasil Sunting Deskripsi Temu-Janji Konsultasi',
                            icon: 'success',
                            type: 'success',
                          }).then((value) => {
                            this.setState(
                              {
                                loadingDescription: false,
                                editDescription: false,
                                showDescription: false,
                                loadingData: true,
                              },
                              () => this.page(this.state.page)
                            );
                          });
                        })
                        .catch((err) => {
                          console.log(err);
                          this.setState({ loadingDescription: false });
                        });
                    });
                  }}
                  fill
                  disabled={!this.state.description}
                >
                  Submit
                </EuiButton>
                <EuiButtonEmpty
                  onClick={() =>
                    this.setState({
                      editDescription: false,
                      description: this.state.prevDescription,
                    })
                  }
                >
                  Batal
                </EuiButtonEmpty>
              </EuiModalFooter>
            )}
          </EuiModal>
        </EuiOverlayMask>
      );
    }
    if (this.state.showReports) {
      modalReports = (
        <EuiOverlayMask>
          <EuiModal
            onClose={() => this.setState({ showReports: false })}
            initialFocus="[name=reports]"
          >
            <EuiModalHeader>
              <EuiModalHeaderTitle>
                Laporan Temu-janji Konsultasi
              </EuiModalHeaderTitle>
              <div style={{ float: 'right' }}>
                <br />
                Jumlah konsultasi: {this.state.reports.length}
                <br />
                Refund:{' '}
                {this.state.reportSummaries.refund}
                <br />
                Jumlah konsultasi tuntas:{' '}
                {this.state.reports.length - this.state.reportSummaries.refund}
                <br />
                Tuntas via SiapDok:{' '}
                {this.state.reportSummaries.finishedViaSiapdok}
                <br />
                Tuntas via alternatif:{' '}
                {this.state.reportSummaries.finishedViaAlternatives}
                <br />
                Skor: {this.state.reportSummaries.finishedPercentage}%
              </div>
            </EuiModalHeader>

            <EuiModalBody>
              {this.state.loadingReport ? (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <EuiLoadingSpinner size="xl" />
                </div>
              ) : (
                <EuiPanel>
                  {(this.state.reports || []).length < 1 ? (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      Tidak ada data
                    </div>
                  ) : (
                    <div>
                      {this.state.reports.map((v, i) => {
                        let scheduleDate = moment(v.scheduleDate, 'YYYY-MM-DD');

                        return (
                          <div>
                            <EuiText key={i}>
                              {i + 1}. {scheduleDate.format('dddd')}{' '}
                              {v.scheduleDate} {v.scheduleTime} {v.clinicName} -{' '}
                              {v.staffName} / pasien #{v.slotNumber}{' '}
                              {v.patientName} / {v.patientExternalId || '-'} /{' '}
                              {v.patientPhoneNumber || '-'} /{' '}
                              {v.description || '-'} / {v.preferredLab} /{' '}
                              {v.preferredPharmacy} / Tuntas via{' '}
                              {v.finishedPlatform === 0
                                ? 'SiapDok'
                                : v.finishedPlatform === 1
                                  ? 'Zoom'
                                  : 'WhatsApp'}
                            </EuiText>
                            {i !== this.state.reports.length - 1 && (
                              <EuiHorizontalRule />
                            )}
                          </div>
                        );
                      })}
                    </div>
                  )}
                </EuiPanel>
              )}
            </EuiModalBody>
            <EuiModalFooter>
              {(this.state.reports || []).length > 0 && (
                <EuiButton
                  fill
                  onClick={() => {
                    var data = '';
                    this.state.reports.map((v, i) => {
                      let scheduleDate = moment(v.scheduleDate, 'YYYY-MM-DD');
                      data += `\n${i + 1}. ${scheduleDate.format('dddd')} ${v.scheduleDate
                        } ${v.scheduleTime} ${v.clinicName} - ${v.staffName
                        } / pasien #${v.slotNumber} ${v.patientName} / ${v.patientExternalId || '-'
                        } / ${v.patientPhoneNumber || '-'} / ${v.description || '-'
                        } / ${v.preferredLab || '-'} / ${v.preferredPharmacy || '-'
                        } / Tuntas via ${v.finishedPlatform === 0
                          ? 'SiapDok'
                          : v.finishedPlatform === 1
                            ? 'Zoom'
                            : 'WhatsApp'
                        }`;
                    });

                    data += `\n\nJumlah konsultasi: ${this.state.reports.length}`;
                    data += `\n\nRefund: ${this.state.reportSummaries.refund}`;
                    data += `\n\nJumlah konsultasi tuntas: ${this.state.reports.length - this.state.reportSummaries.refund}`;
                    data += `\nTuntas via SiapDok: ${this.state.reportSummaries.finishedViaSiapdok}`;
                    data += `\nTuntas via alternatif: ${this.state.reportSummaries.finishedViaAlternatives}`;
                    data += `\nSkor: ${this.state.reportSummaries.finishedPercentage}%`;

                    var link = document.createElement('a');
                    var mimeType = 'text/plain';

                    link.setAttribute('download', 'laporan_temu_janji.txt');
                    link.setAttribute(
                      'href',
                      'data:' +
                      mimeType +
                      ';charset=utf-8,' +
                      encodeURIComponent(data)
                    );
                    link.click();
                  }}
                >
                  Download txt
                </EuiButton>
              )}
              <EuiButtonEmpty
                onClick={() => this.setState({ showReports: false })}
              >
                OK
              </EuiButtonEmpty>
            </EuiModalFooter>
          </EuiModal>
        </EuiOverlayMask>
      );
    }
    if (this.state.showModalUploadClaim) {
      modalUploadClaim = (
        <EuiOverlayMask>
          <EuiModal
            onClose={() => {
              if (this.state.loadingUploadClaimOnDB) {
                return;
              }

              this.setState({
                showModalUploadClaim: false,
                fileUploadClaim: null,
              });
            }}
            initialFocus="[name=reports]"
          >
            <EuiModalHeader>
              <EuiModalHeaderTitle>
                Unggah Klaim Dokumen Asuransi
              </EuiModalHeaderTitle>
            </EuiModalHeader>

            <EuiModalBody>
              <EuiFormRow label="Unggah Dokumen">
                <EuiFilePicker
                  accept="application/pdf"
                  isInvalid={this.state.errorUploadClaim}
                  onChange={(file) => {
                    this.setState({
                      fileUploadClaim: file[0],
                      errorUploadClaim: false,
                    });
                  }}
                  isLoading={this.state.loadingUploadClaim}
                />
              </EuiFormRow>
            </EuiModalBody>
            <EuiModalFooter>
              <EuiButton
                isLoading={this.state.loadingUploadClaimOnDB}
                onClick={() => {
                  if (this.state.fileUploadClaim?.type !== 'application/pdf') {
                    alert('Berkas yang diunggah hanya boleh berjenis pdf');
                    this.setState({ errorUploadClaim: true });
                    return;
                  }

                  if (
                    !window.confirm(
                      'Apakah Anda yakin ingin menggungah berkas klaim dokumen ini?'
                    )
                  ) {
                    return;
                  }

                  this.setState({ loadingUploadClaimOnDB: true }, () => {
                    blobService
                      .uploadFile(this.state.fileUploadClaim)
                      .then((res) => res)
                      .then((blobID) => {
                        let payload = {};
                        payload.session_id =
                          this.state.selectedSessionInsurance;
                        payload.claim_doc = blobID;
                        return sessionsService.uploadClaimDoc(payload);
                      })
                      .then(() => {
                        swal({
                          title: 'Berhasil unggah klaim dokumen',
                          icon: 'success',
                          type: 'success',
                        }).then((value) => {
                          this.setState(
                            {
                              loadingUploadClaimOnDB: false,
                              showModalUploadClaim: false,
                              loadingData: true,
                            },
                            () => this.page(this.state.page)
                          );
                        });
                      })
                      .catch((err) => {
                        console.log(err);
                        this.setState({ loadingUploadClaimOnDB: false });
                      });
                  });
                }}
                fill
                disabled={
                  this.state.loadingUploadClaimOnDB ||
                  !this.state.fileUploadClaim
                }
              >
                Submit
              </EuiButton>
              <EuiButtonEmpty
                disabled={this.state.loadingUploadClaimOnDB}
                onClick={() =>
                  this.setState({
                    fileUploadClaim: null,
                    showModalUploadClaim: false,
                  })
                }
              >
                Batal
              </EuiButtonEmpty>
            </EuiModalFooter>
          </EuiModal>
        </EuiOverlayMask>
      );
    }

    return (
      <>
        <EuiPage className="class">
          {modalDescription}
          {modalReports}
          {modalUploadClaim}
          <EuiPageBody
            style={{ flexDirection: 'row' }}
            className={'content-container'}
          >
            <EuiPageContent>
              <EuiPageContentHeader>
                <EuiPageContentHeaderSection>
                  <EuiTitle>
                    <h2>Temu-Janji Konsultasi</h2>
                  </EuiTitle>
                </EuiPageContentHeaderSection>
              </EuiPageContentHeader>
              <EuiPageContentHeader>
                <EuiTabs>{this.renderTabs()}</EuiTabs>
              </EuiPageContentHeader>
              <EuiPageContentHeader>
                {this.renderFilterComponent()}
              </EuiPageContentHeader>
              <EuiPageContentBody>
                {!this.state.sessionSlotLoading &&
                  this.state.sessionSlotData &&
                  this.state.sessionSlotData.length < 0 && (
                    <p>Belum ada data</p>
                  )}
                {this.state.sessionSlotLoading && (
                  <p>
                    <EuiLoadingSpinner size="xl" />
                  </p>
                )}
                <TablePage
                  placeholder={'Cari'}
                  data={this.state.data}
                  column={this.state.column}
                  page={this.state.page}
                  limit={this.state.limit}
                  total={this.state.total}
                  prev={this.prevPage}
                  next={this.nextPage}
                  toPage={this.page}
                  onItemClick={this.onItemClick}
                  sort={sorting}
                  change={this.onTableChange}
                  loading={this.state.loadingData}
                  customEmpty={
                    <EuiFlexGroup direction="column">
                      <EuiFlexItem>
                        <p>
                          <EuiIcon type="clock" size="xxl" />
                        </p>
                      </EuiFlexItem>
                      <EuiFlexItem>
                        <p>
                          {this.state.selectedTabId === 'today'
                            ? 'Tidak ada jadwal hari ini'
                            : 'Tidak ada jadwal'}
                        </p>
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  }
                />
              </EuiPageContentBody>
            </EuiPageContent>
          </EuiPageBody>
        </EuiPage>
      </>
    );
  }
}

export default Consultations;
