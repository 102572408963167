import React, { Component } from 'react';
import { debounce } from 'lodash';

import {
  EuiOverlayMask,
  EuiModal,
  EuiModalHeader,
  EuiModalBody,
  EuiText,
  EuiModalFooter,
  EuiButton,
  EuiFlexGroup,
  EuiFlexItem,
  EuiComboBox,
  EuiCollapsibleNavGroup,
  EuiSpacer,
  EuiHorizontalRule,
  EuiButtonIcon,
  EuiFieldText,
  EuiSelect,
  EuiTitle,
  EuiSwitch,
  EuiTextArea,
  EuiCallOut,
  EuiFormRow,
  EuiModalHeaderTitle,
  EuiBasicTable,
  EuiIcon,
  EuiPopover,
  EuiContextMenuPanel,
  EuiContextMenuItem,
  EuiLoadingSpinner,  
  EuiBadge,
} from '@elastic/eui';

import swal from 'sweetalert';
import downloadjs from 'downloadjs';

import '../styles/PrescriptionModal.css';

import PharmaciesService from '../services/Pharmacies';
import Patients from '../services/Patients';
import RegionCodes from '../services/RegionCodes';
import Config from '../Config';
import MedicinesService from '../services/Medicines';
import SettingServices from '../services/Settings';

const service = new PharmaciesService();
const patientService = new Patients();
const regionCodesService = new RegionCodes();
const medicinesService = new MedicinesService();
const settingsService = new SettingServices();

class PrescriptionDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editMode: false,
      vendors: [
        {
          value: Config.variant || '-',
          label: (Config.variant || '-').toUpperCase(),
        },
        {
          value: 'kimiafarma',
          label: 'Kimia Farma',
        },
      ],
      tempItem: {},
      outlets: [],
      medicines: [],
      units: [],
      dosages: [],
      instructions: [],
      selectedItem: null,
      loadingMedicine: false,
      loadingOutlets: false,
      isAddItem: false,
      errors: [],
      showModalPatient: false,
      selectedPatient: {},
      showModalHistory: false,
      selectedHistories: [],
      loadingHistories: false,
      isLoadingSubmit: false,
      showModalPreview: false,
      prescriptionCopyPopoverOpen: false,
      prescriptionCopyPopoverHistoryOpen: {},
      loadingStock: false,
      needToCheckStock: false,
      skipVendors: [],
    };
  }

  medicineStock = async (medicineIds) => {
    try {
      this.setState({ loadingStock: true });
      let payload = {
        medicine_ids: medicineIds,
        preferred_pharmacy_id: this.props.item.pharmacy_id,
      };
      let res = await medicinesService.getMedicinesStock(payload);
      if (res.result.length > 0) {
        return res
      }
    } catch (error) {
      console.log("getStockMedicine error =>", error);
    } finally {
      this.setState({ loadingStock: false });
    }
  };
  
  componentDidMount() {
    settingsService
      .getAppSettings('skip-medicine-stock-vendors')
      .then((res) => {
        this.setState({
          skipVendors: JSON.parse(res.data),
        });
      })
      .catch((err) => {});
  }

  componentDidUpdate(prevProps) {
    console.log('componentDidUpdate', prevProps, this.props);
    if (this.props.showModal && !prevProps.showModal) {
      this.setState(
        {
          tempItem: Object.assign({}, this.props.item),
        },
        () => {
          if (prevProps.item?.id !== this.props.item?.id) {
            this.getData();
          }
        }
      );
    }
  }

  getData = async () => {
    try {
      let payload = {
        page: 1,
        limit: 10000,
        vendor: this.props.item?.pharmacy_vendor,
        orderBy: 'name',
      };

      // for initial medicines set only 25 items
      let [outlets, units, medicines, dosages, instructions] =
        await Promise.all([
          service.getPharmaciesV1(payload),
          service.getMedicineNumeroUnits(payload),
          service.getMedicines({
            ...payload,
            limit: 25,
          }),
          service.getDosages({
            ...payload,
            orderBy: 'index_position',
            order: 'ASC'
          }),
          service.getInstructions(payload),
        ]);
      const medicineIds = this.props.item.items.map(m => m.medicine_external_id);
      const data =await this.medicineStock(medicineIds)
      if (data.result.length > 0) {
        data.result.forEach((e) => {
          e.items.forEach((v) => {
            const matchingItem = this.props.item.items.find(g => g.medicine_external_id === `${v.itemId}`);
            if (matchingItem) {
              matchingItem.stock = v.stockCount;
            }
          });
        });
        this.setState({});
      }
      // combobox
      let outletsData = (outlets?.items || []).map((x) => {
        return {
          label: x.name,
          value: x.id,
        };
      });

      // select
      let unitsData = (units?.items || []).map((x) => {
        return {
          text: x.name,
          value: (x.external_id || x.name || '').toUpperCase(),
        };
      });

      // combobox
      let medicinesData = (medicines?.items || []).map((x) => {
        return {
          value: x.id,
          label: x.name,
          metadata: x,
        };
      });

      // select
      let dosagesData = [];
      let nameSame;
      for (let i in dosages?.items) {
        if ((dosagesData.length > 0 && nameSame) === dosages?.items[i].dose) {
          continue;
        }
        nameSame = dosages?.items[i].dose;
        dosagesData.push({
          text: dosages?.items[i].dose,
          value: dosages?.items[i].dose,
        });
      }

      // select
      let instructionsData = (instructions?.items || []).map((x) => {
        return {
          text: x.name,
          value: x.name,
        };
      });

      // add initial items on medicines if item has medicine and not duplicated
      if (this.props.item?.items) {
        let medicineIds = medicinesData.map((x) => x.value);
        let medicineItems = [];

        (this.props.item.items || []).forEach((x) => {
          // check if medicines is a compounding
          // indicated by having compounded_items
          if (x.compounded_items) {
            (x.compounded_items || []).forEach((y) => {
              if (!medicineIds.includes(y.medicine_id)) {
                medicineIds.push(y.medicine_id);
                medicineItems.push({
                  value: y.medicine_id,
                  label: y.medicine_name,
                  metadata: y,
                });
              }
            });
          } else {
            if (!medicineIds.includes(x.medicine_id)) {
              medicineItems.push({
                value: x.medicine_id,
                label: x.medicine_name,
                metadata: x,
              });
            }
          }
        });

        medicinesData = [...medicinesData, ...medicineItems];
      }

      // add choose option
      unitsData = [
        {
          text: 'Pilih satuan',
          value: '',
          disabled: true,
        },
        ...unitsData,
      ];

      dosagesData = [
        {
          text: 'Pilih frekuensi',
          value: '',
          disabled: true,
        },
        ...dosagesData,
      ];

      instructionsData = [
        {
          text: 'Pilih instruksi',
          value: '',
          disabled: true,
        },
        ...instructionsData,
      ];

      let needToCheckStock = this.state.skipVendors.includes(this.state.tempItem?.pharmacy_vendor);

      this.setState({
        initialOutlets: outletsData,
        initialMedicines: medicinesData,
        outlets: outletsData,
        units: unitsData,
        medicines: medicinesData,
        dosages: dosagesData,
        instructions: instructionsData,
        needToCheckStock: needToCheckStock,
      });
    } catch (e) {
      console.log(e);
      // parse error message
      let message = e?.message || '';
      if (e?.response?.data?.code) {
        message = e.response.data.code;
      }
      swal({
        title: 'Resep obat gagal dimuat',
        text: message,
        icon: 'error',
        button: 'OK',
      });

      this.props.closeModal();
    }
  };

  onPopOverHistoryClick = (id) => {
    let state = this.state;
    if (!state.prescriptionCopyPopoverHistoryOpen[id]) {
      state.prescriptionCopyPopoverHistoryOpen[id] = { isOpen: true };
    } else {
      state.prescriptionCopyPopoverHistoryOpen[id] = {
        isOpen: !state.prescriptionCopyPopoverHistoryOpen[id].isOpen,
      };
    }
    this.setState(state);
  };

  onSubmit = async () => {
    this.setState(
      {
        isLoadingSubmit: true,
      },
      () => {
        let tempItem = Object.assign({}, this.state.tempItem);
        delete tempItem.status;
        console.log('onSubmit', tempItem);

        // parse numero and dose_size to float
        tempItem.items.forEach((x) => {
          x.numero = parseFloat(x.numero);
          x.dose_size = parseFloat(x.dose_size);

          // parse compounded_items
          if (x.compounded_items && x.compounded_items.length > 0) {
            x.compounded_items.forEach((y) => {
              y.numero = parseFloat(y.numero);
              y.dose_size = parseFloat(y.dose_size);
            });
          }
        });

        service
          .putPrescription(tempItem)
          .then((res) => {
            swal({
              title: 'Berhasil',
              text: 'Resep berhasil digeser',
              icon: 'success',
              button: 'OK',
            }).then(() => {
              // refresh the page
              window.location.reload();
            });
          })
          .catch((e) => {
            console.log(e);
            // parse error message
            let message = e?.message || '';
            if (e?.response?.data?.code) {
              message = e.response.data.code;
            }
            swal({
              title: 'Resep gagal digeser',
              text: message,
              icon: 'error',
              button: 'OK',
            });
            this.setState({
              isLoadingSubmit: false,
            });
          });
      }
    );
  };

  getPrescriptionCopy = (id, lang, forceRegenerate, cb) => {
    this.setState({ loadingCopy: true }, () => {
      service
        .getRefPrescriptionCopy(id, lang, forceRegenerate)
        .then((result) => {
          let fileName = `prescription_${id}.pdf`;
          if (lang === 'en') {
            fileName = `prescription_${id}_en.pdf`;
          }
          if (!forceRegenerate) {
            downloadjs(result, fileName, `application/pdf`);
          } else {
            cb();
          }
        })
        .catch((err) => {
          console.log(err);
          // parse error message
          let message = err?.message || '';
          if (err?.response?.data?.code) {
            message = err.response.data.code;
          }

          if (err.response?.status === 404) {
            message = 'Mohon maaf, resep ini belum terbuat';
          }

          swal({
            title: 'Gagal mengunduh resep',
            icon: 'error',
            type: 'error',
            text: message,
          });
        })
        .finally(() => {
          this.setState({
            loadingCopy: false,
          });
        });
    });
  };

  getRegionByCode = async (code) => {
    try {
      let region = await regionCodesService.getRegionCode(code);
      return region?.name || '-';
    } catch (e) {
      console.log(e);
    }

    return '-';
  };

  getSelectedOption = (items, id) => {
    let selected = items.find((x) => x.value === id);

    selected = selected ? [selected] : [];

    return selected;
  };

  getSelectedOptionIntoName = (items, id) => {
    let selected = items.find((x) => x.value === id);

    selected = selected ? selected.label : '-';

    return selected;
  };

  handleSearchDebounce = debounce(async (searchValue) => {
    let payload = {
      page: 1,
      limit: 10000,
      vendor: this.state.tempItem?.pharmacy_vendor,
      search: searchValue,
    };

    let medicines = await service.getMedicines(payload);

    let data = (medicines?.items || []).map((x) => {
      return {
        value: x.id,
        label: x.name,
        metadata: x,
      };
    });

    // add selected medicines to make sure it's not removed
    let selectedMedicines = this.state.tempItem?.items || [];
    selectedMedicines.forEach((x) => {
      let found = data.find((y) => y.value === x.medicine_id);
      if (!found) {
        data.push({
          value: x.medicine_id,
          label: x.medicine_name,
          metadata: x,
        });
      }
    });

    this.setState({ medicines: data, loadingMedicine: false });
  }, 600);

  onSearchMedicine = (searchValue) => {
    console.log('called onSearchMedicine : ', searchValue, '');
    if (!searchValue) {
      return;
    }

    this.setState({ loadingMedicine: true }, () =>
      this.handleSearchDebounce(searchValue)
    );
  };

  handleChange = (e, obj) => {
    let value = e.target.value || null;
    obj = obj || {};

    obj[e.target.name] = value;
    let validationFields = { ...this.state.validationFields };
    if (validationFields[e.target.name]) {
      validationFields[e.target.name].isInvalid = false;
      obj.validationFields = validationFields;
    }
    this.setState(obj);
  };

  render() {
    let { item, showModal, closeModal } = this.props;
    let modal;

    const clearOnClose = () => {
      this.setState({
        selectedItem: null,
        tempItem: null,
        errors: [],
        isAddItem: false,
      });

      closeModal();
    };

    const checkMedicineError = () => {
      let item = this.state.selectedItem || {};
      let msgs = [];

      if (item.isCompounded) {
        if (!item.compounded_items || item.compounded_items.length === 0) {
          msgs.push('Minimal 1 obat harus dipilih');
        } else {
          // check compounded items field are filled
          let hasEmptyField = item.compounded_items.some((x) => {
            return (
              !x.medicine_id ||
              !x.numero ||
              !x.numero_unit ||
              x.numero_unit === '-'
            );
          });

          if (hasEmptyField) {
            msgs.push('Semua field obat racikan harus diisi');
          }
        }
      } else {
        if (!item.medicine_id) {
          msgs.push('Nama obat tidak boleh kosong');
        }
      }

      if (!item.numero || !item.numero_unit || item.numero_unit === '-') {
        msgs.push('Numero dan numero unit tidak boleh kosong');
      }
      if (
        !item.dose_size ||
        !item.dose_size_unit ||
        item.dose_size_unit === '-'
      ) {
        msgs.push('Dosis dan dosis unit tidak boleh kosong');
      }

      if (!item.dose_frequency || item.dose_frequency === '-') {
        msgs.push('Frekuensi dosis tidak boleh kosong');
      }

      return msgs;
    };

    const renderTextField = (label, value) => {
      return (
        <EuiFlexGroup alignItems="center">
          <EuiFlexItem grow={2}>
            <EuiText>
              <p>{label}</p>
            </EuiText>
          </EuiFlexItem>
          <EuiFlexItem grow={false}>
            <EuiText>
              <p>:</p>
            </EuiText>
          </EuiFlexItem>
          <EuiFlexItem grow={5}>
            <EuiText>
              <p>{value}</p>
            </EuiText>
          </EuiFlexItem>
        </EuiFlexGroup>
      );
    };

    const renderNavItem = (items, hideButton) => {


      let list = (items || []).map((item, index) => {
        let title = item.medicine_name || '-';
        let isCompounded = false;
        // check if item has compounded items
        if (item.compounded_items && item.compounded_items.length > 0) {
          // get the name of the compounded items
          title =
            `Racikan: ` +
            item.compounded_items.map((x) => x.medicine_name).join(', ');
          isCompounded = true;
        }

        return (
          <div>
            <EuiCollapsibleNavGroup
              key={index}
              iconType={isCompounded ? 'indexMapping' : undefined}
              title={<>{title}  {!this.state.needToCheckStock && (this.state.loadingStock ? <EuiLoadingSpinner size="m" /> : item.stock > 0 ? <EuiBadge color='#c8eebe'><b>{item.stock}</b></EuiBadge> : <EuiBadge color='#ffcac8'><b>Stok kosong</b></EuiBadge>)}</>}
              isCollapsible
              initialIsOpen={false}
              style={{ padding: '0px !important' }}
            >
              <EuiFlexGroup direction="column" gutterSize="l">
                <EuiFlexItem>
                  {!isCompounded &&
                    renderTextField('Nama Obat', item.medicine_name || '-')}
                  {renderTextField(
                    'Numero',
                    `${item.numero} ${item.numero_unit}`
                  )}
                  {renderTextField(
                    'Dosis',
                    `${item.dose_size} ${item.dose_size_unit}`
                  )}
                  {renderTextField('Frekuensi', item.dose_frequency || '-')}
                  {renderTextField('Instruksi', item.instruction || '-')}
                  {renderTextField('Stock', this.state.loadingStock ? <EuiLoadingSpinner size="m" /> : item.stock >= 0 ? item.stock : '(Stok kosong)')}
                  {renderTextField('Catatan Obat', item.note || '-')}
                  {isCompounded && (
                    <div>
                      {renderTextField(
                        'Daftar Racikan',
                        item.compounded_items.map((x, i) => {
                          return (
                            <div
                              style={{
                                borderBottom: '1px solid gray',
                              }}
                            >
                              <div>
                                <b>{x.medicine_name}</b>
                              </div>
                              <div>
                                <span>
                                  {`Numero: ${x.numero} ${x.numero_unit}`}
                                </span>
                              </div>
                            </div>
                          );
                        })
                      )}
                      {/* right */}
                      <div
                        style={{ float: 'right', margin: '10px 0px 0px 0px' }}
                      >
                        <EuiSwitch
                          label="Jika Perlu"
                          checked={item.if_needed || false}
                          readOnly
                          style={{ pointerEvents: 'none' }}
                        />
                      </div>
                    </div>
                  )}
                  {/* edit and delete icon */}
                  {this.state.editMode && !hideButton && (
                    <EuiFlexGroup
                      justifyContent="flexStart"
                      style={{ margin: 0, marginTop: 2, padding: 0 }}
                    >
                      <EuiFlexItem grow={false}>
                        <EuiButton
                          size="s"
                          display="base"
                          iconType="pencil"
                          color="secondary"
                          onClick={async () => {
                            // set medicines initial from the selected item first
                            let medicineIds = (this.state.medicines || []).map(
                              (x) => x.value
                            );
                            let medicineItems = [];

                            if ((item.compounded_items || []).length > 0) {
                              item.compounded_items.forEach((x) => {
                                if (!medicineIds.includes(x.medicine_id)) {
                                  medicineIds.push(x.medicine_id);
                                  medicineItems.push({
                                    value: x.medicine_id,
                                    label: x.medicine_name,
                                    metadata: x,
                                  });
                                }
                              });
                            } else {
                              if (!medicineIds.includes(item.medicine_id)) {
                                medicineItems.push({
                                  value: item.medicine_id,
                                  label: item.medicine_name,
                                  metadata: item,
                                });
                              }
                            }

                            // fetch units to make sure the units are up to date
                            // get initial outlets, medicines, and units from vendor

                            let payload = {
                              page: 1,
                              limit: 10000,
                              vendor: this.state.tempItem?.pharmacy_vendor,
                              orderBy: 'name',
                            };

                            // multiple request
                            // for initial medicines set only 25 items
                            let units = await service.getMedicineNumeroUnits(
                              payload
                            );

                            // select
                            let unitsData = (units?.items || []).map((x) => {
                              return {
                                text: x.name,
                                value: (
                                  x.external_id ||
                                  x.name ||
                                  ''
                                ).toUpperCase(),
                              };
                            });

                            // add choose option
                            unitsData = [
                              {
                                text: 'Pilih satuan',
                                value: '',
                                disabled: true,
                              },
                              ...unitsData,
                            ];

                            this.setState(
                              {
                                medicines: [
                                  ...this.state.medicines,
                                  ...medicineItems,
                                ],
                                units: unitsData,
                              },
                              () =>
                                this.setState({
                                  isAddItem: false,
                                  selectedItem: {
                                    ...item,
                                    index,
                                    isCompounded,
                                  },
                                })
                            );
                          }}
                        >
                          Edit
                        </EuiButton>
                      </EuiFlexItem>
                      <EuiFlexItem grow={false}>
                        <EuiButton
                          size="s"
                          display="base"
                          iconType="trash"
                          color="danger"
                          onClick={() => {
                            let items = [...(this.state.tempItem?.items || [])];
                            items.splice(index, 1);
                            this.setState({
                              tempItem: {
                                ...this.state.tempItem,
                                items,
                              },
                            });
                          }}
                        >
                          Hapus
                        </EuiButton>
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  )}
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiCollapsibleNavGroup>
            {index < items.length - 1 && <EuiHorizontalRule margin="xs" />}
          </div>
        );
      });

      // show empty message if no item
      if ((items || []).length === 0) {
        return (
          <EuiFlexGroup justifyContent="center">
            <EuiFlexItem grow={false}>
              <EuiText textAlign="center" color="subdued">
                <p>Belum ada obat yang diinputkan</p>
              </EuiText>
            </EuiFlexItem>
          </EuiFlexGroup>
        );
      }

      return list;
    };

    const renderFormInput = (
      // render form edit prescription
      <EuiFlexGroup direction="column" gutterSize="m">
        <EuiSpacer size="m" />
        {/* error validation callout */}
        <EuiCallOut
          id="errorCallout"
          color="danger"
          style={{
            display: (this.state.errors || []).length > 0 ? 'block' : 'none',
          }}
          title="Mohon lengkapi data yang lebih lengkap"
        >
          {/* bullet point errors */}
          <ul>
            {(this.state.errors || []).map((x, i) => (
              <li key={i}>{x}</li>
            ))}
          </ul>
        </EuiCallOut>
        {/* Input medicine */}
        <EuiFlexItem>
          <EuiFlexGroup justifyContent="spaceBetween">
            <EuiFlexItem>
              <EuiTitle size="xs">
                <h3>{this.state.isAddItem ? 'Tambah Obat' : 'Edit Obat'}</h3>
              </EuiTitle>
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              {this.state.loadingStock && <EuiLoadingSpinner size="l" /> }
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
            
              <EuiSwitch
                label="Racikan"
                checked={this.state.selectedItem?.isCompounded || false}
                onChange={(e) => {
                  this.setState({
                    selectedItem: {
                      ...this.state.selectedItem,
                      isCompounded: e.target.checked,
                    },
                  });
                }}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
        {this.state.selectedItem?.isCompounded ? (
          <EuiFlexItem
            style={{
              border: '1px solid gray',
              padding: 8,
            }}
          >
            <EuiFlexGroup justifyContent="spaceBetween">
              <EuiFlexItem>
                <EuiTitle size="xs">
                  <h3>Daftar Racikan</h3>
                </EuiTitle>
              </EuiFlexItem>
              <EuiFlexItem grow={false}>
                <EuiButton
                  size="s"
                  display="base"
                  iconType="plusInCircle"
                  color="secondary"
                  onClick={() => {
                    this.setState({
                      selectedItem: {
                        ...this.state.selectedItem,
                        compounded_items: [
                          ...(this.state.selectedItem?.compounded_items || []),
                          {
                            medicine_id: null,
                            medicine_name: '',
                            numero: '',
                            numero_unit: '',
                          },
                        ],
                      },
                    });
                  }}
                >
                  Tambah
                </EuiButton>
              </EuiFlexItem>
            </EuiFlexGroup>
            <EuiHorizontalRule margin="xs" />
            {/* list of compounded items */}
            <EuiFlexItem>
              <EuiFlexGroup direction="column" gutterSize="m">
                {(this.state.selectedItem?.compounded_items || []).map(
                  (item, index) => {
                    return (
                      <EuiFlexItem
                        style={{
                          // border bottom
                          borderBottom:
                            index <
                            this.state.selectedItem?.compounded_items.length - 1
                              ? '1px solid gray'
                              : '',
                        }}
                      >
                        <EuiFlexGroup
                          justifyContent="spaceBetween"
                          gutterSize="m"
                        >
                          <EuiFlexItem>
                            <EuiComboBox
                              fullWidth
                              isClearable
                              async
                              isLoading={this.state.loadingMedicine}
                              onSearchChange={this.onSearchMedicine}
                              placeholder="Silahkan ketik nama obat terlebih dahulu"
                              options={this.state.medicines}
                              onChange={(e) => {
                                if (e.length > 0) {
                                  this.medicineStock([e[0].metadata.code])
                                    .then((v) => {
                                      if (v === undefined) {
                                        swal({
                                          title: "",
                                          type: "",
                                          text: `Stok obat ${e[0]?.label} kosong. Silahkan pilih alternatif lain`,
                                        });
                                      }
                                      if (v.result.length > 0) {
                                        if (v.result[0].items[0].stockCount > 0) {
                                          let tempItem = this.state.selectedItem;
                                          tempItem.compounded_items[index] = {
                                            ...tempItem.compounded_items[index],
                                            medicine_id: (e || []).length > 0 ? e[0]?.value : "",
                                            medicine_name: (e || []).length > 0 ? e[0]?.label : "",
                                            // set initial numero unit and dose size unit
                                            numero_unit: (e || []).length > 0 ? e[0]?.metadata?.size_unit : "",
                                            dose_size_unit:
                                              (e || []).length > 0 ? e[0]?.metadata?.size_unit : "",
                                            stock: v.result[0].items[0].stockCount,
                                          };
                                          this.setState({ selectedItem: tempItem });
                                        }
                                      }
                                    })
                                    .catch((e) => {
                                      console.log(e);
                                    });
                                }
                                
                              }}
                              selectedOptions={
                                this.getSelectedOption(this.state.medicines,item?.medicine_id || ''
                              )}
                              value={item?.medicine_id || ''}
                              aria-label="Obat"
                              singleSelection={{ asPlainText: true }}
                              append={item.stock > 0 ? `${item.stock}` : '0' }
                            />
                          </EuiFlexItem>
                          <EuiFlexItem grow={false}>
                            <EuiButtonIcon
                              size="s"
                              display="base"
                              iconType="trash"
                              color="danger"
                              onClick={() => {
                                let items = [
                                  ...(this.state.selectedItem
                                    ?.compounded_items || []),
                                ];
                                items.splice(index, 1);
                                this.setState({
                                  selectedItem: {
                                    ...this.state.selectedItem,
                                    compounded_items: items,
                                  },
                                });
                              }}
                            >
                              Hapus
                            </EuiButtonIcon>
                          </EuiFlexItem>
                        </EuiFlexGroup>
                        <EuiSpacer size="s" />
                        <EuiFlexGroup
                          justifyContent="spaceBetween"
                          gutterSize="m"
                          style={{
                            marginBottom: 8,
                          }}
                        >
                          <EuiFlexItem grow={3}>
                            <EuiFieldText
                              fullWidth
                              placeholder="Numero"
                              value={item?.numero}
                              onChange={(e) => {
                                let tempItem = this.state.selectedItem;
                                tempItem.compounded_items[index] = {
                                  ...tempItem.compounded_items[index],
                                  // only number and dot
                                  numero: e.target.value.replace(
                                    /[^0-9.]/g,
                                    ''
                                  ),
                                };
                                this.setState({ selectedItem: tempItem });
                              }}
                            />
                          </EuiFlexItem>
                          <EuiFlexItem grow={2}>
                            <EuiSelect
                              fullWidth
                              options={this.state.units}
                              value={item.numero_unit}
                              onChange={(e) => {
                                let tempItem = this.state.selectedItem;
                                tempItem.compounded_items[index] = {
                                  ...tempItem.compounded_items[index],
                                  numero_unit: e.target.value,
                                };
                                this.setState({ selectedItem: tempItem });
                              }}
                            />
                          </EuiFlexItem>
                        </EuiFlexGroup>
                      </EuiFlexItem>
                    );
                  }
                )}
              </EuiFlexGroup>
            </EuiFlexItem>
          </EuiFlexItem>
        ) : (
          <EuiFlexItem>
            <EuiFlexGroup alignItems="center">
              <EuiFlexItem grow={1}>
                <EuiText>
                  <p>Nama Obat</p>
                </EuiText>
              </EuiFlexItem>
              <EuiFlexItem grow={5}>
                <EuiComboBox
                  fullWidth
                  isClearable
                  async
                  isLoading={this.state.loadingMedicine}
                  onSearchChange={this.onSearchMedicine}
                  placeholder="Silahkan ketik nama obat terlebih dahulu"
                  options={this.state.medicines}
                  onChange={(e) => {
                    if (e.length > 0) {
                      this.medicineStock([e[0].metadata.code]).then((v) => {
                        if (v === undefined && !this.state.needToCheckStock) {
                          swal({
                            title: '',
                            type: '',
                            text: `Stok obat ${e[0]?.label} kosong. Silahkan pilih alternatif lain`,
                          });
                        }
                        if (v.result.length > 0) {
                          if (v.result[0].items[0].stockCount > 0) {
                            this.setState({
                              selectedItem: {
                                ...this.state.selectedItem,
                                medicine_id: (e || []).length > 0 ? e[0]?.value : '',
                                medicine_name: (e || []).length > 0 ? e[0]?.label : '',
                                // set initial numero unit and dose size unit
                                numero_unit:
                                  (e || []).length > 0 ? e[0]?.metadata?.size_unit : '',
                                dose_size_unit:
                                  (e || []).length > 0 ? e[0]?.metadata?.size_unit : '',
                                stock: v.result[0].items[0].stockCount,
                              },
                            });
                          }
                        }
                      }).catch((e) => {
                        console.log(e)
                      })
                    }
                  }}
                  selectedOptions={this.getSelectedOption(
                    this.state.medicines,
                    this.state.selectedItem?.medicine_id || ''
                  )}
                  append={this.state.selectedItem?.stock > 0 && !this.state.needToCheckStock && `${this.state.selectedItem?.stock}` }
                  value={this.state.selectedItem?.medicine_id || ''}
                  aria-label="Obat"
                  singleSelection={{ asPlainText: true }}
                />
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
        )}
        <EuiFlexItem>
          <EuiFlexGroup alignItems="center">
            <EuiFlexItem grow={1}>
              <EuiText>
                <p>Numero</p>
              </EuiText>
            </EuiFlexItem>
            <EuiFlexItem grow={5}>
              <EuiFlexGroup alignItems="center">
                <EuiFlexItem grow={3}>
                  <EuiFieldText
                    fullWidth
                    placeholder="Numero"
                    value={this.state.selectedItem?.numero}
                    onChange={(e) => {
                      this.setState({
                        selectedItem: {
                          ...this.state.selectedItem,
                          // only number and dot
                          numero: e.target.value.replace(/[^0-9.]/g, ''),
                        },
                      });
                    }}
                  />
                </EuiFlexItem>
                <EuiFlexItem grow={2}>
                  <EuiSelect
                    fullWidth
                    options={this.state.units}
                    value={this.state.selectedItem?.numero_unit}
                    onChange={(e) => {
                      this.setState({
                        selectedItem: {
                          ...this.state.selectedItem,
                          numero_unit: e.target.value,
                        },
                      });
                    }}
                  />
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFlexGroup alignItems="center">
            <EuiFlexItem grow={1}>
              <EuiText>
                <p>Dosis</p>
              </EuiText>
            </EuiFlexItem>
            <EuiFlexItem grow={5}>
              <EuiFlexGroup alignItems="center">
                <EuiFlexItem grow={3}>
                  <EuiFieldText
                    fullWidth
                    placeholder="Dosis"
                    value={this.state.selectedItem?.dose_size}
                    onChange={(e) => {
                      this.setState({
                        selectedItem: {
                          ...this.state.selectedItem,
                          // only number and dot
                          dose_size: e.target.value.replace(/[^0-9.]/g, ''),
                        },
                      });
                    }}
                  />
                </EuiFlexItem>
                <EuiFlexItem grow={2}>
                  <EuiSelect
                    fullWidth
                    options={this.state.units}
                    value={this.state.selectedItem?.dose_size_unit}
                    onChange={(e) => {
                      this.setState({
                        selectedItem: {
                          ...this.state.selectedItem,
                          dose_size_unit: e.target.value,
                        },
                      });
                    }}
                  />
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFlexGroup alignItems="center">
            <EuiFlexItem grow={1}>
              <EuiText>
                <p>Frekuensi</p>
              </EuiText>
            </EuiFlexItem>
            <EuiFlexItem grow={5}>
              <EuiSelect
                fullWidth
                options={this.state.dosages}
                value={this.state.selectedItem?.dose_frequency}
                onChange={(e) => {
                  this.setState({
                    selectedItem: {
                      ...this.state.selectedItem,
                      dose_frequency: e.target.value,
                    },
                  });
                }}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFlexGroup alignItems="center">
            <EuiFlexItem grow={1}>
              <EuiText>
                <p>Instruksi</p>
              </EuiText>
            </EuiFlexItem>
            <EuiFlexItem grow={5}>
              <EuiSelect
                fullWidth
                options={this.state.instructions}
                value={this.state.selectedItem?.instruction}
                onChange={(e) => {
                  this.setState({
                    selectedItem: {
                      ...this.state.selectedItem,
                      instruction: e.target.value,
                    },
                  });
                }}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
        <EuiFlexItem>
          <EuiFlexGroup alignItems="center">
            <EuiFlexItem grow={1}>
              <EuiText>
                <p>Catatan Obat</p>
              </EuiText>
            </EuiFlexItem>
            <EuiFlexItem grow={5}>
              <EuiTextArea
                fullWidth
                placeholder="Catatan Obat"
                value={this.state.selectedItem?.note}
                onChange={(e) => {
                  this.setState({
                    selectedItem: {
                      ...this.state.selectedItem,
                      note: e.target.value,
                    },
                  });
                }}
              />
            </EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
        {this.state.selectedItem?.isCompounded && (
          <EuiFlexItem>
            <EuiFlexGroup justifyContent="flexEnd">
              <EuiFlexItem grow={false}>
                <EuiSwitch
                  label="Jika Perlu"
                  checked={this.state.selectedItem?.if_needed || false}
                  onChange={(e) => {
                    this.setState({
                      selectedItem: {
                        ...this.state.selectedItem,
                        if_needed: e.target.checked,
                      },
                    });
                  }}
                />
              </EuiFlexItem>
            </EuiFlexGroup>
          </EuiFlexItem>
        )}
      </EuiFlexGroup>
    );

    if (showModal) {
      modal = (
        <>
          <EuiOverlayMask>
            <EuiModal
              className="custom-modal"
              // required
              onClose={this.state.editMode ? function () {} : clearOnClose}
            >
              {!this.state.selectedItem && (
                <EuiModalHeader
                  style={{
                    paddingBottom: 0,
                  }}
                >
                  <EuiFlexGroup
                    direction="column"
                    gutterSize="s"
                    style={{ minWidth: '600px' }}
                  >
                    <EuiFlexItem>
                      <EuiText>
                        <h2>Detail Resep Obat</h2>
                      </EuiText>
                    </EuiFlexItem>
                    <EuiFlexItem>
                      {/* render doctor name */}
                      <EuiFlexItem>
                        <EuiFlexGroup alignItems="center">
                          <EuiFlexItem grow={1}>
                            <EuiText>
                              <p>Nama Dokter</p>
                            </EuiText>
                          </EuiFlexItem>
                          <EuiFlexItem grow={5}>
                            <EuiText>
                              <strong>
                                {this.state.tempItem?.author_name ?? '-'}
                              </strong>
                            </EuiText>
                          </EuiFlexItem>
                        </EuiFlexGroup>
                      </EuiFlexItem>

                      <EuiFlexGroup alignItems="center">
                        <EuiFlexItem grow={1}>
                          <EuiText>
                            <p>Apotek</p>
                          </EuiText>
                        </EuiFlexItem>
                        <EuiFlexItem grow={5}>
                          <EuiComboBox
                            fullWidth
                            isClearable={false}
                            isDisabled={!this.state.editMode}
                            placeholder="Pilih Apotek"
                            options={this.state.vendors}
                            onChange={(e) => {
                              // check if vendor is changed
                              let vendor =
                                (e || []).length > 0 ? e[0]?.value : '';

                              if (
                                vendor === this.state.tempItem?.pharmacy_vendor
                              ) {
                                return;
                              }

                              // confirm changes
                              if (
                                !window.confirm(
                                  'Apakah Anda yakin untuk mengubah vendor apotek? perubahan ini akan menghapus semua item resep obat yang sudah ada'
                                )
                              ) {
                                return;
                              }

                              this.setState(
                                {
                                  tempItem: {
                                    ...this.state.tempItem,
                                    items: [],
                                    pharmacy_id: '',
                                    pharmacy_name: '',
                                    pharmacy_vendor: vendor,
                                  },
                                  loadingOutlets: true,
                                },
                                async () => {
                                  // get initial outlets, medicines, and units from vendor

                                  let payload = {
                                    page: 1,
                                    limit: 10000,
                                    vendor: vendor,
                                    orderBy: 'name',
                                  };

                                  // multiple request
                                  // for initial medicines set only 25 items
                                  let [outlets, units, medicines] =
                                    await Promise.all([
                                      service.getPharmaciesV1(payload),
                                      service.getMedicineNumeroUnits(payload),
                                      service.getMedicines({
                                        ...payload,
                                        limit: 25,
                                      }),
                                    ]);

                                  // combobox
                                  let outletsData = (outlets?.items || []).map(
                                    (x) => {
                                      return {
                                        label: x.name,
                                        value: x.id,
                                      };
                                    }
                                  );

                                  // select
                                  let unitsData = (units?.items || []).map(
                                    (x) => {
                                      return {
                                        text: x.name,
                                        value: (
                                          x.external_id ||
                                          x.name ||
                                          ''
                                        ).toUpperCase(),
                                      };
                                    }
                                  );

                                  // add choose option
                                  unitsData = [
                                    {
                                      text: 'Pilih satuan',
                                      value: '',
                                      disabled: true,
                                    },
                                    ...unitsData,
                                  ];

                                  // combobox
                                  let medicinesData = (
                                    medicines?.items || []
                                  ).map((x) => {
                                    return {
                                      value: x.id,
                                      label: x.name,
                                      metadata: x,
                                    };
                                  });

                                  this.setState({
                                    outlets: outletsData,
                                    units: unitsData,
                                    medicines: medicinesData,
                                    loadingOutlets: false,
                                  });
                                }
                              );
                            }}
                            value={
                              this.state.editMode
                                ? this.state.tempItem?.pharmacy_vendor
                                : item?.pharmacy_vendor
                            }
                            aria-label="Apotek"
                            singleSelection={{ asPlainText: true }}
                            selectedOptions={this.getSelectedOption(
                              this.state.vendors,
                              this.state.editMode
                                ? this.state.tempItem?.pharmacy_vendor
                                : item?.pharmacy_vendor
                            )}
                          />
                        </EuiFlexItem>
                      </EuiFlexGroup>
                    </EuiFlexItem>
                    <EuiFlexItem>
                      <EuiFlexGroup alignItems="center">
                        <EuiFlexItem grow={1}>
                          <EuiText>
                            <p>Outlet</p>
                          </EuiText>
                        </EuiFlexItem>
                        <EuiFlexItem grow={5}>
                          <EuiComboBox
                            fullWidth
                            isClearable={false}
                            isLoading={this.state.loadingOutlets}
                            isDisabled={!this.state.editMode}
                            placeholder="Pilih Outlet"
                            options={this.state.outlets}
                            onChange={(e) => {
                              this.setState({
                                tempItem: {
                                  ...this.state.tempItem,
                                  pharmacy_id:
                                    (e || []).length > 0 ? e[0]?.value : '',
                                  pharmacy_name:
                                    (e || []).length > 0 ? e[0]?.label : '',
                                },
                              });
                            }}
                            value={
                              this.state.editMode
                                ? this.state.tempItem?.pharmacy_id
                                : item?.pharmacy_id
                            }
                            aria-label="Outlet"
                            singleSelection={{ asPlainText: true }}
                            selectedOptions={this.getSelectedOption(
                              this.state.outlets,
                              this.state.editMode
                                ? this.state.tempItem?.pharmacy_id
                                : item?.pharmacy_id
                            )}
                          />
                        </EuiFlexItem>
                      </EuiFlexGroup>
                    </EuiFlexItem>

                    <EuiFlexItem style={{ marginBottom: 0 }}>
                      <EuiFlexGroup justifyContent="flexStart">
                        {this.state.editMode && (
                          <EuiFlexItem grow={false}>
                            <EuiButton
                              size="s"
                              color="primary"
                              fill
                              onClick={() => {
                                this.setState({
                                  isAddItem: true,
                                  selectedItem: {},
                                });
                              }}
                            >
                              Tambah Obat
                            </EuiButton>
                          </EuiFlexItem>
                        )}
                        {!this.state.editMode && (
                          <EuiFlexItem grow={false}>
                            <EuiPopover
                              button={
                                <EuiButton
                                  size="s"
                                  color="primary"
                                  disabled={this.state.editMode}
                                  isLoading={this.state.loadingCopy}
                                  onClick={() => {
                                    this.setState({
                                      prescriptionCopyPopoverOpen:
                                        !this.state.prescriptionCopyPopoverOpen,
                                    });
                                  }}
                                >
                                  Unduh Resep
                                </EuiButton>
                              }
                              isOpen={this.state.prescriptionCopyPopoverOpen}
                              closePopover={() =>
                                this.setState({
                                  prescriptionCopyPopoverOpen: false,
                                })
                              }
                              panelPaddingSize="none"
                              anchorPosition="downLeft"
                            >
                              <EuiContextMenuPanel
                                size="s"
                                items={[
                                  <EuiContextMenuItem
                                    key="id"
                                    onClick={() => {
                                      this.getPrescriptionCopy(
                                        this.state.tempItem?.id,
                                        'id'
                                      );
                                      this.setState({
                                        prescriptionCopyPopoverOpen: false,
                                      });
                                    }}
                                  >
                                    ID
                                  </EuiContextMenuItem>,
                                  <EuiContextMenuItem
                                    key="en"
                                    onClick={() => {
                                      this.getPrescriptionCopy(
                                        this.state.tempItem?.id,
                                        'en'
                                      );
                                      this.setState({
                                        prescriptionCopyPopoverOpen: false,
                                      });
                                    }}
                                  >
                                    EN
                                  </EuiContextMenuItem>,
                                  <EuiContextMenuItem
                                    key="id"
                                    onClick={() => {
                                      this.setState({
                                        prescriptionCopyPopoverOpen: false,
                                      });
                                      this.getPrescriptionCopy(
                                        this.state.tempItem?.id,
                                        'id',
                                        true,
                                        () => {
                                          this.getPrescriptionCopy(
                                            this.state.tempItem?.id,
                                            'en',
                                            true,
                                            () => {
                                              alert(
                                                'Berkas PDF sudah digenerate ulang.'
                                              );
                                            }
                                          );
                                        }
                                      );
                                    }}
                                  >
                                    Regenerate
                                  </EuiContextMenuItem>,
                                ]}
                              />
                            </EuiPopover>
                          </EuiFlexItem>
                        )}
                        <EuiFlexItem grow={false}>
                          <EuiButton
                            size="s"
                            color="primary"
                            onClick={async () => {
                              this.setState(
                                {
                                  showModalHistory: true,
                                  loadingHistories: true,
                                },
                                () => {
                                  let payload = {
                                    limit: 100,
                                    page: 1,
                                    order: 'desc',
                                    orderBy: 'created_at',
                                    sessionId: this.state.tempItem?.session_id,
                                  };

                                  service
                                    .getPharmacyPrescriptions(payload)
                                    .then((res) => {
                                      this.setState({
                                        selectedHistories: res.items,
                                        loadingHistories: false,
                                      });
                                    })
                                    .catch((err) => {
                                      console.log(err);
                                      let message = err?.message || '';
                                      if (err?.response?.data?.code) {
                                        message = err?.response?.data?.code;
                                      }

                                      swal({
                                        title: 'Gagal mengambil riwayat',
                                        text: message,
                                        icon: 'error',
                                      });

                                      this.setState({
                                        loadingHistories: false,
                                      });
                                    });
                                }
                              );
                            }}
                          >
                            Riwayat
                          </EuiButton>
                        </EuiFlexItem>
                        <EuiFlexItem grow={false}>
                          <EuiButton
                            size="s"
                            color="primary"
                            onClick={async () => {
                              let patient = await patientService.getPatient(
                                item?.patient_id
                              );

                              if (!patient) {
                                alert('Pasien tidak ditemukan');
                                return;
                              }

                              // parse patient region code
                              let [province, city, district, sub_district] =
                                await Promise.all([
                                  this.getRegionByCode(
                                    patient?.current_province
                                  ),
                                  this.getRegionByCode(patient?.current_city),
                                  this.getRegionByCode(
                                    patient?.current_district
                                  ),
                                  this.getRegionByCode(
                                    patient?.current_sub_district
                                  ),
                                ]);

                              this.setState({
                                showModalPatient: true,
                                selectedPatient: {
                                  ...patient,
                                  current_province: province,
                                  current_city: city,
                                  current_district: district,
                                  current_sub_district: sub_district,
                                },
                              });
                            }}
                          >
                            Lihat Detail Pasien
                          </EuiButton>
                        </EuiFlexItem>
                      </EuiFlexGroup>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </EuiModalHeader>
              )}
              <EuiModalBody>
                <EuiFlexGroup direction="column" gutterSize="s">
                  <EuiFlexItem>
                    {!this.state.selectedItem && (
                      <EuiHorizontalRule margin="s" />
                    )}
                    {this.state.selectedItem
                      ? renderFormInput
                      : renderNavItem(this.state.tempItem?.items)}
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiModalBody>
              <EuiModalFooter style={{ paddingTop: 0 }}>
                <EuiFlexGroup direction="column">
                  <EuiFlexItem>
                    <EuiFlexGroup direction="column" gutterSize="xs">
                      <EuiHorizontalRule margin="s" />
                      <EuiFlexItem>
                        <EuiText>
                          <strong>Catatan Resep:</strong>
                        </EuiText>
                      </EuiFlexItem>
                      <EuiFlexItem>
                        {this.state.editMode ? (
                          <EuiTextArea
                            fullWidth
                            rows={3}
                            placeholder="Catatan Obat"
                            value={this.state.tempItem?.note}
                            onChange={(e) => {
                              this.setState({
                                tempItem: {
                                  ...this.state.tempItem,
                                  note: e.target.value,
                                },
                              });
                            }}
                          />
                        ) : (
                          <EuiText>
                            <p>{item?.note || '-'}</p>
                          </EuiText>
                        )}
                      </EuiFlexItem>
                    </EuiFlexGroup>
                  </EuiFlexItem>
                  <EuiFlexItem>
                    {this.state.selectedItem ? (
                      <EuiFlexGroup justifyContent="spaceBetween">
                        <EuiFlexItem grow={false}>
                          <EuiButton
                            color={
                              this.state.isAddItem ? 'primary' : 'secondary'
                            }
                            onClick={() => {
                              let errors = checkMedicineError();
                              this.setState({
                                errors,
                              });

                              if (errors.length === 0) {
                                if (this.state.isAddItem) {
                                  this.setState({
                                    tempItem: {
                                      ...this.state.tempItem,
                                      items: [
                                        ...(this.state.tempItem?.items || []),
                                        this.state.selectedItem,
                                      ],
                                    },
                                    selectedItem: null,
                                  });
                                } else {
                                  let items = [
                                    ...(this.state.tempItem?.items || []),
                                  ];

                                  items[this.state.selectedItem?.index] =
                                    this.state.selectedItem;
                                  this.setState({
                                    tempItem: {
                                      ...this.state.tempItem,
                                      items,
                                    },
                                    selectedItem: null,
                                  });
                                }
                              } else {
                                //  focus on eui callout error
                                let element =
                                  document.getElementById('errorCallout');

                                element.scrollIntoView({ behavior: 'smooth' });
                              }
                            }}
                            fill
                          >
                            {this.state.isAddItem ? 'Tambah Obat' : 'Edit Obat'}
                          </EuiButton>
                        </EuiFlexItem>
                        <EuiFlexItem grow={false}>
                          <EuiButton
                            color="danger"
                            onClick={() => {
                              this.setState({
                                errors: [],
                                isAddItem: false,
                                selectedItem: null,
                              });
                            }}
                          >
                            {this.state.editMode ? 'Batal' : 'Tutup'}
                          </EuiButton>
                        </EuiFlexItem>
                      </EuiFlexGroup>
                    ) : (
                      <EuiFlexGroup justifyContent="spaceBetween">
                        <EuiFlexItem grow={false}>
                          <EuiButton
                            color={
                              this.state.editMode ? 'primary' : 'secondary'
                            }
                            isDisabled={
                              this.state.editMode &&
                              (this.state.tempItem?.pharmacy_vendor === '' ||
                                this.state.tempItem?.pharmacy_id === null ||
                                this.state.tempItem?.pharmacy_id === '' ||
                                (this.state.tempItem?.items || []).length === 0)
                            }
                            isLoading={this.state.isLoadingSubmit}
                            onClick={() => {
                              // submit form
                              if (this.state.editMode) {
                                console.log('submit form');
                                console.log(this.state.tempItem);
                                this.setState({
                                  showModalPreview: true,
                                });
                              } else {
                                this.setState({
                                  editMode: !this.state.editMode,
                                });
                              }
                            }}
                            fill
                          >
                            {this.state.editMode
                              ? 'Simpan dan Geser Resep'
                              : 'Geser Resep'}
                          </EuiButton>
                        </EuiFlexItem>
                        <EuiFlexItem grow={false}>
                          <EuiButton
                            color={this.state.editMode ? 'danger' : 'primary'}
                            onClick={() => {
                              if (this.state.editMode) {
                                this.setState({
                                  outlets: this.state.initialOutlets,
                                  medicines: this.state.initialMedicines,
                                  tempItem: item,
                                  editMode: false,
                                  selectedItem: null,
                                });
                              } else {
                                clearOnClose();
                              }
                            }}
                          >
                            {this.state.editMode ? 'Batal' : 'Tutup'}
                          </EuiButton>
                        </EuiFlexItem>
                      </EuiFlexGroup>
                    )}
                  </EuiFlexItem>
                </EuiFlexGroup>
              </EuiModalFooter>
            </EuiModal>
          </EuiOverlayMask>
        </>
      );
    }

    let modalPatient;

    if (this.state.showModalPatient) {
      modalPatient = (
        <EuiOverlayMask>
          <EuiModal onClose={() => this.setState({ showModalPatient: false })}>
            <EuiModalHeader>
              <EuiModalHeaderTitle>Detail Pasien</EuiModalHeaderTitle>
            </EuiModalHeader>
            <EuiModalBody>
              <EuiFlexGroup direction="column" gutterSize="s">
                <EuiFlexItem>
                  <EuiFormRow label="Nama">
                    <EuiFieldText
                      value={this.state.selectedPatient?.name}
                      readOnly
                      style={{ pointerEvents: 'none' }}
                    />
                  </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiFormRow label="Alamat">
                    <EuiFieldText
                      value={this.state.selectedPatient?.address}
                      readOnly
                      style={{ pointerEvents: 'none' }}
                    />
                  </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiFormRow label="No. Telepon">
                    <EuiFieldText
                      value={this.state.selectedPatient?.phoneNumber}
                      readOnly
                      style={{ pointerEvents: 'none' }}
                    />
                  </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiFormRow label="Provinsi">
                    <EuiFieldText
                      value={this.state.selectedPatient?.current_province}
                      readOnly
                      style={{ pointerEvents: 'none' }}
                    />
                  </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiFormRow label="Kabupaten/Kota">
                    <EuiFieldText
                      value={this.state.selectedPatient?.current_city}
                      readOnly
                      style={{ pointerEvents: 'none' }}
                    />
                  </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiFormRow label="Kecamatan">
                    <EuiFieldText
                      value={this.state.selectedPatient?.current_district}
                      readOnly
                      style={{ pointerEvents: 'none' }}
                    />
                  </EuiFormRow>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiFormRow label="Kelurahan">
                    <EuiFieldText
                      value={this.state.selectedPatient?.current_sub_district}
                      readOnly
                      style={{ pointerEvents: 'none' }}
                    />
                  </EuiFormRow>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiModalBody>
            <EuiModalFooter>
              <EuiButton
                onClick={() => this.setState({ showModalPatient: false })}
              >
                Tutup
              </EuiButton>
            </EuiModalFooter>
          </EuiModal>
        </EuiOverlayMask>
      );
    }

    let modalHistory;
    if (this.state.showModalHistory) {
      modalHistory = (
        <EuiOverlayMask>
          <EuiModal onClose={() => this.setState({ showModalHistory: false })}>
            <EuiModalHeader>
              <EuiModalHeaderTitle>Riwayat Resep Obat</EuiModalHeaderTitle>
            </EuiModalHeader>
            <EuiModalBody>
              <EuiBasicTable
                items={this.state.selectedHistories}
                noItemsMessage="Tidak ada riwayat resep obat"
                loading={this.state.loadingHistories}
                columns={[
                  {
                    field: 'created_by',
                    name: 'Author',
                    render: (createdBy) => {
                      if (!createdBy) return '-';

                      return createdBy;
                    },
                  },
                  {
                    field: 'created_at',
                    name: 'Tanggal Dibuat',
                  },
                  {
                    field: '',
                    name: 'Resep',
                    render: (item) => (
                      <EuiPopover
                        button={
                          <EuiButton
                            size="s"
                            color="primary"
                            isLoading={this.state.loadingCopy}
                            onClick={() => this.onPopOverHistoryClick(item?.id)}
                          >
                            Unduh Resep
                          </EuiButton>
                        }
                        isOpen={
                          this.state.prescriptionCopyPopoverHistoryOpen[
                            item?.id
                          ]?.isOpen
                        }
                        closePopover={() =>
                          this.onPopOverHistoryClick(item?.id)
                        }
                        panelPaddingSize="none"
                        anchorPosition="downLeft"
                      >
                        <EuiContextMenuPanel
                          size="s"
                          items={[
                            <EuiContextMenuItem
                              key="id"
                              onClick={() => {
                                this.getPrescriptionCopy(item?.id, 'id');
                                this.onPopOverHistoryClick(item?.id);
                              }}
                            >
                              ID
                            </EuiContextMenuItem>,
                            <EuiContextMenuItem
                              key="en"
                              onClick={() => {
                                this.getPrescriptionCopy(item?.id, 'en');
                                this.onPopOverHistoryClick(item?.id);
                              }}
                            >
                              EN
                            </EuiContextMenuItem>,
                          ]}
                        />
                      </EuiPopover>
                    ),
                  },
                ]}
              />
            </EuiModalBody>
            <EuiModalFooter>
              <EuiButton
                onClick={() => this.setState({ showModalHistory: false })}
              >
                Tutup
              </EuiButton>
            </EuiModalFooter>
          </EuiModal>
        </EuiOverlayMask>
      );
    }

    const renderPreviewItem = (label, prevComponent, afterComponent) => {
      return (
        <EuiFlexItem>
          <EuiTitle size="xs">
            <h3>{label}</h3>
          </EuiTitle>
          <EuiSpacer size="s" />
          <EuiFlexGroup justifyContent="spaceBetween">
            <EuiFlexItem>{prevComponent}</EuiFlexItem>
            <EuiFlexItem
              grow={false}
              style={{
                alignItems: 'center',
              }}
            >
              <EuiIcon type="arrowRight" color="primary" size="l" />
            </EuiFlexItem>
            <EuiFlexItem>{afterComponent}</EuiFlexItem>
          </EuiFlexGroup>
        </EuiFlexItem>
      );
    };

    let modalPreview;
    if (this.state.showModalPreview) {
      // show prevew before and after change
      modalPreview = (
        <EuiOverlayMask>
          <EuiModal
            onClose={() => {
              if (this.state.isLoadingSubmit) return;
              this.setState({ showModalPreview: false });
            }}
          >
            <EuiModalHeader style={{ paddingBottom: 0 }}>
              <EuiFlexGroup direction="column">
                <EuiFlexItem>
                  <EuiModalHeaderTitle>Preview Perubahan</EuiModalHeaderTitle>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiHorizontalRule margin="none" />
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiModalHeader>
            <EuiModalBody>
              <EuiFlexGroup
                direction="column"
                gutterSize="s"
                style={{ minWidth: '600px' }}
              >
                <EuiFlexItem>
                  <EuiTitle size="xs">
                    <h3>Nama Dokter</h3>
                  </EuiTitle>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiText size="s">
                    <p>{item.author_name}</p>
                  </EuiText>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiTitle size="xs">
                    <h3>Nama Pasien</h3>
                  </EuiTitle>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiText size="s">
                    <p>{item.patient_name}</p>
                  </EuiText>
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiFlexGroup justifyContent="spaceBetween">
                    <EuiFlexItem>
                      <EuiTitle size="xs">
                        <h3>Resep Awal</h3>
                      </EuiTitle>
                      <EuiHorizontalRule margin="none" />
                    </EuiFlexItem>
                    <EuiFlexItem>
                      <EuiTitle size="xs">
                        <h3>Diubah Menjadi</h3>
                      </EuiTitle>
                      <EuiHorizontalRule margin="none" />
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </EuiFlexItem>
                {renderPreviewItem(
                  'Apotek',
                  <EuiText size="s">
                    <p>
                      {this.getSelectedOptionIntoName(
                        this.state.vendors,
                        item.pharmacy_vendor
                      )}
                    </p>
                  </EuiText>,
                  <EuiText size="s">
                    <p>
                      {this.getSelectedOptionIntoName(
                        this.state.vendors,
                        this.state.tempItem?.pharmacy_vendor
                      )}
                    </p>
                  </EuiText>
                )}
                {renderPreviewItem(
                  'Outlet',
                  <EuiText size="s">
                    <p>
                      {this.getSelectedOptionIntoName(
                        this.state.initialOutlets,
                        item.pharmacy_id
                      )}
                    </p>
                  </EuiText>,
                  <EuiText size="s">
                    <p>
                      {this.getSelectedOptionIntoName(
                        this.state.outlets,
                        this.state.tempItem?.pharmacy_id
                      )}
                    </p>
                  </EuiText>
                )}
                {renderPreviewItem(
                  'Daftar Obat',
                  renderNavItem(item?.items, true),
                  renderNavItem(this.state.tempItem?.items, true)
                )}
                {renderPreviewItem(
                  'Catatan Resep',
                  <EuiText size="s">
                    <p>{item?.note || '-'}</p>
                  </EuiText>,
                  <EuiText size="s">
                    <p>{this.state.tempItem?.note || '-'}</p>
                  </EuiText>
                )}
              </EuiFlexGroup>
            </EuiModalBody>
            <EuiModalFooter>
              <EuiFlexGroup direction="column">
                <EuiFlexItem>
                  <EuiHorizontalRule margin="none" />
                </EuiFlexItem>
                <EuiFlexItem>
                  <EuiFlexGroup justifyContent="spaceBetween">
                    <EuiFlexItem grow={false}>
                      <EuiButton
                        fill
                        isLoading={this.state.isLoadingSubmit}
                        onClick={this.onSubmit}
                      >
                        Konfirmasi
                      </EuiButton>
                    </EuiFlexItem>
                    <EuiFlexItem grow={false}>
                      <EuiButton
                        isDisabled={this.state.isLoadingSubmit}
                        onClick={() =>
                          this.setState({ showModalPreview: false })
                        }
                      >
                        Batal
                      </EuiButton>
                    </EuiFlexItem>
                  </EuiFlexGroup>
                </EuiFlexItem>
              </EuiFlexGroup>
            </EuiModalFooter>
          </EuiModal>
        </EuiOverlayMask>
      );
    }

    return (
      <div>
        {modal}
        {modalPatient}
        {modalHistory}
        {modalPreview}
      </div>
    );
  }
}

export default PrescriptionDetail;
